import {
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import _, { isArray } from "lodash";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { getApiData } from "../../../../utils/APIHelper";
import ConfirmModel from "../../../../components/ConfirmModel";
import { usePackageContext } from "..";
import SingleBoqAccordion from "../../../../components/SingleBoqAccordian";
import Form from "./components/Form";

const errorObj = {
  bNameErr: false,
  bNameMsg: "",
  materialTypeErr: false,
  materialTypeMsg: "",
  materialUnitErr: false,
  materialUnitMsg: "",
  specificationsErr: false,
  specificationsMsg: "",
  descriptionErr: false,
  descriptionMsg: "",
};

const initialFormvalues = {
  name: "",
  materialType: "",
  materialUnit: "",
  specifications: "",
  updatedAt: moment().format("MMM DD, YYYY"),
  id: "",
  description: "",
  imageMetadata: "",
  varaints: [],
};

const Inclusion = ({ tabValue, setTabValue, handleModalClose }) => {
  const { packageDetails, setPackageDetails } = usePackageContext();

  const [budgets, setBudgets] = useState([]);
  const [isCreationOpen, setIsCreationOpen] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [budgetDetails, setBudgetDetails] = useState(initialFormvalues);
  const [errObj, setErrObj] = useState(errorObj);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    setButtonLoader(true);
    if (packageDetails && packageDetails?.id) {
      setBudgets(packageDetails?.inclusions);
      setButtonLoader(false);
    }
  }, [packageDetails]);

  function clearData() {
    setBudgetDetails(initialFormvalues);
    setErrObj(errorObj);
    setVariants([]);
  }

  function clearErr() {
    setErrObj({
      ...errObj,
      materialTypeErr: false,
      materialTypeMsg: "",
      materialUnitErr: false,
      materialUnitMsg: "",
    });
  }

  const handleCloseCreation = () => {
    setIsCreationOpen(false);
    setVisible(false);
    setVisibleEditModal(false);
    clearData();
  };

  async function getDetailsApiCall() {
    try {
      const response = await getApiData(
        `api/v2/package/${packageDetails?.id}`,
        "GET",
        {},
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        setPackageDetails(response);
      } else {
        toast.error("Oops! Something went wrong");
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }

  const handleRowClick = (event, budget, id) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedBudget({
      data: budget,
      id: budget?.id,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedBudget(null);
    getDetailsApiCall();
  };

  const handleEdit = () => {
    setAnchorEl(null);
    setVisible(true);
    setBudgetDetails(selectedBudget.data);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteModal(true);
    setBudgetDetails(selectedBudget.data);
  };

  const handlePrevious = () => {
    setTabValue(0);
  };

  const handleNext = () => {
    setTabValue(tabValue + 1);
  };

  const handleUpdate = async () => {
    const newErrObj = { ...errObj };
    let valid = true;
    const validations = [
      {
        field: budgetDetails.name.trim(),
        errorKey: "bNameErr",
        msgKey: "bNameMsg",
        message: "Please enter budget name",
      },
      {
        field: budgetDetails.description?.trim(),
        errorKey: "descriptionErr",
        msgKey: "descriptionMsg",
        message: "Please enter budget description",
      },
      {
        field: budgetDetails.materialType,
        errorKey: "materialTypeErr",
        msgKey: "materialTypeMsg",
        message: "Please enter material type",
      },
      {
        field: budgetDetails.materialUnit,
        errorKey: "materialUnitErr",
        msgKey: "materialUnitMsg",
        message: "Please enter material unit",
      },
      {
        field: budgetDetails.specifications.trim(),
        errorKey: "specificationsErr",
        msgKey: "specificationsMsg",
        message: "Please enter specifications",
      },
    ];

    validations.forEach(({ field, errorKey, msgKey, message }) => {
      if (!field) {
        valid = false;
        newErrObj[errorKey] = true;
        newErrObj[msgKey] = message;
      } else {
        newErrObj[errorKey] = false;
        newErrObj[msgKey] = "";
      }
    });

    setErrObj(newErrObj);
    if (!valid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let body = {
        name: budgetDetails?.name,
        description: budgetDetails?.description,
        specifications: budgetDetails?.specifications,
        materialType: budgetDetails?.materialType,
        materialUnit: budgetDetails?.materialUnit,
        id: budgetDetails?.id,
        packageId: packageDetails?.id,
        imageMetadata: JSON.stringify(budgetDetails?.imageMetadata),
      };
      setButtonLoader(true);
      try {
        const response = await getApiData(
          `api/v2/package/${packageDetails?.id}/boq/${budgetDetails?.id}`,
          "PUT",
          body,
          { "Content-Type": "application/json" },
          true
        );
        if (response) {
          toast.success("BOQ Updated Successfully");
          setVisible(false);
          setVisibleEditModal(false);
          setIsCreationOpen(false);
          clearData();

          getDetailsApiCall();
          setButtonLoader("");
        }
      } catch (error) {
        toast.error(error.toString());
        setButtonLoader("");
      }
    }
  };

  const handleSave = async () => {
    const newErrObj = { ...errObj };
    let valid = true;
    const validations = [
      {
        field: budgetDetails.name.trim(),
        errorKey: "bNameErr",
        msgKey: "bNameMsg",
        message: "Please enter budget name",
      },
      {
        field: budgetDetails.description?.trim(),
        errorKey: "descriptionErr",
        msgKey: "descriptionMsg",
        message: "Please enter budget description",
      },
      {
        field: budgetDetails.materialType,
        errorKey: "materialTypeErr",
        msgKey: "materialTypeMsg",
        message: "Please enter material type",
      },
      {
        field: budgetDetails.materialUnit,
        errorKey: "materialUnitErr",
        msgKey: "materialUnitMsg",
        message: "Please enter material unit",
      },
      {
        field: budgetDetails.specifications.trim(),
        errorKey: "specificationsErr",
        msgKey: "specificationsMsg",
        message: "Please enter specifications",
      },
    ];

    validations.forEach(({ field, errorKey, msgKey, message }) => {
      if (!field) {
        valid = false;
        newErrObj[errorKey] = true;
        newErrObj[msgKey] = message;
      } else {
        newErrObj[errorKey] = false;
        newErrObj[msgKey] = "";
      }
    });

    setErrObj(newErrObj);
    if (!valid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      let body = {
        name: budgetDetails?.name,
        description: budgetDetails?.description,
        specifications: budgetDetails?.specifications,
        materialType: budgetDetails?.materialType,
        materialUnit: budgetDetails?.materialUnit,
        imageMetadata: JSON.stringify(budgetDetails?.imageMetadata),
        metadata: "",
      };
      setButtonLoader(true);
      try {
        const response = await getApiData(
          `/api/v2/package/${packageDetails?.id}/boq`,
          "POST",
          body,
          { "Content-Type": "application/json" },
          true
        );
        if (response) {
          toast.success("Budget Added Successfully");
          setButtonLoader("");
          setVisible(false);
          setVisibleEditModal(false);
          setIsCreationOpen(false);
          clearData();
          getDetailsApiCall();
        }
      } catch (error) {
        toast.error(error.toString());
        setButtonLoader("");
      }
    }
  };

  function renderBudgetCreateForm(mode) {
    return (
      <Form
        visible={visible}
        isCreationOpen={isCreationOpen}
        mode={mode}
        handleCloseCreation={handleCloseCreation}
        visibleEditModal={visibleEditModal}
        budgetDetails={budgetDetails}
        setBudgetDetails={setBudgetDetails}
        setErrObj={setErrObj}
        errObj={errObj}
        clearErr={clearErr}
        variants={variants}
        setVariants={setVariants}
        handleUpdate={handleUpdate}
        handleSave={handleSave}
        getDetailsApiCall={getDetailsApiCall}
      />
    );
  }

  const handleDeleteBudget = async () => {
    setButtonLoader(true);
    try {
      const response = await getApiData(
        `api/v2/package/${packageDetails?.id}/boq/${budgetDetails?.id}`,
        "DELETE",
        {},
        { "Content-Type": "application/json" },
        true
      );

      toast.success("Budget Deleted Successfully");
      setButtonLoader("");
      setSelectedBudget({});
      setDeleteModal(false);
      clearData();
      getDetailsApiCall();
    } catch (error) {
      toast.error(error.toString());
      setButtonLoader("");
    }
  };

  return (
    <>
      <Stack width="100%" gap="16px" height="90%">
        {isCreationOpen && renderBudgetCreateForm("form")}
        {visible && renderBudgetCreateForm("modal")}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 1vw",
          }}
        >
          <h2 style={{fontFamily: "Inter Tight"}}>Budgets</h2>
          <Button
            variant="contained"
            onClick={(e) => {
              setIsCreationOpen(true);
            }}
          >
            <AddCircleOutlineOutlinedIcon style={{ marginRight: 4 }} />
            Add Budget
          </Button>
        </div>

        <Stack
          width="100%"
          gap="16px"
          height="85%"
          overflow="auto"
          sx={{
            overflowX: "hidden !important",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none", // IE and Edge
            scrollbarWidth: "none", // Firefox
          }}
        >
          {buttonLoader ? (
            <CircularProgress size={26} style={{ color: "#fff" }} />
          ) : (
            <>
              {isArray(budgets) &&
                budgets.length > 0 &&
                budgets.map((item, idx) => (
                  <SingleBoqAccordion
                    budget={item}
                    index={idx}
                    handleRowClick={handleRowClick}
                    getDetailsApiCall={getDetailsApiCall}
                    isSummary={false}
                  />
                ))}
            </>
          )}
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button variant="outlined" onClick={handlePrevious}>
            {buttonLoader ? (
              <CircularProgress size={26} style={{ color: "#fff" }} />
            ) : (
              "Previous Step"
            )}
          </Button>

          <Stack
            alignItems="end"
            flexDirection="row"
            justifyContent="flex-end"
            gap="10px"
          >
            <Button variant="outlined" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleNext}>
              {buttonLoader ? (
                <CircularProgress size={26} style={{ color: "#fff" }} />
              ) : (
                "Save & Continue"
              )}
            </Button>{" "}
          </Stack>
        </Stack>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            ml: 8,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          style={{
            
            padding: "12px 36px 12px 12px",
          }}
          onClick={handleEdit}
        >
          Edit
        </MenuItem>
        <Divider style={{ margin: 0 }} />
        <MenuItem
          style={{
            
            padding: "12px 36px 12px 12px",
          }}
          onClick={handleDelete}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmModel
        visible={deleteModal}
        handleClose={() => setDeleteModal(false)}
        confirmation={handleDeleteBudget}
        message={`Are you sure you want to delete ${selectedBudget?.data?.name} budget?`}
      />
    </>
  );
};

export default Inclusion;
