import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HighlightOffOutlined } from "@mui/icons-material";
import { Setting } from "../../../utils/Setting";
import Images from "../../../config/images";
import { getApiData, postFormData } from "../../../utils/APIHelper";
import { usePackageContext } from "..";
import CloseIcon from "@mui/icons-material/Close";

const errorObj = {
  documentErr: false,
  documentMsg: "",
};

const PackageImages = ({ setTabValue, tabValue }) => {
  const navigate = useNavigate();
  const { packageDetails, setPackageDetails } = usePackageContext();

  const [errObj, setErrObj] = useState(errorObj);
  const [uploadLoader, setUploadLoader] = useState(false);

  useEffect(() => {
    if (packageDetails && packageDetails?.id) {
      getDetailsApiCall(packageDetails?.id);
    }
  }, [packageDetails?.id]);

  const handleClose = () => {
    navigate("/packages");
  };

  async function getDetailsApiCall(id) {
    try {
      const response = await getApiData(
        `api/v2/package/${id}`,
        "GET",
        {},
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        setPackageDetails(response);
        console.log("res from api", response);
      } else {
        toast.error("Oops! Something went wrong");
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }
  const handlePrevious = () => {
    setTabValue(tabValue - 1);
  };

  const handleNext = () => {
    if (packageDetails?.images?.length > 0) {
      setTabValue(tabValue + 1);
    } else {
      toast.error("Please upload atleast one image to proceed");
    }
  };

  function displayImagesView() {
    return (
      <>
        {packageDetails?.images?.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "calc(20% - 10px)",
                position: "relative",
              }}
            >
              <img
                src={item?.path}
                alt="Uploaded BOQ"
                style={{
                  width: "100%",
                  height: "180px",
                  border: "2px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  margin: "8px 8px",
                }}
              />
              <IconButton
                onClick={(e) => {
                  handleDeleteImage(item?.id);
                }}
                style={{
                  position: "absolute",
                  top: "-2px",
                  right: "-8px",
                  padding: "4px",
                  backgroundColor: "rgba(255, 255, 255)",
                  borderRadius: "50%",
                  border: "2px solid red",
                }}
              >
                <CloseIcon style={{ fontSize: "12px", color: "red" }} />
              </IconButton>
            </div>
          );
        })}
      </>
    );
  }

  async function handleFileUpload(e) {
    const files = Array.from(e.target.files);
    if (files.length === 0) {
      toast.error("No files selected");
      return;
    }

    if (files.length > 9) {
      toast.error("You can upload a maximum of 9 images at a time.");
      return;
    }

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });

    formData.append("type", "reno_package");
    formData.append("tags", "package_images");
    formData.append("project_id", packageDetails?.id);

    console.log("Uploading files:", files, packageDetails);
    setUploadLoader(true);

    try {
      const response = await postFormData(
        Setting.endpoints.projectFiles,
        formData
      );
      if (response.success) {
        toast.success("Files uploaded successfully");
        console.log("response", response);
        getDetailsApiCall(packageDetails?.id);
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      toast.error("An error occurred during file upload");
    } finally {
      setUploadLoader(false);
    }
  }

  const handleDeleteImage = async (id) => {
    setUploadLoader(true);
    try {
      const response = await getApiData(
        `api/project/delete-files/${id}`,
        "POST",
        {},
        { "Content-Type": "application/json" },
        false
      );
      if (response.success) {
        toast.success(response?.message);
        setUploadLoader("");
        getDetailsApiCall(packageDetails?.id);
      }
    } catch (error) {
      toast.error(error.toString());
      setUploadLoader("");
    }
  };

  return (
    <Stack
      overflow="auto"
      gap="8px"
      sx={{
        overflowX: "hidden !important",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 10,
        }}
      >
        <h2 style={{ marginBottom: "10px" }}>Package Images</h2>

        {uploadLoader ? (
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={12}
            minHeight={220}
          >
            <CircularProgress style={{ color: "#274BF1" }} size={26} />
          </Grid>
        ) : (
          <>
            <Grid
              item
              container
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 170,
                  border: "1px dashed #9CA3AF",
                  borderRadius: 4,
                  gap: 1,
                }}
              >
                <div style={{ width: "24px", height: "24px" }}>
                  <img src={Images.upload_icon} alt="upload-icon"></img>
                </div>
                <InputLabel>
                  <b>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#2563EB",
                      }}
                    >
                      Do you want to upload more images?
                    </span>{" "}
                  </b>
                </InputLabel>
                <InputLabel style={{ fontSize: 12, color: "#6B7280" }}>
                  {"PNG, JPG, (max size 1200*800)"}
                </InputLabel>
              </div>
              <input
                type="file"
                accept="image/*"
                multiple
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                  cursor: "pointer",
                  width: "100%",
                }}
                onChange={(e) => handleFileUpload(e)}
              />
              <FormHelperText
                error={errObj.documentErr}
                style={{ fontFamily: "Inter Tight" }}
              >
                {errObj.documentMsg}
              </FormHelperText>
            </Grid>
            {packageDetails?.images && packageDetails?.images?.length > 0 && (
              <Grid
                item
                style={{
                  marginTop: "20px",
                  overflowY: "auto",
                  maxHeight: "50vh",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  paddingTop: "1vh",
                  gap: "10px",
                }}
                sx={{
                  overflowX: "hidden !important",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                {displayImagesView()}
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop={2}
      >
        <Button variant="outlined" onClick={handlePrevious}>
          Previous Step
        </Button>

        <Stack
          alignItems="end"
          flexDirection="row"
          justifyContent="flex-end"
          gap="10px"
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleNext}>
            Save & Continue
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PackageImages;
