export const tabs = [
  { id: "1", title: "Draft", type: "CREATED" },
  { id: "2", title: "Requested", type: "Requested" },
  { id: "3", title: "Submitted", type: "awaiting-approval,rejected-by-contractor" },
  { id: "4", title: "Pending Action", type: "awaiting-contractor-confirmation,open-for-modification" },
  { id: "5", title: "Approved", type: "proposed-to-client" },
];

export default tabs;

export const statusStyleArray = [
  {
    status: "STANDARD",
    color: "#d46b08",
    backgroundColor: "#fff7e6",
  },
  {
    status: "ECONOMIC",
    color: "#096dd9",
    backgroundColor: "#e6f7ff",
  },
  {
    status: "awaiting-contractor-confirmation",
    color: "#c41d7f",
    backgroundColor: "#fff0f6",
  },
  {
    status: "SUPER HIGH END",
    color: "#389e0d",
    backgroundColor: "#f6ffed",
  },
  {
    status: "Requested",
    backgroundColor: "#096dd9",
    color: "#e6f7ff",
  },
  {
    status:'awaiting-approval',
    color:'#d4b106',
    backgroundColor:'#feffe6'
  },
  // {
  //   status: "awaiting-contractor-confirmation",
  //   backgroundColor: "#d46b08",
  //   color: "#fff7e6",
  // },
  {
    status: 'proposed-to-client',
    color: '#fff',
    backgroundColor: 'rgb(56, 158, 13)'
  },
  {
    status:'draft-by-contractor',
    backgroundColor:'#c41d7f',
    color:'#fff0f6'
  },
  {
    status: 'rejected-by-contractor',
    color:'rgb(229, 71, 70)',
    backgroundColor:'#fff0f6'
  }
];
