import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import useStyles from "./styles";
import Variants from "../../pages/CreatePackage/Inclusion/components/Varaints";
import VariantScroll from "../../pages/CreatePackage/Inclusion/components/VariantScroll";

const SingleBoqAccordion = ({
  budget,
  index,
  handleRowClick,
  getDetailsApiCall,
  isSummary,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [variantExpand, setVariantExpand] = useState(false);
  const classes = useStyles();

  const handleChangeExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleVaraintChangeExpanded = (panel) => (event, newExpanded) => {
    setVariantExpand(newExpanded ? panel : false);
  };

  // Parse image metadata, fallback to empty object if invalid
  let imageMetadata = {};
  try {
    imageMetadata = budget?.imageMetadata
      ? JSON.parse(budget?.imageMetadata)
      : {};
  } catch (e) {
    console.error("Error parsing imageMetadata:", e);
  }

  // Use a fallback image if imageMetadata is not available or the parsing fails
  const imageSrc =
    imageMetadata?.image ||
    "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b";

  return (
    <Grid item xs={12} key={index} sx={{ backgroundColor: "#F5F6F8" }}>
      <Accordion
        key={budget?.name}
        onChange={handleChangeExpanded(`panel_${budget?.name}`)}
        style={{ boxShadow: "none", borderRadius: "none" }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            ".MuiAccordionSummary-content": {
              margin: "5px 0px !important",
              padding: "0px !important",
            },
          }}
        >
          <Grid container>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <Grid>{expanded ? <ExpandMoreIcon /> : <ChevronRight />}</Grid>
                <Grid>
                  <div
                    style={{
                      width: "128px",
                      height: "128px",
                      position: "relative",
                    }}
                  >
                    <img
                      width="100%"
                      height="100%"
                      src={imageSrc}
                      alt="placeholder"
                      style={{ borderRadius: "8px" }}
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                  direction={"column"}
                  display={"flex"}
                  style={{ placeSelf: "baseline", gap: "5px" }}
                >
                  <Stack gap="12px">
                    <Stack>
                      <span className="budgetName">{budget?.name}</span>
                    </Stack>
                    <Stack>
                      <span className="disc">
                        <span className="accLabel">Specifications:&nbsp;</span>
                        {budget?.specifications}
                      </span>
                    </Stack>
                  </Stack>
                  <div>
                    <span className="accLabel">Material Type:&nbsp;</span>
                    <span className="accLabelValue">
                      {budget?.materialType}
                    </span>
                  </div>
                  <div>
                    <span className="accLabel">Material Unit:&nbsp;</span>
                    <span className="accLabelValue">
                      {budget?.materialUnit}
                    </span>
                  </div>
                </Grid>
              </Grid>
              {handleRowClick && (
                <Grid item>
                  <IconButton onClick={(e) => handleRowClick(e, budget, index)}>
                    <MoreVertIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "0px 55px" }}>
          <Divider style={{ width: "100%", margin: "12px 0" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1vh",
            }}
          >
            <div className="disc">
              <span className="accLabel">Description:&nbsp;</span>
              {budget?.description}
            </div>
          </div>
          <Variants
            budget={budget}
            getDetailsApiCall={getDetailsApiCall}
            isSummary={isSummary}
          />

          {/* {budget?.variants && budget?.variants?.length > 0 && (
            <Accordion
              style={{
                marginBottom: "1vh",
                background: "#f7f7f7",
                boxShadow: "none",
                borderRadius: "4px",
              }}
              key={budget?.name}
              onChange={handleVaraintChangeExpanded(`panel_${budget?.name}`)}
            >
               <VariantScroll
                  budget={budget}
                  getDetailsApiCall={getDetailsApiCall}
                  isSummary={isSummary}
                />
              
            </Accordion>
          )} */}
          {/* <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  ".MuiAccordionSummary-content": {
                    margin: "5px 0px !important",
                    padding: "0px !important",
                  },
                }}
              >
                <Typography
                  className={classes.projectInformation}
                  variant="h6"
                  display="flex"
                  alignItems="center"
                  fontFamily="Inter Tight !important"
                  letterSpacing="0px"
                >
                  {variantExpand ? <ExpandMoreIcon /> : <ChevronRight />}{" "}
                  Variants
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <VariantScroll
                  budget={budget}
                  getDetailsApiCall={getDetailsApiCall}
                  isSummary={isSummary}
                />
              </AccordionDetails> */}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SingleBoqAccordion;
