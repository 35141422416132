import { useMemo, useState } from "react";
import authActions from "../../../redux/reducers/auth/actions";
import { AddCircleOutline, Close } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  FormControl,
  Modal,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SingleMilestoneAccordion from "../../../components/SingleMilestoneAccordian";
import theme from "../../../config/theme";
import useStyles from "./styles";
import CInput from "../../../components/CInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Images from "../../../config/images";
import { v4 as uuidv4 } from "uuid";
import MilestonePaymentGroup from "./MilestonePayementGroup";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import ConfirmModel from "../../../components/ConfirmModel";
import { transformDateFormat } from "../../../utils/CommonFunction";
// import ConfirmModel from "../../../components/ConfirmModel";
// import { useLocation, useNavigate } from "react-router-dom";
// import { getSaveDraftPayload } from "../helpers";
// import { getAPIProgressData } from "../../../utils/APIHelper";
// import { Setting } from "../../../utils/Setting";

const PaymentGroup = ({ handleTabValue, handleDisableTab, isUpdateDraftProposal }) => {
  const errorObj = {
    groupNameError: false,
    groupNameMsg: "",
    dueDateError: false,
    dueDateMsg: "",
  };
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { proposalDetails } = useSelector((state) => state.auth);
  const { setProposalDetails } = authActions;
  // const [milestones, setMilestones] = useState({});
  const [errObj, setErrObj] = useState(errorObj);
  const [isOpen, setIsOpen] = useState(false);
  // const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [groupName, setGroupName] = useState("");
  // const [paymentGroups, setPaymentGroups] = useState({});
  const [selectedPaymentGroup, setSelectedPaymentGroup] = useState({});
  const [selectedMilestones, setSelectedMilestones] = useState({});
  // const [isConfirmSaveDraft, setIsConfirmSaveDraft] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 800,
    maxHeight: sm ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    padding: "24px",
    overflow: "auto",
  };

  // useEffect(() => {
  //   if (proposalDetails.payment_group) {
  //     setPaymentGroups(proposalDetails.payment_group);
  //   }
  // }, [proposalDetails.payment_group]);

  // useEffect(() => {
  //   setMilestones(proposalDetails.milestones);
  // }, [proposalDetails.milestones]);

  const endDate = useMemo(() => {
    const endDateArray = Object.keys(selectedMilestones).map((key) => {
      const milestone = selectedMilestones[key];
      return +new Date(milestone.end_date);
    });

    const maxEndDate = Math.max(...endDateArray);

    const endDate = transformDateFormat(new Date(maxEndDate));
    return endDate;
  }, [selectedMilestones]);

  const handleValidation = (mode) => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(groupName)) {
      valid = false;
      error.groupNameError = true;
      error.groupNameMsg = "Please enter the name";
    }

    setErrObj(error);

    if (Object.keys(selectedMilestones).length < 1) {
      return toast.error("Please select at least one milestone");
    }

    if (valid) {
      if (mode === "create") {
        handleCreate();
      }
      if (mode === "update") {
        handleUpdate();
      }
    }
  };

  const clearData = () => {
    setGroupName("");
    setErrObj({});
  };

  const handleClose = () => {
    // for (let key in proposalDetails.milestones) {
    //   if (selectedMilestones.hasOwnProperty(key)) {
    //     dispatch(setProposalDetails({
    //       ...proposalDetails,
    //       milestones: {
    //         ...proposalDetails.milestones,
    //         [key]: {
    //           ...proposalDetails.milestones[key],
    //           in_payment_group: false
    //         }
    //       },
    //     }));
    // setMilestones((prev) => ({
    //   ...prev,
    //   [key]: { ...prev[key], in_payment_group: false },
    // }));
    // }
    // }
    // const newMilestones = { ...proposalDetails.milestones };
    // for (let key in selectedMilestones) {
    //   newMilestones[key] = {
    //     ...newMilestones[key],
    //     in_payment_group: false,
    //     group_id: "",
    //   };
    // }
    // dispatch(setProposalDetails({
    //   ...proposalDetails,
    //   milestones: newMilestones,
    // }));

    setSelectedMilestones({});
    setSelectedPaymentGroup({});
    setIsOpen(false);
    setIsEditModalOpen(false);
    clearData();
  };

  const handleMilestones = (event, milestone, milestoneKey) => {
    const isSelected = selectedMilestones.hasOwnProperty(milestoneKey);

    let updatedSelectedMilestones;

    if (isSelected) {
      updatedSelectedMilestones = { ...selectedMilestones };
      delete updatedSelectedMilestones[milestoneKey];
    } else {
      updatedSelectedMilestones = {
        ...selectedMilestones,
        [milestoneKey]: milestone,
      };
    }
    setSelectedMilestones(updatedSelectedMilestones);

    const updatedMilestones = {
      ...proposalDetails.milestones,
      [milestoneKey]: {
        ...proposalDetails.milestones[milestoneKey],
        in_payment_group: !isSelected,
      },
    };

    dispatch(
      setProposalDetails({
        ...proposalDetails,
        milestones: updatedMilestones,
      })
    );
  };

  // const isSelected = selectedMilestones.hasOwnProperty(milestoneId);
  //   if (isSelected) {
  //     const newMilestones = {};
  //     for (let key in selectedMilestones) {
  //       if (key !== milestoneId) {
  //         newMilestones[key] = selectedMilestones[key];
  //       }
  //     }
  //     setSelectedMilestones((prev) => newMilestones);
  //     dispatch(setProposalDetails({
  //       ...proposalDetails,
  //       milestones: {
  //         ...proposalDetails.milestones,
  //         [milestoneId]: {
  //           ...proposalDetails.milestones[milestoneId],
  //           in_payment_group: false,
  //           group_id: "",
  //         },
  //       },
  //     }));

  // setMilestones((prev) => ({
  //   ...prev,
  //   [milestoneId]: {
  //     ...prev[milestoneId],
  //     in_payment_group: false,
  //     group_id: "",
  //   },
  // }));
  // } else {
  //   setSelectedMilestones((prev) => ({ ...prev, [milestoneId]: milestone }));
  //   dispatch(setProposalDetails({
  //     ...proposalDetails,
  //     milestones: {
  //       ...proposalDetails.milestones,
  //       [milestoneId]: {
  //         ...proposalDetails.milestones[milestoneId],
  //         in_payment_group: true,
  //       },
  //     },
  //   }));

  // setMilestones((prev) => ({
  //   ...prev,
  //   [milestoneId]: { ...prev[milestoneId], in_payment_group: true },
  // }));
  // }
  // };
  // const location = useLocation();

  // const villa = location?.state?.villa ? location?.state?.villa : {};
  // const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);

  // const proposalId = isUpdateDraftProposal ? villa?.proposal_id : proposalDetails.saveDraftProposalId ? proposalDetails.saveDraftProposalId : null;
  // const query = isUpdateDraftProposal ? `?proposal_id=${proposalId}` : '';

  // const handleSaveDraft = async () => {
  //   const payload = getSaveDraftPayload({
  //     proposalDetails,
  //     paymentGroups: proposalDetails.payment_group,
  //   });
  //   try {
  //     const response = await getAPIProgressData(
  //       `${Setting.endpoints.createDraftProposal}${query}`,
  //       "POST",
  //       payload,
  //       true
  //     );
  //
  //     if (response.success) {
  //       setProposalDetails({
  //         ...proposalDetails,
  //         saveDraftProposalId: proposalId,
  //       });
  //       toast.success("Draft created Successfully");
  //       navigate("/dashboard");
  //     } else {
  //       toast.error(response.message);
  //     }
  //   } catch (error) {
  //     console.log("save draft api error", error)
  //     toast.error(error.toString());
  //   }
  // };

  const handleCreate = () => {
    const groupId = uuidv4();
    const newPaymentGroup = {
      ...proposalDetails.payment_group,
      [groupId]: {
        groupName,
        group: selectedMilestones,
        dueDate: endDate,
      },
    };
    const newMilestones = {};
    for (let key in proposalDetails.milestones) {
      if (selectedMilestones.hasOwnProperty(key)) {
        newMilestones[key] = {
          ...proposalDetails.milestones[key],
          group_id: groupId,
        };
      } else {
        newMilestones[key] = proposalDetails.milestones[key];
      }
    }

    dispatch(
      setProposalDetails({
        ...proposalDetails,
        payment_group: newPaymentGroup,
        milestones: newMilestones,
      })
    );
    // setMilestones(newMilestones);
    // setPaymentGroups((prev) => ({
    //   ...prev,
    //   [groupId]: {
    //     groupName,
    //     group: selectedMilestones,
    //     dueDate: endDate,
    //   },
    // }));
    setSelectedMilestones({});
    setIsOpen(false);
    clearData();
  };

  const handleUpdate = () => {
    const newPaymentGroup = {
      ...proposalDetails.payment_group,
      [selectedPaymentGroup.id]: {
        groupName,
        group: selectedMilestones,
        dueDate: endDate,
      },
    };
    const newMilestones = {};
    for (let key in proposalDetails.milestones) {
      if (selectedMilestones.hasOwnProperty(key)) {
        newMilestones[key] = {
          ...proposalDetails.milestones[key],
          group_id: selectedPaymentGroup.id,
        };
      } else {
        newMilestones[key] = proposalDetails.milestones[key];
      }
    }
    // setPaymentGroups((prev) => ({
    //   ...prev,
    //   [selectedPaymentGroup.id]: {
    //     groupName,
    //     group: selectedMilestones,
    //     dueDate: endDate,
    //   },
    // }));

    dispatch(
      setProposalDetails({
        ...proposalDetails,
        payment_group: newPaymentGroup,
        milestones: newMilestones,
      })
    );

    // setMilestones(newMilestones);
    setSelectedMilestones({});
    setSelectedPaymentGroup({});
    setIsEditModalOpen(false);
    clearData();
  };

  const renderSelectedMilestones = () => {
    return Object.keys(selectedMilestones).map((key) => (
      <SingleMilestoneAccordion
        milestone={proposalDetails.milestones[key]}
        id={key}
        key={key}
        checked
        handleCheckbox={handleMilestones}
        isEditScreen={isUpdateDraftProposal ? true : false}
      />
    ));
  };
  // const totalPaymentGroupAmount = useMemo(() => {
  //   const amount = Object.keys(selectedMilestones).reduce((acc, key) => {
  //     let milestone = proposalDetails?.milestones[key];
  //     return acc + milestone?.amount;
  //   }, 0);
  //   return amount;
  // }, [selectedMilestones, proposalDetails.milestones]);

  const renderUnselectedMilestones = () => {
    return Object.keys(proposalDetails.milestones).map((key) => {
      if (
        !selectedMilestones.hasOwnProperty(key) &&
        !proposalDetails.milestones[key].in_payment_group
      ) {
        const milestone = proposalDetails.milestones[key];
        return (
          <SingleMilestoneAccordion
            milestone={milestone}
            id={key}
            handleCheckbox={(event) => handleMilestones(event, milestone, key)}
          />
        );
      }
    });
  };

  // const handleConfirm = () => {
  //   setIsCancelModalOpen(false);
  //   dispatch(setProposalDetails({}));
  //   navigate("/dashboard");
  // };

  const handleEditPaymentGroup = (e, paymentGroup, id) => {
    e.stopPropagation();
    setIsEditModalOpen(true);
    setSelectedPaymentGroup({ paymentGroup, id });
    setGroupName(paymentGroup.groupName);
    // paymentGroup.group is the milestones inside this payment group
    setSelectedMilestones(paymentGroup.group);
  };

  const handleDelete = (e, paymentGroup, id) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
    setSelectedPaymentGroup({ paymentGroup, id });
  };

  const handleDeletePaymentGroup = () => {
    const updatedMilestones = {};

    for (let key in proposalDetails.milestones) {
      const milestone = proposalDetails.milestones[key];

      if (milestone.group_id === selectedPaymentGroup.id) {
        updatedMilestones[key] = {
          ...milestone,
          in_payment_group: false,
          group_id: undefined,
        };
      } else {
        updatedMilestones[key] = milestone;
      }
    }

    const newPaymentGroups = {};
    for (let key in proposalDetails.payment_group) {
      if (key !== selectedPaymentGroup.id) {
        newPaymentGroups[key] = proposalDetails.payment_group[key];
      }
    }

    dispatch(
      setProposalDetails({
        ...proposalDetails,
        milestones: updatedMilestones,
        payment_group: newPaymentGroups,
      })
    );
    setIsDeleteModalOpen(false);
  };
  // const handlePreviousStep = () => {
  // dispatch(
  //   setProposalDetails({
  //     ...proposalDetails,
  //     milestones: milestones,
  //     payment_group: paymentGroups,
  //     paymentGroup_ids: Object.keys(paymentGroups),
  //   })
  // );

  // setSelectedMilestones({});
  // setMilestones({});
  // setPaymentGroups({});
  // setSelectedPaymentGroup({});
  // handleTabValue(2);
  // };

  const isAnyMilestonesAvailable = useMemo(() => {
    return Object.values(proposalDetails.milestones).some(
      (milestone) => milestone?.in_payment_group === false
    );
  }, [proposalDetails.milestones]);

  // const handleSave = () => {
  // const isNotValid = Object.values(proposalDetails.milestones).some(
  //   (milestone) => milestone.in_payment_group === false
  // );
  // if (isNotValid) return toast.error("All milestones should have a group");
  // dispatch(
  //   setProposalDetails({
  //     ...proposalDetails,
  //     milestones: milestones,
  //     payment_group: paymentGroups,
  //     paymentGroup_ids: Object.keys(paymentGroups),
  //   })
  // );

  // setSelectedMilestones({});
  // setMilestones({});
  // setPaymentGroups({});
  // setSelectedPaymentGroup({});
  // handleDisableTab("detailsTab");
  // handleTabValue(4);
  // };
  /* Payment groups form */
  function renderUpsertPaymentGroupForm(mode) {
    return (
      <Modal
        open={isOpen || isEditModalOpen}
        closeAfterTransition
        disableAutoFocus
        slotProps={{ backdrop: Backdrop }}
      >
        <Fade in={isOpen || isEditModalOpen}>
          <Box sx={style}>
            <Stack gap="28px" height="100%">
              <Close
                sx={{
                  width: "35px",
                  height: "35px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
              <Stack direction="row" justifyContent="space-between">
                {mode === "create" ? (
                  <Typography className={classes.groupTitle}>
                    Create Payment Group
                  </Typography>
                ) : (
                  <Typography className={classes.groupTitle}>
                    Update Payment Group
                  </Typography>
                )}
              </Stack>
              <Divider />
              <Stack flex={1} gap="28px">
                <Stack direction="row" gap="28px">
                  <CInput
                    multiline={true}
                    rows={1}
                    label="Name:"
                    placeholder="Write here..."
                    value={groupName}
                    className={classes.groupTitle}
                    onChange={(e) => {
                      setGroupName(e.target.value);
                      setErrObj({
                        ...errObj,
                        groupNameError: false,
                        groupNameMsg: "",
                      });
                    }}
                    error={errObj.groupNameError}
                    helpertext={errObj.groupNameMsg}
                  />
                  <FormControl
                    variant="standard"
                    fullWidth
                    style={{ position: "relative" }}
                    error={errorObj.dueDateError}
                  >
                    <span className={classes.formLabel} htmlFor="end-date">
                      Due Date:
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={new Date(endDate)}
                        disabled
                        sx={{
                          width: "100%",
                          marginTop: "6px",
                        }}
                        components={{
                          OpenPickerIcon: () => (
                            <img
                              src={Images.calendarIcon}
                              alt="calender-icon"
                            ></img>
                          ),
                        }}
                        format="MMMM dd, yyyy"
                        slotProps={{
                          textField: {
                            helperText: errObj.dueDateMsg,
                            error: errObj.dueDateError,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Stack>
              </Stack>
              {renderSelectedMilestones()}
              {renderUnselectedMilestones()}
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {mode === "create" ? (
                  <Button
                    variant="contained"
                    onClick={() => handleValidation(mode)}
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleValidation(mode)}
                  >
                    Update
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    );
  }
  return (
    <Stack width="100%" gap="28px">
      {Object.keys(proposalDetails.payment_group).map((key) => {
        const pGroup = proposalDetails.payment_group[key];
        return (
          <MilestonePaymentGroup
            group={pGroup.group} /* milestones inside this payment group */
            groupName={pGroup.groupName}
            dueDate={pGroup.dueDate}
            groupId={key}
            handleEditPaymentGroup={(e) =>
              handleEditPaymentGroup(e, pGroup, key)
            }
            handleDelete={(e) => handleDelete(e, pGroup, key)}
            isExpandable
            isUpdateDraftProposal
          />
        );
      })}
      <Tooltip title={!isAnyMilestonesAvailable ? "No more Milestones" : ""}>
        <span style={{ width: "fit-content" }}>
          <Button
            variant="text"
            onClick={() => setIsOpen(true)}
            disabled={!isAnyMilestonesAvailable}
            color="primary"
            style={{
              alignSelf: "start",
              gap: "8px",
              padding: "8px 12px",
              boxShadow: "none",
            }}
          >
            <AddCircleOutline />
            Create payment group
          </Button>
        </span>
      </Tooltip>
      {/*<Stack direction="row" justifyContent="space-between" marginTop="28px">*/}
      {/*<Button*/}
      {/*  variant="outlined"*/}
      {/*  sx={{ padding: "12px 24px" }}*/}
      {/*  onClick={handlePreviousStep}*/}
      {/*>*/}
      {/*  Previous Step*/}
      {/*</Button>*/}
      {/*<Stack direction="row" gap="16px">*/}
      {/*<Button*/}
      {/*  variant="outlined"*/}
      {/*  sx={{ padding: "12px 24px" }}*/}
      {/*  onClick={() => setIsCancelModalOpen(true)}*/}
      {/*>*/}
      {/*  Cancel*/}
      {/*</Button>*/}
      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  sx={{ padding: "12px 24px" }}*/}
      {/*  onClick={handleSave}*/}
      {/*>*/}
      {/*  Save And Continue*/}
      {/*</Button>*/}
      {/*<Button variant="contained" size="large" onClick={() => setIsConfirmSaveDraft(true)}>*/}
      {/*  Save Draft*/}
      {/*</Button>*/}
      {/*</Stack>*/}
      {/*</Stack>*/}
      {isOpen && renderUpsertPaymentGroupForm("create")}
      {isEditModalOpen && renderUpsertPaymentGroupForm("update")}

      <ConfirmModel
        visible={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        confirmation={handleDeletePaymentGroup}
        message={`Are you sure to delete the Payment Group ${selectedPaymentGroup?.paymentGroup?.groupName}`}
      />
      {/*<ConfirmModel*/}
      {/*  visible={isCancelModalOpen}*/}
      {/*  handleClose={() => setIsCancelModalOpen(false)}*/}
      {/*  confirmation={handleConfirm}*/}
      {/*  message="Are you sure to discard the changes"*/}
      {/*/>*/}
      {/*<ConfirmModel*/}
      {/*  visible={isConfirmSaveDraft}*/}
      {/*  handleClose={() => setIsConfirmSaveDraft(false)}*/}
      {/*  confirmation={handleSaveDraft}*/}
      {/*  message="Are you sure you want to save and quit?"*/}
      {/*/>*/}
    </Stack>
  );
};
export default PaymentGroup;
