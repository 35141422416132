import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getApiData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import authActions from "../../redux/reducers/auth/actions";
import Cmodal from "../Cmodel";
import ConfirmModel from "../ConfirmModel";
import Images from "../../config/images";
import DrawerList from "./components/DrawerList";
import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  SettingsOutlined,
  Person,
  AccountBalanceWalletOutlined,
} from "@mui/icons-material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { ReactComponent as LogoutActiveIcon } from "../../assets/svg/LogoutActiveIcon.svg";

const DrawerComponent = () => {
  const currentUrl = window.location.href;
  const { pathname } = useLocation();

  const isActive = (path) => pathname === path;
  const { userData, accountTab } = useSelector((state) => state.auth);
  const { clearAllData, setAccountTab } = authActions;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [mopen, setMopen] = useState("");
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const toggleProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  };

  useEffect(() => {
    if (!currentUrl.includes("account-setting") && accountTab !== 0) {
      dispatch(setAccountTab(0));
    }
  }, [location]);

  const handleClose = () => {
    setVisible(false);
  };

  const handleSignOut = () => setVisible(true);

  async function logout(type) {
    try {
      await getApiData(Setting.endpoints.logout, "POST");
      dispatch(clearAllData());
      handleClose();
      setTimeout(() => {
        type === "signup" ? navigate("/signup") : navigate("/login");
      }, 500);
    } catch (error) {
      console.log("🚀 logout ~ error:", error);
    }
  }
  console.log("currentUrl", currentUrl);

  return (
    <div>
      {currentUrl?.includes("signup") ||
      currentUrl?.includes("login") ||
      currentUrl?.includes("otp-verify") ? null : (
        <div className={classes.container}>
          <div
            className={classes.imgContainer}
            onClick={() => {
              if (userData?.contractor_data?.profile_completed === "pending") {
                return;
              } else {
                navigate("/dashboard");
              }
            }}
          >
            <img
              alt="logo"
              src={Images.header_logo}
              className={classes.imgStyle}
            />
          </div>

          <div
            style={{
              padding: "10px 18px",
              color: "#C5CBD4",
              fontWeight: "500",
              fontSize: "14px",
              marginTop: "1vh",
            }}
          >
            MAIN
          </div>

          <DrawerList userData={userData} handleSignOut={handleSignOut} />

          <div
            style={{
              padding: "10px 18px",
              color: "#C5CBD4",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            OTHER
          </div>
          {userData?.role === "designer" ? (
            <ListItem
              button
              onClick={() => navigate("/designer-documents")}
              style={{
                color: isActive("/designer-documents") ? "#4D6BF6" : "#949DAA",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              <InsertDriveFileOutlinedIcon style={{ marginRight: 15 }} />
              <ListItemText primary="Documents" />
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={() => navigate("/contractor-documents")}
              style={{
                color: isActive("/contractor-documents")
                  ? "#4D6BF6"
                  : "#949DAA",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              <InsertDriveFileOutlinedIcon style={{ marginRight: 15 }} />
              <ListItemText primary="Documents" />
            </ListItem>
          )}

          <div
            style={{
              marginTop: "auto",
              padding: "10px 10px",
              borderTop: "1px solid #e5e5e5",
            }}
          >
            {currentUrl?.includes("otp-verify") ? null : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    gap: "50px",
                  }}
                  onClick={toggleProfileMenu}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {userData?.profile_url ? (
                      <img
                        alt="profile"
                        src={userData?.profile_url}
                        className={classes.logoStyle}
                      />
                    ) : (
                      <Avatar style={{ color: "#FFF", cursor: "pointer" }} />
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#0D0D0E",
                          textTransform: "capitalize",
                        }}
                      >
                        {userData?.username}
                      </p>
                      <p
                        style={{
                          textTransform: "uppercase",
                          color: "#818898",
                          fontSize: "12px",
                        }}
                      >
                        {userData?.role}
                      </p>
                    </div>
                  </div>
                  {openProfileMenu ? <ExpandLess /> : <ExpandMore />}
                </div>

                <Collapse in={openProfileMenu}>
                  <List>
                    <ListItem
                      button
                      onClick={() => navigate("/account-setting")}
                      style={{
                        color: isActive("/account-setting")
                          ? "#4D6BF6"
                          : "#949DAA",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "1vh .4vw",
                      }}
                    >
                      <SettingsOutlined style={{ marginRight: 15 }} />
                      <ListItemText primary="Account Settings" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => navigate("/user-management")}
                      style={{
                        color: isActive("/user-management")
                          ? "#4D6BF6"
                          : "#949DAA",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "1vh .4vw",
                      }}
                    >
                      <Person style={{ marginRight: 15 }} />
                      <ListItemText primary="User Management" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={() => navigate("/billing")}
                      style={{
                        color: isActive("/billing") ? "#4D6BF6" : "#949DAA",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "1vh .4vw",
                      }}
                    >
                      <AccountBalanceWalletOutlined
                        style={{ marginRight: 15 }}
                      />
                      <ListItemText primary="Billing Info" />
                    </ListItem>

                    <ListItem
                      button
                      onClick={handleSignOut}
                      style={{
                        color: "#F26B59",
                        fontWeight: "600",
                        fontSize: "14px",
                        padding: "1vh .5vw",
                      }}
                    >
                      <LogoutActiveIcon style={{ marginRight: 18 }} />
                      <ListItemText primary="Sign Out" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
          </div>
        </div>
      )}

      <Cmodal
        visible={
          mopen === "currency" ? true : mopen === "language" ? true : false
        }
        handleClose={() => setMopen("")}
        currency={mopen}
      />
      <ConfirmModel
        visible={visible}
        handleClose={() => setVisible(false)}
        confirmation={() => logout("")}
        message="Are you sure you want to sign out?"
      />
    </div>
  );
};

export default DrawerComponent;
