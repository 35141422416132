import React, { useState } from "react";
import { toast } from "react-toastify";
import { postFormData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import { Box, Typography, IconButton } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

const UploadPdf = ({ projectId, setIsCalled }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    handleFileUpload(event.target.files[0]);
  };

  async function handleFileUpload(file) {
    if (!file) {
      toast.error("No file selected");
      return;
    }
    if (file.type !== "application/pdf") {
      toast.error("Invalid file type");
      return;
    }

    const formData = new FormData();
    formData.append("file_1", file);
    formData.append("type", "proposal");
    formData.append("tags", "LOOK_N_FEEL");
    formData.append("project_id", projectId);

    try {
      const response = await postFormData(
        Setting.endpoints.projectFiles,
        formData
      );
      if (response.success) {
        toast.success("File uploaded successfully");
        window.location.reload();
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      toast.error("An error occurred during file upload");
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        p: 2,
        border: "1px dashed #ccc",
        borderRadius: 1,
        cursor: "pointer",
        backgroundColor: "#f9f9f9",
      }}
    >
      <input
        type="file"
        id="pdf-upload-input"
        accept="application/pdf"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <IconButton component="label" htmlFor="pdf-upload-input" sx={{ mb: 1 }}>
        <UploadIcon />
      </IconButton>

      <Typography
        variant="body1"
        color="textSecondary"
        style={{ fontSize: "12px" }}
      >
        You can upload a PDF design document here.
      </Typography>
    </Box>
  );
};

export default UploadPdf;
