import { Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../redux/reducers/common/actions";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getCreateProposalPayload, getSaveDraftPayload } from "./helpers";
import { getAPIProgressData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import ConfirmModel from "../../components/ConfirmModel";
import authActions from "../../redux/reducers/auth/actions";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import ProfileSuccessModal from "../../components/ProfileSuccessModal";

export default function TabActions({
  tabValue,
  onBackButtonClick,
  onNextButtonClick,
}) {
  const dispatch = useDispatch();
  const { setIsConfirmModalSaveDraftOpen, setIsCancelModalOpen } =
    commonActions;
  const { proposalDetails, errors } = useSelector((state) => state.auth);
  const { setProposalDetails, resetProposalDetails, setErrors, resetErrors } =
    authActions;
  const { isConfirmModalSaveDraftOpen, isCancelModalOpen } = useSelector(
    (state) => state.common
  );
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const location = useLocation();
  const [isConfirmSubmission, setIsConfirmSubmission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessSubmission, setIsSuccessSubmission] = useState(false);
  useEffect(() => {}, [tabValue]);
  const handlePreviousStep = () => {
    // const budgets = updatedBudgets;
    //
    // const milestoneIds = Object.keys(milestones);
    // const budgetIds = Object.keys(budgets);
    // const paymentGroup = updatePaymentGroup();
    //
    // dispatch(
    //   setProposalDetails({
    //     ...proposalDetails,
    //     budgets,
    //     budgetIds,
    //     milestones,
    //     milestoneIds,
    //     payment_group: paymentGroup,
    //     start_date: startDate,
    //     end_date: endDate,
    //   })
    // );
    // go back one step, to do that, save the tab value in redux state
    onBackButtonClick();
    // handleTabValue(0);
  };
  const validateMilestones = () => {
    const hasEachMilestoneDoesNotHaveDates = Object.keys(
      proposalDetails.milestones
    ).some((milestoneId) => {
      const milestone = proposalDetails.milestones[milestoneId];
      if (!(milestone.start_date || milestone.end_date)) {
        return true;
      }
      return false;
    });

    if (
      hasEachMilestoneDoesNotHaveDates ||
      Object.keys(proposalDetails.milestones).length < 1
    ) {
      if (hasEachMilestoneDoesNotHaveDates) {
        toast.error("Every milestone should have start and end date");
      } else {
        toast.error("Please add at least one milestone");
      }
    }

    const valid =
      !!Object.keys(proposalDetails.milestones).length &&
      !hasEachMilestoneDoesNotHaveDates;
    dispatch(
      setErrors({
        ...errors,
        isMilestonesValid: valid,
      })
    );

    return valid;
  };
  const isCreateProposal = location?.state?.isCreateProposal || false;
  const validateSummary = (isSaveDraftButtonClicked) => {
    if (isSaveDraftButtonClicked) {
      const error = { ...errors };
      let valid = true;

      if (!proposalDetails.project_type) {
        valid = false;
        error.typeErr = true;
        error.typeMsg = "Please select project type";
      }
      if (isEmpty(proposalDetails.name?.trim())) {
        valid = false;
        error.nameErr = true;
        error.nameMsg = "Please enter project name";
      }
      dispatch(
        setErrors({
          summaryErrors: { ...error },
        })
      );
      if (!valid) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      return valid;
    }
    const error = { ...errors };
    let valid = true;

    if (isEmpty(proposalDetails.scope_of_work)) {
      valid = false;
      error.scpErr = true;
      error.scpMsg = "Please enter scope of project";
    }

    if (isCreateProposal) {
      if (!proposalDetails.project_type) {
        valid = false;
        error.typeErr = true;
        error.typeMsg = "Please select project type";
      }
      if (isEmpty(proposalDetails.name?.trim())) {
        valid = false;
        error.nameErr = true;
        error.nameMsg = "Please enter project name";
      }

      if (isEmpty(proposalDetails.description?.trim())) {
        valid = false;
        error.descriptionErr = true;
        error.descriptionMsg = "Please enter project description";
      }
      if (isEmpty(proposalDetails.customer_name?.trim())) {
        valid = false;
        error.cNameErr = true;
        error.cNameMsg = "Please enter customer name";
      }

      if (isEmpty(proposalDetails.email)) {
        valid = false;
        error.emailErr = true;
        error.emailMsg = "Please enter email";
      } else if (!emailRegex.test(proposalDetails.email)) {
        valid = false;
        error.emailErr = true;
        error.emailMsg = "Please enter valid email";
      }
    }
    // add isSummaryValid in this object to indicate that all is valid instead of the current way
    // I can do this in all steps
    dispatch(
      setErrors({
        summaryErrors: { ...error },
        isSummaryValid: valid,
      })
    );
    if (!valid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    return valid;
  };

  const validateBudgets = () => {
    if (Object.keys(proposalDetails.budgets).length < 1) {
      toast.error("Please add at least one budget");
    }
    let valid = !!Object.keys(proposalDetails.budgets).length;

    dispatch(
      setErrors({
        ...errors,
        isBudgetsValid: valid,
      })
    );
    return valid;
  };

  const validatePaymentGroups = () => {
    const hasEveryMilestonesHaveAGroup = Object.values(
      proposalDetails.milestones
    ).every((milestone) => milestone?.in_payment_group);

    const hasEachMilestoneDoesNotHaveDates = Object.keys(
      proposalDetails.milestones
    ).some((milestoneId) => {
      const milestone = proposalDetails.milestones[milestoneId];
      if (!(milestone.start_date || milestone.end_date)) {
        return true;
      }
      return false;
    });

    if (hasEachMilestoneDoesNotHaveDates || !hasEveryMilestonesHaveAGroup) {
      if (hasEachMilestoneDoesNotHaveDates) {
        toast.error("Every milestone should have start and end date");
      } else {
        toast.error("All milestones should have a group");
      }
    }

    let valid =
      !hasEachMilestoneDoesNotHaveDates && hasEveryMilestonesHaveAGroup;
    dispatch(
      setErrors({
        ...errors,
        isPaymentGroupsValid: valid,
      })
    );
    return valid;
  };

  const validate = ({ step, isDraft = undefined }) => {
    // summary step
    if (step == 0) {
      return validateSummary(isDraft);
    }
    // milestone step
    if (step == 1) {
      return validateMilestones();
    }
    // budgets step
    if (step == 2) {
      return validateBudgets();
    }
    // payment groups step
    if (step == 3) {
      return validatePaymentGroups();
    }
    return true;
  };
  const handleNextButton = () => {
    let isValid = validate({ step: tabValue });
    if (!isValid) return;
    // const budgets = updatedBudgets;

    // const milestoneIds = Object.keys(milestones);
    // const budgetIds = Object.keys(budgets);
    // const paymentGroup = updatePaymentGroup();

    // dispatch(
    //   setProposalDetails({
    //     ...proposalDetails,
    //     budgets,
    //     budgetIds,
    //     milestones,
    //     milestoneIds,
    //     payment_group: paymentGroup,
    //     start_date: startDate,
    //     end_date: endDate,
    //   })
    // );
    // handleDisableTab("budgetTab");
    onNextButtonClick();
    // handleTabValue(2);
  };
  const handleSaveDraftConfirm = () => {
    let isValid = validate({ step: tabValue, isDraft: true });
    if (!isValid) return;
    dispatch(setIsConfirmModalSaveDraftOpen(true));
  };

  const navigate = useNavigate();

  const handleCancelButton = () => {
    dispatch(setIsCancelModalOpen(false));
    dispatch(resetProposalDetails());
    dispatch(resetErrors());
    navigate("/proposals");
  };

  const convertBase64ToImageFile = (base64String, filename) => {
    const arr = base64String?.split(",");
    const mimeType = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);

    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    const file = new File([uint8Array], filename, { type: mimeType });

    return file;
  };

  const convertProjectToFiles = () => {
    let projectFiles;
    // if (isCreateProposal) {
    //   projectFiles = proposalDetails?.project?.map((base64String, index) => {
    //     const filename = `project_image_${index + 1}.jpg`;
    //     return convertBase64ToImageFile(base64String, filename);
    //   });
    //   return projectFiles;
    // } else {
    // proposalDetails.project_image is also used in edit proposal in a different way other than create/draft proposal
    projectFiles = isCreateProposal
      ? proposalDetails?.project_image?.map((base64String, index) => {
          const filename = `project_image_${index + 1}.jpg`;
          return convertBase64ToImageFile(base64String, filename);
        })
      : [];
    // return projectFiles;
    // }
    // const projectFiles = proposalDetails?.project_image?.map(
    //   (base64String, index) => {
    //     const filename = `project_image_${index + 1}.jpg`;
    //     return convertBase64ToImageFile(base64String, filename);
    //   }
    // );
    return projectFiles;
    // }
  };
  const villa = location?.state?.villa ? location?.state?.villa : {};

  const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);
  console.log(isUpdateDraftProposal, ">>>>>>>>>>>.isUpdateDraftProposal");

  const proposalId = isUpdateDraftProposal
    ? villa?.proposal_id
    : proposalDetails.saveDraftProposalId
    ? proposalDetails.saveDraftProposalId
    : null;
  const query = isUpdateDraftProposal ? `?proposal_id=${proposalId}` : "";

  const handleSaveDraft = async () => {
    const projectFiles = convertProjectToFiles();
    const payload = getSaveDraftPayload({
      proposalDetails,
      projectFiles,
    });
    setIsLoading(true);
    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.createDraftProposal}${query}`,
        "POST",
        payload,
        true
      );

      if (response.success) {
        dispatch(
          setProposalDetails({
            ...proposalDetails,
            saveDraftProposalId: proposalId,
          })
        );
        toast.success("Draft created Successfully");
        navigate("/proposals");
        dispatch(setIsConfirmModalSaveDraftOpen(false));
      } else {
        toast.error(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("save draft api error", error);
      toast.error(error.toString());
      setIsLoading(false);
    }
  };

  async function createproposalApicall(data) {
    setIsLoading(true);
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.directproposal,
        "POST",
        data,
        true
      );

      if (response.success) {
        setIsConfirmSubmission(false);
        setIsSuccessSubmission(true);
      } else {
        toast.error(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
    }
  }

  const handleSubmitProposal = () => {
    const hasMilestones = Object.keys(proposalDetails?.milestones).length > 0;
    const hasBudgets = Object.keys(proposalDetails.budgets).length > 0;
    const hasEachMilestoneDoesNotHaveDates = Object.keys(
      proposalDetails.milestones
    ).some((milestoneId) => {
      const milestone = proposalDetails.milestones[milestoneId];
      if (!(milestone.start_date || milestone.end_date)) {
        return true;
      }
      return false;
    });

    if (!hasMilestones || !hasBudgets || hasEachMilestoneDoesNotHaveDates) {
      if (!hasMilestones) {
        toast.error("Please add at least one milestone");
      } else if (hasEachMilestoneDoesNotHaveDates) {
        toast.error("Every milestone should have start and end date");
      } else {
        toast.error("Please add at least one budget");
      }
    } else {
      setIsConfirmSubmission(true);
    }
  };
  const handleSubmit = () => {
    const projectFiles = convertProjectToFiles();
    const payload = getCreateProposalPayload(proposalDetails, projectFiles);
    // Object.values(budgets).forEach((budget, index) => {
    //   const photoOriginFiles = convertPhotoOriginToFiles(budget);
    //   payload[`budget_image_${index + 1}`] = photoOriginFiles;
    // });
    if (isUpdateDraftProposal) {
      payload["proposal_id"] = proposalId;
    }
    createproposalApicall(payload);
  };

  return (
    <Stack width="100%" direction="row" justifyContent="space-between">
      <Stack>
        {tabValue > 0 && (
          <Button
            variant="outlined"
            size="small"
            sx={{ boxShadow: "none", padding: "12px 24px" }}
            onClick={handlePreviousStep}
          >
            Previous Step
          </Button>
        )}
      </Stack>
      <Stack direction="row" gap={2}>
        <Button
          variant="outlined"
          onClick={() => dispatch(setIsCancelModalOpen(true))}
          style={{ padding: "12px 24px" }}
        >
          Cancel
        </Button>
        {tabValue < 4 ? (
          <Button
            variant="contained"
            onClick={handleNextButton}
            style={{ padding: "12px 24px" }}
          >
            Save And Continue
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmitProposal}
          >
            Submit
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          onClick={handleSaveDraftConfirm}
        >
          Save Draft
        </Button>
      </Stack>
      <ConfirmModel
        visible={isCancelModalOpen}
        handleClose={() => dispatch(setIsCancelModalOpen(false))}
        confirmation={handleCancelButton}
        message="Are you sure to discard the changes"
      />
      <ConfirmModel
        visible={isConfirmModalSaveDraftOpen}
        handleClose={() => dispatch(setIsConfirmModalSaveDraftOpen(false))}
        confirmation={handleSaveDraft}
        loader={isLoading}
        message="Are you sure you want to save and quit?"
      />
      <ConfirmModel
        visible={isConfirmSubmission}
        loader={isLoading}
        title={"Submit"}
        handleClose={() => setIsConfirmSubmission(false)}
        confirmation={handleSubmit}
        message={`Are you sure you want to submit proposal?`}
      />
      {isSuccessSubmission && (
        <ProfileSuccessModal
          title="Congrats!"
          msg="Proposal successfully submitted!"
          btnTitle="Continue"
          visible={isSuccessSubmission}
        />
      )}
    </Stack>
  );
}
