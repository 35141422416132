import { Box, Stack, Typography, Divider, Button } from "@mui/material";
import { useState } from "react";

const ImageGallery = ({ details }) => {
  const [showAllImages, setShowAllImages] = useState(false);

  const totalImages = details?.images?.length || 0;
  const remainingImages = totalImages - 8;

  const imagesToDisplay = showAllImages
    ? details?.images
    : details?.images?.slice(0, 8);

  const toggleShowMore = () => setShowAllImages(!showAllImages);

  return (
    <Stack gap="12px" width="100%">
      {details?.images && (
        <>
          <Stack direction="row" gap="4px" width="100%">
            {totalImages > 0 && (
              <Typography padding="8px 16px" style={{ display: "flex" }}>
                Package Images
                <span
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px 10px",
                    margin: "0px 8px",
                    backgroundColor: "#E9B55C",
                    borderRadius: 22,
                  }}
                >
                  {totalImages}
                </span>
              </Typography>
            )}
          </Stack>
          <Stack
            width="100%"
            gap="10px"
            flexWrap="wrap"
            direction="row"
            padding={"4px 18px"}
          >
            {imagesToDisplay?.map((image, index) => {
              // Apply overlay for the 8th image if not showing all images
              const isLastVisibleImage =
                !showAllImages && index === 7 && remainingImages > 0;
              return (
                <Box
                  key={image.id}
                  component="div"
                  sx={{
                    position: "relative",
                    width: "calc(20% - 8px)", // 5 images per row
                    height: "200px",
                    mb: 1,
                    borderRadius: 1,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src={image.path}
                    alt={image.tags}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "inherit",
                      filter: isLastVisibleImage ? "brightness(50%)" : "none",
                    }}
                  />
                  {isLastVisibleImage && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "#fff",
                        fontSize: "16px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={toggleShowMore}
                    >
                      + {remainingImages} more
                    </Box>
                  )}
                </Box>
              );
            })}
          </Stack>
          {totalImages > 8 && (
            <>
            {showAllImages &&
            <Button
              variant="text"
              onClick={toggleShowMore}
              sx={{
                alignSelf: "start",
                color: "#1976d2",
                textTransform: "none",
                margin: "0 1vw 1vh 1vw",
              }}
            >
              Show Less 
            </Button>
            }
            </>
          )}
          <Divider />
        </>
      )}
    </Stack>
  );
};

export default ImageGallery;
