import React, { useState } from 'react';
import { Box, Typography, IconButton, Dialog, DialogContent, Skeleton } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

const ImageGallery = ({ projectFiles }) => {
  const [isImagesPreviewVisible, setIsImagesPreviewVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpenPreview = (index) => {
    setCurrentImageIndex(index);
    setIsImagesPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setIsImagesPreviewVisible(false);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % projectFiles?.supportingImages?.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + projectFiles?.supportingImages?.length) % projectFiles?.supportingImages?.length
    );
  };

  console.log("s",projectFiles)

  return (
    <Box sx={{ mb: 3 }}>
     <Typography 
      fontFamily={"Inter Tight"}
      fontWeight="bold"
      fontSize="18px"
      paddingBottom="1vh"
    >
      Supporting Images
    </Typography>
    <Box display="flex" gap={2}>
      {projectFiles?.loading ? (
        <Skeleton variant="rectangular" width={100} height={100} />
      ) : (
        projectFiles?.supportingImages?.length > 0 ? (
          projectFiles?.supportingImages?.slice(0, 2)?.map((image, index) => (
            <Box
              key={image?.path}
              sx={{
                cursor: 'pointer',
                position: 'relative',
                '&:hover': { opacity: 0.8 },
              }}
              onClick={() => handleOpenPreview(index)}
            >
              <img
                src={image?.path}
                alt={`supporting-img-${index}`}
                height={100}
                width={100}
                style={{ objectFit: 'cover' }}
              />
              {index === 1 && projectFiles?.supportingImages?.length > 2 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    fontSize: 18,
                  }}
                >
                  +{projectFiles?.supportingImages?.length - 2}
                </Box>
              )}
            </Box>
          ))
        ) : (
          <Typography>No Images Found</Typography>
        )
      )}
    </Box>


      <Dialog open={isImagesPreviewVisible} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogContent sx={{ position: 'relative' }}>
          <IconButton
            onClick={handlePrevious}
            sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        
          <div
            style={{
                textAlign:"center",
                paddingBottom:"1vh"
            }}
          >
            {currentImageIndex + 1} / {projectFiles?.supportingImages?.length}
          </div>
          <img
            src={projectFiles?.supportingImages[currentImageIndex]?.path}
            alt={`preview-img-${currentImageIndex}`}
            height={400}
            width={400}
            style={{ objectFit: 'cover', margin: '0 auto', display: 'block' }}
          />
          <IconButton
            onClick={handleNext}
            sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
          >
            <ChevronRightIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ImageGallery;
