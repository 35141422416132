import {
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import authActions from "../../../redux/reducers/auth/actions";
import CAutocomplete from "../../../components/CAutocomplete";
import theme from "../../../config/theme";
import { useEffect, useState } from "react";
import { getApiData } from "../../../utils/APIHelper";
import { Setting } from "../../../utils/Setting";
import _, { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import CInput from "../../../components/CInput";
import Images from "../../../config/images";
import { HighlightOffOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import HomeOwnerSummary from "../../RequestedProposal/HomeOwnerSummary";
import ProjectDetails from "../ProjectDetails";
const Summary = ({
  isCreateProposal,
  villa,
  handleTabValue,
  handleDisableTab,
  classes,
  isDraft,
}) => {
  const dispatch = useDispatch();
  const { setProposalDetails, setErrors } = authActions;
  const { proposalDetails, errors } = useSelector((state) => state.auth);
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [expertiseList, setExpertiesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadLoader, setUploadLoader] = useState(false);

  useEffect(() => {
    getprojectList();
  }, []);

  // useEffect(() => {
  //   if (villa && Object.keys(villa).length > 0 && villa?.project) {
  //     console.log("vileee",villa?.project)
  //     dispatch(
  //       setProposalDetails({
  //         ...proposalDetails,
  //         form_json: villa?.project?.form_json !== "" && villa?.project?.form_json !== null && villa?.project?.form_json !== undefined
  //           ? JSON.parse(villa?.project?.form_json)
  //           : null,
  //         dimensions: villa?.project?.dimensions !== "" && villa?.project?.dimensions !== null && villa?.project?.dimensions !== undefined
  //           ? JSON.parse(villa?.project?.dimensions)
  //           : null,
  //       })
  //     );
  //   }
  // }, [villa]);

  useEffect(() => {
    console.log("props", proposalDetails);
  }, [proposalDetails]);

  async function getprojectList() {
    setIsLoading(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.projectlist}`,
        "GET",
        {}
      );
      if (response.success) {
        setIsLoading(false);
        if (isArray(response?.data) && !isEmpty(response?.data)) {
          setExpertiesList(response?.data);
        } else {
          setExpertiesList([]);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  }

  function checkImgSize(img) {
    let valid = true;
    if (img.size > 3145728) {
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  async function UploadFile(img) {
    const nArr1 = proposalDetails.project_image
      ? [...proposalDetails.project_image]
      : [];
    for (let i = 0; i < img.length; i++) {
      const base64Data = await convertToBase64(img[i]);
      nArr1.push(base64Data);
    }
    dispatch(
      setProposalDetails({
        ...proposalDetails,
        project_image: nArr1,
      })
    );

    dispatch(
      setErrors({
        ...errors,
        summaryErrors: {
          ...errors.summaryErrors,
          photoErr: false,
          photoMsg: "",
        },
        isSummaryValid: false,
        isMilestonesValid: false,
        isBudgetsValid: false,
      })
    );
  }

  async function deleteImage(image) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteProjectImageById(image.id)}`,
        "POST"
      );
      if (response.success) {
        toast.success("Image deleted successfully from our database");
        dispatch(
          setProposalDetails({
            ...proposalDetails,
            uploadedImages: proposalDetails.uploadedImages.filter(
              (uploadedImage) => uploadedImage.id !== image.id
            ),
          })
        );
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }
  function CustomImage({ image, index, handleDelete }) {
    return (
      <div
        key={index}
        style={{
          display: "flex",
          border: "1px solid #F2F3F4",
          borderRadius: 6,
          marginBottom: 10,
          padding: 3,
        }}
      >
        <img
          style={{
            width: 60,
            height: 70,
            borderRadius: 6,
            marginRight: 20,
            objectFit: "cover",
          }}
          src={image}
          alt="Proposal image"
        />
        <div style={{ margin: "auto 0" }}>
          <Typography
            style={{
              fontFamily: "Inter Tight",
              fontWeight: "500",
              color: "#202939",
              fontSize: 18,
            }}
          >
            {image?.name || `Image ${index + 1}` || ""}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: 10,
          }}
        >
          <HighlightOffOutlined
            style={{
              zIndex: 10,
              cursor: "pointer",
              fontSize: 28,
              color: "#8C92A4",
            }}
            onClick={() => {
              handleDelete();
            }}
          />
        </div>
      </div>
    );
  }
  function displayUploadedImages() {
    return proposalDetails?.uploadedImages?.map((image, index) => {
      return (
        <CustomImage
          image={image.image}
          index={index}
          handleDelete={() => {
            deleteImage(image);
          }}
        />
      );
    });
  }
  function displayImagesToBeUploaded() {
    return proposalDetails?.project_image.map((image, index) => {
      return (
        <CustomImage
          image={image}
          index={index}
          handleDelete={() => {
            const nArr = [...proposalDetails.project_image];
            nArr.splice(index, 1);
            dispatch(
              setProposalDetails({
                ...proposalDetails,
                project_image: nArr,
              })
            );
          }}
        />
      );
    });
  }

  return isLoading ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 0",
      }}
    >
      <CircularProgress size={40} />
    </div>
  ) : (
    <>
      {isCreateProposal && (
        <>
          <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
            <Grid item xs={6} style={{ paddingTop: 25 }}>
              <CAutocomplete
                label="Type"
                loading={isLoading}
                placeholder="Select project type"
                value={expertiseList?.find(
                  (option) =>
                    option?.project_name?.trim() ===
                    proposalDetails?.project_type?.trim()
                )}
                onChange={(e, newValue) => {
                  dispatch(
                    setProposalDetails({
                      ...proposalDetails,
                      project_type: newValue?.project_name?.trim(),
                    })
                  );
                  dispatch(
                    setErrors({
                      ...errors,
                      summaryErrors: {
                        ...errors.summaryErrors,
                        typeErr: false,
                        typeMsg: "",
                      },
                      isSummaryValid: false,
                      isMilestonesValid: false,
                      isBudgetsValid: false,
                    })
                  );
                }}
                options={expertiseList}
                getOptionLabel={(option) => option?.project_name?.trim()}
                error={errors.summaryErrors.typeErr}
                helpertext={errors.summaryErrors.typeMsg}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingTop: 25 }}>
              <CInput
                label="Project Name"
                placeholder="Write here..."
                value={proposalDetails.name}
                onChange={(e) => {
                  dispatch(
                    setProposalDetails({
                      ...proposalDetails,
                      name: e.target.value,
                    })
                  );
                  dispatch(
                    setErrors({
                      ...errors,
                      summaryErrors: {
                        ...errors.summaryErrors,
                        nameErr: false,
                        nameMsg: "",
                      },
                      isSummaryValid: false,
                      isMilestonesValid: false,
                      isBudgetsValid: false,
                    })
                  );
                }}
                inputProps={{ maxLength: 50 }}
                error={errors.summaryErrors.nameErr}
                helpertext={errors.summaryErrors.nameMsg}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <CInput
              multiline={true}
              rows={3}
              label="Description"
              placeholder="Write here..."
              value={proposalDetails.description}
              onChange={(e) => {
                dispatch(
                  setProposalDetails({
                    ...proposalDetails,
                    description: e.target.value,
                  })
                );
                dispatch(
                  setErrors({
                    ...errors,
                    summaryErrors: {
                      ...errors.summaryErrors,
                      descriptionErr: false,
                      descriptionMsg: "",
                    },
                    isSummaryValid: false,
                    isMilestonesValid: false,
                    isBudgetsValid: false,
                  })
                );
              }}
              error={errors.summaryErrors.descriptionErr}
              helpertext={errors.summaryErrors.descriptionMsg}
            />
          </Grid>
          <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
            <Grid item xs={6}>
              <CInput
                label="Customer Name"
                placeholder="Write here..."
                value={proposalDetails.customer_name}
                onChange={(e) => {
                  dispatch(
                    setProposalDetails({
                      ...proposalDetails,
                      customer_name: e.target.value,
                    })
                  );
                  dispatch(
                    setErrors({
                      ...errors,
                      summaryErrors: {
                        ...errors.summaryErrors,
                        cNameErr: false,
                        cNameMsg: "",
                      },
                      isSummaryValid: false,
                      isMilestonesValid: false,
                      isBudgetsValid: false,
                    })
                  );
                }}
                inputProps={{ maxLength: 50 }}
                error={errors.summaryErrors.cNameErr}
                helpertext={errors.summaryErrors.cNameMsg}
              />
            </Grid>
            <Grid item xs={6}>
              <CInput
                label="Customer Email"
                placeholder="Enter email address"
                value={proposalDetails.email}
                onChange={(e) => {
                  dispatch(
                    setProposalDetails({
                      ...proposalDetails,
                      email: e.target.value,
                    })
                  );
                  dispatch(
                    setErrors({
                      ...errors,
                      summaryErrors: {
                        ...errors.summaryErrors,
                        emailErr: false,
                        emailMsg: "",
                      },
                      isSummaryValid: false,
                      isMilestonesValid: false,
                      isBudgetsValid: false,
                    })
                  );
                }}
                error={errors.summaryErrors.emailErr}
                helpertext={errors.summaryErrors.emailMsg}
              />
            </Grid>
          </Grid>
        </>
      )}
        <Grid item xs={12} style={{ paddingTop: 25 }}>
        {!isDraft && (
          <>
            {!isCreateProposal && <ProjectDetails proposalDetails={proposalDetails} />}
            <br />
            <HomeOwnerSummary
              proposalDetails={proposalDetails}
              classes={classes}
            />
          </>
        )}
        <CInput
          multiline={true}
          rows={4}
          label="Scope of work"
          placeholder="Write here..."
          value={proposalDetails.scope_of_work}
          onChange={(e) => {
            dispatch(
              setProposalDetails({
                ...proposalDetails,
                scope_of_work: e.target.value,
              })
            );
            dispatch(
              setErrors({
                ...errors,
                summaryErrors: {
                  ...errors.summaryErrors,
                  scpErr: false,
                  scpMsg: "",
                },
                isSummaryValid: false,
                isMilestonesValid: false,
                isBudgetsValid: false,
              })
            );
          }}
          error={errors.summaryErrors.scpErr}
          helpertext={errors.summaryErrors.scpMsg}
        />
      </Grid>
      {isCreateProposal && (
        <>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: 20,
            }}
          >
            {uploadLoader ? (
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={12}
                minHeight={220}
              >
                <CircularProgress style={{ color: "#274BF1" }} size={26} />
              </Grid>
            ) : (
              <>
                <InputLabel shrink error={errors.summaryErrors.documentErr}>
                  Upload supporting pictures or documentation
                </InputLabel>
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: 170,
                      border: "1px dashed #9CA3AF",
                      borderRadius: 4,
                      gap: 1,
                    }}
                  >
                    <div style={{ width: "24px", height: "24px" }}>
                      <img src={Images.upload_icon} alt="upload-icon"></img>
                    </div>
                    <InputLabel>
                      <b>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#2563EB",
                          }}
                        >
                          Click to upload Images
                        </span>{" "}
                        or drag and drop{" "}
                      </b>
                    </InputLabel>
                    <InputLabel style={{ fontSize: 12, color: "#6B7280" }}>
                      {"PNG, JPG, (max size 1200*800)"}
                    </InputLabel>
                  </div>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    multiple
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      opacity: 0,
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      const chosenFiles = Array.prototype.slice.call(
                        e.target.files
                      );
                      const rejected = chosenFiles.every(
                        (item) =>
                          item.type === "image/png" ||
                          item.type === "image/jpg" ||
                          item.type === "image/jpeg"
                      );
                      if (!rejected) {
                        toast.error("You can only add jpeg,jpg or png");
                      }
                      const filteredFiles = chosenFiles.filter(
                        (item) =>
                          item.type === "image/png" ||
                          item.type === "image/jpg" ||
                          item.type === "image/jpeg"
                      );

                      let showMsg = false;
                      let limit = false;
                      console.log("image", proposalDetails.project_image);
                      const newArr = [...proposalDetails.project_image];
                      filteredFiles.map((item) => {
                        const bool = checkImgSize(item);
                        if (bool && newArr.length < 5) {
                          newArr.push(item);
                        } else if (newArr.length >= 4) {
                          limit = true;
                        } else {
                          showMsg = true;
                        }
                      });
                      if (limit) {
                        toast.error("You can upload maximum 5 files");
                      } else if (showMsg) {
                        toast.error(
                          "Some registraion you are attempting to upload exceeds the maximum file size limit of 3 MB. Please reduce the size of your image and try again."
                        );
                      }
                      // let shouldUpload =
                      //   isArray(newArr) &&
                      //   !isEmpty(newArr) &&
                      //   newArr?.filter((elem) => typeof elem !== "string");
                      // if (shouldUpload) {
                      UploadFile(chosenFiles);
                      // }
                    }}
                  />
                  <FormHelperText
                    error={errors.summaryErrors.documentErr}
                    style={{ fontFamily: "Inter Tight" }}
                  >
                    {errors.summaryErrors.documentMsg}
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "20px",
                    overflowY: "auto",
                    maxHeight: "170px",
                    width: "100%",
                  }}
                >
                  {displayUploadedImages()}
                  {displayImagesToBeUploaded()}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
      {isDraft && proposalDetails?.proposal_status === "Requested" && (
        <Grid item xs={12}>
          <HomeOwnerSummary
            proposalDetails={proposalDetails}
            classes={classes}
          />
        </Grid>
      )}
    </>
  );
};
export default Summary;
