import { useState, useCallback } from "react";
import { ChevronRight } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as DesignIcon } from "../../assets/svg/DesignIcon.svg";
import { ReactComponent as RenovationIcon } from "../../assets/svg/RenovationIcon.svg";
import { ReactComponent as MilestoneIcon } from "../../assets/svg/MilestoneIcon.svg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Typography,
  Stack,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import {
  formattedAmount,
  transformDateFormat,
} from "../../utils/CommonFunction";
import useStyles from "./styles";
import { useTheme } from "@emotion/react";
import { getApiData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";

const SingleMilestoneAccordion = ({
  milestone,
  id,
  handleRowClick,
  children,
  group,
  handleCheckbox,
  checked,
  isEditScreen,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [imageDetails, setImageDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeExpanded = useCallback(
    (event, isExpanded) => {
      if (isExpanded && !expanded && isEditScreen) {
        console.log("Fetching image for milestone id:", id);
        getMilestoneImage(id);
      }
      setExpanded(isExpanded);
    },
    [expanded, id, isEditScreen]
  );
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  async function getMilestoneImage(id) {
    setLoading(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.milestoneUpdates}/${id}?type=milestone`,
        "GET",
        {}
      );
      if (response.success) {
        setImageDetails(response?.data || []);
      }
    } catch (error) {
      console.log("Error fetching milestone image:", error);
    } finally {
      setLoading(false);
    }
  }

  const getImagePath = () => {
    if (imageDetails[0]?.files.length > 0) {
      return imageDetails[0].files[0]?.path;
    }
    return "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"; // Placeholder image
  };

  return (
    <Stack width="100%">
      <Accordion
        key={id}
        expanded={expanded}
        onChange={handleChangeExpanded}
        style={{
          boxShadow: "none",
          borderRadius: "8px",
          backgroundColor: handleCheckbox || group ? "white" : "#f3f4f9",
          border: handleCheckbox || group ? "1px solid #E8E8E8" : "none",
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            minHeight: "64px",
            ".MuiAccordionSummary-content": {
              margin: 0,
              padding: "10px",
            },
            "Mui-expanded": {
              margin: "0px",
            },
          }}
        >
          {handleCheckbox && (
            <Checkbox
              defaultChecked={checked}
              onClick={(e) => handleCheckbox(e, milestone, id)}
            />
          )}
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Stack width="50%" gap="2px" alignItems="center" direction="row">
              {expanded ? <ExpandMoreIcon /> : <ChevronRight />}
              <div style={{ marginRight: 10, display: "flex" }}>
                {milestone?.tags === "Renovation" ? (
                  <RenovationIcon />
                ) : milestone?.tags === "Design" ? (
                  <DesignIcon />
                ) : (
                  <MilestoneIcon />
                )}
              </div>
              <span style={{ fontFamily: "Inter Tight" }}>
                {milestone?.milestone_name}
              </span>
            </Stack>
            <Stack width={lg ? "40%" : "50%"}>
              <div
                className={classes.styledGrid}
                style={{
                  gridTemplateColumns: handleRowClick
                    ? "1fr 1fr 1fr"
                    : "1fr 1fr",
                  gap: handleRowClick ? "24px" : 0,
                }}
              >
                {milestone?.end_date && milestone?.end_date.trim() !== "" ? (
                  <Stack direction="column" marginRight={2}>
                    <div className="accLabel">End Date</div>
                    <div className="accLabelValue">
                      {transformDateFormat(milestone?.end_date)}
                    </div>
                  </Stack>
                ) : (
                  <Stack></Stack>
                )}
                {milestone?.amount ? (
                  <Stack marginRight={1}>
                    <div className="accLabel" style={{ textAlign: "end" }}>
                      Amount
                    </div>
                    <div className="accLabelValue" style={{ textAlign: "end" }}>
                      {formattedAmount(milestone?.amount)}
                    </div>
                  </Stack>
                ) : (
                  <Stack></Stack>
                )}

                {handleRowClick ? (
                  <Stack alignItems="center">
                    <IconButton
                      onClick={(e) => handleRowClick(e, milestone, id)}
                      style={{ alignItems: "start", padding: "0px" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Stack>
                ) : (
                  <Stack></Stack>
                )}
              </div>
            </Stack>
          </Stack>
        </AccordionSummary>
        <Divider sx={{ margin: "0px 16px" }} />
        <AccordionDetails
          style={{
            padding: "12px 40px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Grid item sx={12} rowGap="16px">
              <Stack>
                {loading ? (
                  <CircularProgress size={40} />
                ) : (
                  <img
                    style={{
                      width: "128px",
                      height: "128px",
                      borderRadius: "4px",
                    }}
                    src={getImagePath()}
                    alt="milestone"
                  />
                )}
              </Stack>
            </Grid>
            <div style={{ width: "100%" }}>
              <Stack direction="row" gap={8}>
                {milestone.start_date && milestone.start_date.trim() !== "" ? (
                  <Stack>
                    <div component="span" className="accLabel">
                      Start Date
                    </div>
                    <div component="span" className="accLabelValue">
                      {transformDateFormat(milestone?.start_date)}
                    </div>
                  </Stack>
                ) : (
                  <Stack></Stack>
                )}

                {milestone.end_date && milestone.end_date.trim() !== "" ? (
                  <Stack>
                    <div component="span" className="accLabel">
                      End Date
                    </div>
                    <div component="span" className="accLabelValue">
                      {transformDateFormat(milestone?.end_date)}
                    </div>
                  </Stack>
                ) : (
                  <Stack></Stack>
                )}
                {milestone.amount > 0 && (
                  <Grid
                    display="flex"
                    item
                    lg={5}
                    sm={12}
                    md={5}
                    xs={12}
                    direction="column"
                  >
                    <div component="span" className="accLabel">
                      Amount
                    </div>
                    <div component="span" className="accLabelValue">
                      {formattedAmount(milestone?.amount)}
                    </div>
                  </Grid>
                )}
              </Stack>
              <Stack style={{ marginTop: "1vh" }}>
                <Typography className="accLabel">Description</Typography>
                <Typography className="description">
                  {milestone?.description}
                </Typography>
              </Stack>
            </div>
          </div>
        </AccordionDetails>
        {children}
      </Accordion>
    </Stack>
  );
};

export default SingleMilestoneAccordion;
