import { Grid, styled, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/reducers/auth/actions";
import About from "../../components/About";
import EditProfile from "../EditProfile";
import Billing from "../Billing";
import ChangePassword from "../ChangePassword";
import PhoneVerificationModal from "../../components/PhoneVerificationModal";
import TwoFectorModal from "../../components/TwoFectorModal";
import NotificationSettings from "../NotificationSettings";
import Security from "../../components/Security";
import useStyles from "./styles";

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  color: selected ? "#fff !important" : "#949DAA",
  backgroundColor: selected ? "#0d0d0d" : "#fff",
  border: "1px solid #d7d8d9",
  borderRadius: "10px",
  minHeight: "40px !important",
  fontWeight: 500,
  textTransform: "none",
  marginRight: "10px",
  padding: "4px 16px !important",
  fontFamily: "Inter Tight",
  "&:hover": {
    backgroundColor: selected ? "#0d0d0d" : "",
    color: !selected && "#000",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent !important",
  },
}));

export default function AccountSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setAccountTab } = authActions;
  const { userData, accountTab } = useSelector((state) => state.auth);
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [show2FModal, setShow2FModal] = useState(false);

  useEffect(() => {
    setTabValue(accountTab);
  }, [accountTab]);

  return (
    <>
      <Grid container xs={12} sm={9} md={10} lg={10}>
        <Grid item xs={12}>
          <Typography className={classes.header}>Account Settings</Typography>
        </Grid>

        <Grid
          item
          container
          borderRadius={"8px"}
          border="1px solid #F2F4F7"
          padding={"20px"}
        >
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setTabValue(b);
                dispatch(setAccountTab(b));
              }}
              variant="scrollable"
              sx={{
                "& .MuiTabs-indicator": {
                  background: "none",
                },
              }}
            >
              <StyledTab label="Profile" selected={tabValue === 0}/>
              <StyledTab label="Billing" selected={tabValue === 1}/>
              <StyledTab label="Notifications" selected={tabValue === 2}/>
              {userData?.social_connection === "google" ||
              userData?.social_connection === "fb" ||
              userData?.social_connection === "apple" ? null : (
                <StyledTab label="Change password" selected={tabValue === 3}/>
              )}
              <StyledTab label="Security" selected={tabValue === 4}/>
            </Tabs>
          </Grid>
          {tabValue === 0 ? <EditProfile /> : null}
          {tabValue === 1 ? <Billing /> : null}
          {tabValue === 2 ? <NotificationSettings /> : null}
          {tabValue === 3 ? (
            userData?.social_connection === "google" ||
            userData?.social_connection === "fb" ||
            userData?.social_connection === "apple" ? (
              <Security />
            ) : (
              <ChangePassword />
            )
          ) : null}
          {tabValue === 4 ? <Security /> : null}
        </Grid>
      </Grid>

      {/* <About /> */}
      <PhoneVerificationModal
        visible={showModal}
        handleClose={() => setShowModal(false)}
      />
      <TwoFectorModal
        visible={show2FModal}
        handleClose={() => setShow2FModal(false)}
      />
    </>
  );
}
