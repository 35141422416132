import {
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../config/theme";
import { useTheme } from "@emotion/react";
import Images from "../../../config/images";
import _, { isArray, isEmpty, isNull, isObject } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Setting } from "../../../utils/Setting";
import { getApiData } from "../../../utils/APIHelper";
import authActions from "../../../redux/reducers/auth/actions";
import moment from "moment";
import { formattedAmount } from "../../../utils/CommonFunction";

export default function Budget(props) {
  const { handleClick = () => null, villa, createProposal } = props;
  const classes = useStyles();
  const [deleteIND, setDeleteIND] = useState(null);
  const { setProposalDetails } = authActions;
  const percentageReleased = (1000 / 1500) * 100;
  const percentageRemaining = 100 - percentageReleased;

  const [budgetDetails, setBudgetDetails] = useState([
    {
      updatedAt: "2023-06-12T03:17:51.483Z",
      id: 528,
      name: "Elaine Kinney",
      material_type: "Mechelle Pugh",
      material_unit: "tonns",
      material_unit_price: 380,
      qty: 22,
      milestone_id: 471,
      manpower_rate: 535,
      days: 22,
      specification: "Qui voluptas fugiat ",
      total_item: 20130,
      buget_image: [
        {
          id: 1048,

          image: Images.building,
          type: "image/jpeg",
        },
        {
          id: 1049,
          image: Images.building,
          type: "image/jpeg",
        },
      ],
    },
    {
      updatedAt: "2023-06-12T03:17:51.483Z",
      id: 528,
      name: "Elaine Kinney",
      material_type: "Mechelle Pugh",
      material_unit: "tonns",
      material_unit_price: 380,
      qty: 22,
      milestone_id: 471,
      manpower_rate: 535,
      days: 22,
      specification: "Qui voluptas fugiat ",
      total_item: 20130,
      buget_image: [
        {
          id: 1048,
          image: Images.building,
          type: "image/jpeg",
        },
        {
          id: 1049,
          image: Images.building,
          type: "image/jpeg",
        },
      ],
    },
    {
      updatedAt: "2023-06-12T03:17:51.483Z",
      id: 528,
      name: "Elaine Kinney",
      material_type: "Mechelle Pugh",
      material_unit: "tonns",
      material_unit_price: 380,
      qty: 22,
      milestone_id: 471,
      manpower_rate: 535,
      days: 22,
      specification: "Qui voluptas fugiat ",
      total_item: 20130,
      buget_image: [
        {
          id: 1048,
          image: Images.building,
          type: "image/jpeg",
        },
        {
          id: 1049,
          image: Images.building,
          type: "image/jpeg",
        },
      ],
    },
  ]);
  const [budgetLoader, setBudgetLoader] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    padding: "25px 25px 0px 25px",
  };

  const [amounts, setAmounts] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getBudgetList();
    getMilestoneList();
  }, []);

  useEffect(() => {
    const array = [];

    budgetDetails.map((bud) => {
      let count =
        parseInt(bud.material_unit_price || 0) * parseInt(bud.qty || 0) +
        parseInt(bud.manpower_rate || 0) * parseInt(bud.days || 0);
      array.push(count);
    });
    setAmounts(array);
  }, [budgetDetails]);

  async function getBudgetList() {
    setBudgetLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.budgetList}/${villa?.proposal_id}`,
        "GET",
        {}
      );
      if (response.success) {
        if (
          isArray(response?.data?.budget) &&
          !isEmpty(response?.data?.budget)
        ) {
          setBudgetDetails(response?.data?.budget);
        } else {
          setBudgetDetails([]);
        }
      }
      setBudgetLoader(false);
    } catch (error) {
      setBudgetLoader(false);
      console.log("err===>", error);
    }
  }

  const handleChange = (e, i) => {
    let dummyarr = [...budgetDetails];
    dummyarr[i].expanded = !dummyarr[i].expanded;
    setBudgetDetails(dummyarr);
  };

  async function getMilestoneList() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.milestoneProposalList}/${villa?.proposal_id}`,
        "GET",
        {}
      );
      if (response.success) {
        setMilestones(response?.data);
      }
    } catch (error) {
      console.log("err===>", error);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item container justifyContent={"space-between"} pt={2}>
          <Grid
            item
            container
            alignItems="center"
            justifyContent={"flex-end"}
            margin={0}
            xl={5.8}
            md={5.5}
            sm={12}
            pb={1}
          >
            <Grid item xs={12} textAlign={"start"}>
              <Typography
                className={classes.acctext}
                style={{
                  color: color.captionText,
                }}
              >
                Project Name:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.accRightText}>
                {villa?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent={"flex-end"}
            margin={0}
            xl={5.8}
            md={5.5}
            sm={12}
            pb={1}
          >
            <Grid item xs={12}>
              <Typography
                className={classes.acctext}
                style={{
                  color: color.captionText,
                }}
              >
                Serial ID:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.accRightText}
                style={{ color: color.primary }}
              >
                {villa?.project_slug}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent={"space-between"}
          padding="12px 0px"
        >
          <Typography fontSize="20px" fontFamily="Inter Tight">
            Total Budget Amount
          </Typography>
          <Typography fontSize="20px" fontFamily="Inter Tight">
            {formattedAmount(
              (isArray(budgetDetails) &&
                !isEmpty(budgetDetails) &&
                budgetDetails?.reduce((acc, bud) => {
                  const amount =
                    parseInt(bud?.material_unit_price || 0) *
                      parseInt(bud?.qty || 0) +
                    parseInt(bud?.manpower_rate || 0) *
                      parseInt(bud?.days || 0);
                  return acc + amount;
                }, 0)) ||
                0
            )}
          </Typography>
        </Grid>

        {budgetLoader ? (
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={12}
            minHeight={220}
          >
            <CircularProgress style={{ color: "#274BF1" }} size={26} />
          </Grid>
        ) : (
          isArray(budgetDetails) &&
          !isEmpty(budgetDetails) &&
          budgetDetails?.map((item, index) => {
            const milestoneValue = item?.milestone_id
              ? milestones?.find((e, i) => {
                  return e?.id === item?.milestone_id;
                })
              : item?.milestone;

            return (
              <Grid container className={classes.card}>
                <Grid item container wrap={sm ? "wrap" : "nowrap"} gap="12px">
                  <Grid item sx={12} rowGap="16px">
                    <Stack>
                      {item?.images ? (
                        <img
                          style={{
                            width: "128px",
                            height: "128px",
                            borderRadius: "4px",
                          }}
                          src={item?.images[0]?.image}
                          alt="budget"
                        />
                      ) : (
                        <div
                          style={{
                            width: "128px",
                            height: "128px",
                            borderRadius: "4px",
                          }}
                        >
                          <img
                            width="100%"
                            height="100%"
                            src="https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
                            alt="placeholder"
                          ></img>
                        </div>
                      )}
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    direction="column"
                    display="flex"
                    style={{ gap: "20px" }}
                  >
                    <Stack gap="12px">
                      <Stack>
                        <span className="budgetName">{item.name}</span>
                      </Stack>
                      <Stack>
                        <span className="disc">{item.specification}</span>
                      </Stack>
                    </Stack>

                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                      paddingRight={2}
                    >
                      <Grid
                        item
                        lg={7}
                        sm={12}
                        md={7}
                        xs={12}
                        display="flex"
                        flexDirection="column"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        <div component="span" className="accLabel">
                          Payment Date
                        </div>
                        <div component="span" className="accLabelValue">
                          {moment(item?.updatedAt).format("MMM DD, YYYY")}
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={5}
                        sm={12}
                        md={5}
                        xs={12}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <div component="span" className="accLabel">
                          Amount
                        </div>
                        <Typography
                          fontFamily="Inter Tight"
                          className="accLabelValue"
                        >
                          {formattedAmount(amounts[index] || 0)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container>
                  <ListItemButton
                    style={{
                      color: color.primary,
                      padding: sm && "10px 0px",
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      handleChange(item, index);
                    }}
                  >
                    <div
                      style={{
                        flexGrow: 1,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item?.expanded ? "Hide Details" : "View Details"}
                    </div>
                    {item?.expanded ? (
                      <ExpandLessIcon sx={{ ml: 1 }} />
                    ) : (
                      <ExpandMoreIcon sx={{ ml: 1 }} />
                    )}
                  </ListItemButton>
                </Grid>

                <Collapse
                  in={item?.expanded}
                  timeout="auto"
                  unmountOnExit
                  style={{ width: "100%" }}
                >
                  <Grid item padding={"10px 10px 0px 10px"}>
                    <div
                      style={{
                        width: "100%",
                        paddingTop: 14,
                        paddingBottom: 4,
                      }}
                    >
                      <Divider />
                    </div>
                  </Grid>

                  <div
                    className="responsive-table"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack gap="28px" width="25%">
                      <Stack
                        style={{
                          fontFamily: "Inter Tight",
                          fontSize: "18px",
                          whiteSpace: "nowrap",
                          padding: "10px",
                        }}
                      >
                        Budget Details
                      </Stack>
                    </Stack>
                    <TableContainer
                      style={{ padding: "16px", boxSizing: "border-box" }}
                    >
                      <Table className={classes.customtable}>
                        <Typography
                          fontFamily={"Inter Tight"}
                          fontSize={18}
                        >
                          Manpower
                        </Typography>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Milestone
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Manpower rate
                            </TableCell>

                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Days
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                visibility: "hidden",
                                textAlign: "left",
                              }}
                            >
                              Days
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "right",
                                textAlign: "right",
                              }}
                            >
                              Amount
                            </TableCell>
                          </TableRow>
                          <TableRow key={"Manpower"}>
                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.milestone_name || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.manpower_rate || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.days || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell
                              style={{
                                visibility: "hidden",
                                textAlign: "left",
                              }}
                            >
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.days || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {formattedAmount(
                                  parseInt(item.manpower_rate || 0) *
                                    parseInt(item.days || 0)
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <div
                        style={{ width: "100%", padding: "10px 0px 14px 0px" }}
                      >
                        <Divider />
                      </div>
                      <Table className={classes.customtable}>
                        <Typography
                          fontFamily={"Inter Tight"}
                          fontSize={18}
                        >
                          Material
                        </Typography>
                        <TableBody>
                          <TableRow>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Material Type
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Material Unit
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Unit Price
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "left",
                              }}
                            >
                              Quantity
                            </TableCell>
                            <TableCell
                              style={{
                                color: color.captionText,
                                fontFamily: "Inter Tight !important",
                                textAlign: "right",
                              }}
                            >
                              Amount
                            </TableCell>
                          </TableRow>
                          <TableRow key={"Manpower"}>
                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.material_type || "-"}
                              </Typography>
                            </TableCell>

                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.material_unit || "-"}
                              </Typography>
                            </TableCell>

                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {formattedAmount(
                                  item?.material_unit_price || 0
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {item?.qty || "-"}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <Typography fontFamily={"Inter Tight"}>
                                {formattedAmount(
                                  parseInt(item.material_unit_price || 0) *
                                    parseInt(item.qty || 0)
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Collapse>
              </Grid>
            );
          })
        )}
      </Grid>
    </>
  );
}
