import React, { useState, useEffect, useCallback } from "react";
import "./index.css";
import { getApiData } from "../../utils/APIHelper";
import { useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Pagination,
  Typography,
  CircularProgress,
  Stack,
  TextField,
  IconButton,
  styled,
} from "@mui/material";
import moment from "moment";
import { debounce } from "lodash";
import CInput from "../../components/CInput";
import ClearIcon from "@mui/icons-material/Clear";
import { format } from "date-fns";
import { Box, Paper } from "@mui/material";
import PaymentDetails from "./components/PaymentDetails";
import { ReactComponent as LineIcon } from "../../assets/svg/Line.svg";

function formatDisplayDate(date) {
  return date ? format(new Date(date), "MMM dd, yyyy") : "";
}

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  color: selected ? "#fff !important" : "#949DAA",
  backgroundColor: selected ? "#0d0d0d" : "#fff",
  border: "1px solid #d7d8d9",
  borderRadius: "10px",
  minHeight: "40px !important",
  fontWeight: 500,
  textTransform: "none",
  marginRight: "10px",
  padding: "4px 16px !important",
  fontFamily: "Inter Tight",
  "&:hover": {
    backgroundColor: selected ? "#0d0d0d" : "",
    color: !selected && "#000",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent !important",
  },
}));

export default function Payments() {
  const { userData } = useSelector((state) => state.auth);

  const today = moment().format("YYYY-MM-DD");
  const upcomingEndDate = moment().add(7, "days").format("YYYY-MM-DD");
  const currentMonthStartDate = moment().startOf("month").format("YYYY-MM-DD");
  const currentMonthEndDate = moment().endOf("month").format("YYYY-MM-DD");

  const [projectDetails, setProjectDetails] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("dueDate");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [startDate, setStartDate] = useState(currentMonthStartDate);
  const [endDate, setEndDate] = useState(currentMonthEndDate);
  const [selectedTab, setSelectedTab] = useState(0); // 0: Overdue, 1: Upcoming, 2: All
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [startDateType, setStartDateType] = useState("text");
  const [endDateType, setEndDateType] = useState("text");
  const [summary, setSummary] = useState({});

  const handleDateChange = (dateSetter, dateTypeSetter) => (event) => {
    const rawDate = event.target.value;
    dateSetter(rawDate ? new Date(rawDate).toISOString().split("T")[0] : ""); // Convert to ISO format for backend if needed
    dateTypeSetter("text"); // Revert the input type immediately after selection
  };

  const handleFocus = (dateTypeSetter) => {
    dateTypeSetter("date"); // Set type to "date" on focus
  };

  const handleBlur = (dateTypeSetter) => {
    dateTypeSetter("text"); // Reset to "text" after user interaction
  };

  // Debounced search handler
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    if (value.length >= 4 || value.length === 0) {
      debounceSearch(value);
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      setDebouncedSearch(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (userData?.contractor_data?.id) {
      fetchData(page, sortBy, sortOrder, startDate, endDate, debouncedSearch);
    }
  }, [
    userData?.contractor_data?.id,
    page,
    sortBy,
    sortOrder,
    startDate,
    endDate,
    selectedTab,
    debouncedSearch,
  ]);

  const fetchData = async (
    currentPage,
    currentSortBy,
    currentSortOrder,
    start,
    end,
    searchQuery
  ) => {
    setLoading(true);
    try {
      let filters = `contractorId:=:${userData.contractor_data.id}`;
      if (selectedTab !== 2) {
        filters += ":AND";
      }
      if (selectedTab === 0) {
        filters += `&filters=status:notLike:PAID:AND&filters=dueDate:<=:${today}`;
      } else if (selectedTab === 1) {
        filters += `&filters=status:notLike:PAID:AND&filters=dueDate:>:${today}:AND&filters=dueDate:<:${upcomingEndDate}`;
      } else if (selectedTab === 2) {
        if (start) filters += `:AND&filters=dueDate:%3E=:${start}`;
        if (end) filters += `:AND&filters=dueDate:%3C=:${end}`;
        if (searchQuery)
          filters += `:AND&filters=projectName:like:%${searchQuery}%`;
      }

      const response = await getApiData(
        `api/v2/dashboard/paymentOverview?object=payouts&page=${currentPage}&size=10&filters=${filters}&sortBy=${currentSortBy}&sortOrder=${currentSortOrder}`,
        "GET",
        {},
        {},
        true
      );
      setProjectDetails(response?.content || []);
      setTotalPages(response?.totalPages || 0);
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.contractor_data?.id) {
      fetchSummary(sortBy, sortOrder, startDate, endDate, debouncedSearch);
    }
  }, [
    userData?.contractor_data?.id,
    selectedTab,
    startDate,
    endDate,
    debouncedSearch,
  ]);

  const fetchSummary = async (
    currentSortBy,
    currentSortOrder,
    start,
    end,
    searchQuery
  ) => {
    setLoading(true);
    try {
      let filters = `contractorId:=:${userData.contractor_data.id}`;
      if (selectedTab === 1) {
        filters += `:AND&filters=status:notLike:PAID:AND&filters=dueDate:>:${today}:AND&filters=dueDate:<:${upcomingEndDate}`;
      } else if (selectedTab === 2) {
        // filters += `:AND&filters=dueDate:%3E=:${currentMonthStartDate}:AND&filters=dueDate:%3C=:${currentMonthEndDate}`
        if (start) filters += `:AND&filters=dueDate:%3E=:${start}`;
        if (end) filters += `:AND&filters=dueDate:%3C=:${end}`;
        if (searchQuery)
          filters += `:AND&filters=projectName:like:%${searchQuery}%`;
      }

      const response = await getApiData(
        `api/v2/dashboard/paymentOverview/summary?object=payouts&filters=${filters}&sortBy=${currentSortBy}&sortOrder=${currentSortOrder}`,
        "GET",
        {},
        {},
        true
      );
      setSummary(response || {});
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const handleSort = (column) => {
    const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortOrder(newSortOrder);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
  };

  const stats = [
    { label: "Total Projects", value: summary.totalProjects },
    { label: "Total Paid Amount", value: summary.totalPaidAmount },
    { label: "Total Unpaid Amount", value: summary.totalUnPaidAmount },
  ];

  return (
    <Stack
      flex={1}
      sx={{
        fontFamily: "Inter Tight",
      }}
    >
      <div style={{ background: "#fff", padding: "4.5vh 2vw" }}>
        <StyledHeading>Payments</StyledHeading>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1vh",
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                background: "none",
              },
            }}
          >
            <StyledTab label="Overdue Payments" selected={selectedTab === 0} />
            <StyledTab label="Upcoming Payments" selected={selectedTab === 1} />
            <StyledTab label="All Payments" selected={selectedTab === 2} />
          </Tabs>

          {selectedTab === 2 && (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              style={{ width: "45%" }}
            >
              <CInput
                placeholder="Project Name"
                value={search}
                onChange={handleSearchChange}
                inputProps={{ maxLength: 50 }}
                style={{ width: "50%" }}
                isWhiteBackground
              />
              <TextField
                value={formatDisplayDate(startDate)}
                onChange={handleDateChange(setStartDate, setStartDateType)}
                onFocus={() => handleFocus(setStartDateType)}
                onBlur={() => handleBlur(setStartDateType)}
                InputLabelProps={{ shrink: true }}
                placeholder="Start Date"
                fullWidth
                type={startDateType}
                InputProps={{
                  endAdornment: startDate && (
                    <IconButton
                      onClick={() => setStartDate("")}
                      className="iconButton"
                    >
                      <ClearIcon className="iconSvg" />
                    </IconButton>
                  ),
                }}
              />
              <TextField
                value={formatDisplayDate(endDate)}
                onChange={handleDateChange(setEndDate, setEndDateType)}
                onFocus={() => handleFocus(setEndDateType)}
                onBlur={() => handleBlur(setEndDateType)}
                InputLabelProps={{ shrink: true }}
                placeholder="End Date"
                fullWidth
                type={endDateType}
                InputProps={{
                  endAdornment: endDate && (
                    <IconButton
                      onClick={() => setEndDate("")}
                      className="iconButton"
                    >
                      <ClearIcon className="iconSvg" />
                    </IconButton>
                  ),
                }}
              />
            </Stack>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {stats?.map((stat, index) => (
            <div style={{ width: "100%" }} key={index}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  boxShadow: "none",
                  borderRadius: 0,
                  borderBottom: "1px solid #D7D8D9",
                  paddingBottom: 0,
                  alignItems: "flex-end",
                }}
              >
                {index !== 0 ? <LineIcon /> : null}
                <div style={{ padding: index === 0 ? "1vh .5vw" : "1vh 4vw" }}>
                  <Typography
                    sx={{
                      mb: 1,
                      letterSpacing: "0",
                      fontSize: "16px",
                      color: "#949DAA",
                    }}
                  >
                    {stat?.label}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "24px",
                      color: "#0D0D0E",
                      fontWeight: "600",
                    }}
                  >
                    {stat.value ? stat?.value?.toLocaleString() : "---"}
                  </Typography>
                </div>
              </Paper>
            </div>
          ))}
        </div>

        <Box sx={{ paddingTop: 2 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : projectDetails?.length > 0 ? (
            <>
              <PaymentDetails
                details={projectDetails}
                handleSort={handleSort}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
              <center
                style={{
                  alignSelf: "center",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page + 1}
                  onChange={handlePageChange}
                  color="primary"
                  style={{
                    alignSelf: "center",
                    marginTop: "1vh",
                  }}
                />
              </center>
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", marginTop: 4 }}
              >
                No data available
              </Typography>
              <center
                style={{
                  alignSelf: "center",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Pagination
                  count={totalPages}
                  page={page + 1}
                  onChange={handlePageChange}
                  color="primary"
                  style={{
                    alignSelf: "center",
                    marginTop: "1vh",
                  }}
                />
              </center>
            </>
          )}
        </Box>
      </div>
    </Stack>
  );
}
