import { useEffect, useMemo, useState } from "react";
import authActions from "../../../../redux/reducers/auth/actions";
import { AddCircleOutline, Close } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControl,
  Modal,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { v4 as uuidv4 } from "uuid";
import MilestonePaymentGroup from "./MilestonePaymentGroup";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import theme from "../../../../config/theme";
import SingleMilestoneAccordion from "../../../../components/SingleMilestoneAccordian";
import CInput from "../../../../components/CInput";
import Images from "../../../../config/images";
import { useLocation } from "react-router-dom";
import { getAPIProgressData, getNewApiData } from "../../../../utils/APIHelper";
import { Setting } from "../../../../utils/Setting";
import ConfirmModel from "../../../../components/ConfirmModel";
import { transformDateFormat } from "../../../../utils/CommonFunction";
import moment from "moment";

const EditPaymentGroup = ({
  handleTabValue,
  handleDisableTab,
  setTabValue,
}) => {
  const errorObj = {
    groupNameError: false,
    groupNameMsg: "",
    dueDateError: false,
    dueDateMsg: "",
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [villa] = useState(location?.state?.villa);
  const { proposalDetails, reload } = useSelector((state) => state.auth);
  const { setProposalDetails, setReload } = authActions;
  const [milestones, setMilestones] = useState({});
  const [errObj, setErrObj] = useState(errorObj);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [groupName, setGroupName] = useState("");
  const [paymentGroups, setPaymentGroups] = useState({});
  const [selectedPaymentGroup, setSelectedPaymentGroup] = useState({});
  const [selectedMilestones, setSelectedMilestones] = useState({});
  const [buttonLoader, setButtonLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 800,
    maxHeight: sm ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    padding: "24px",
    overflow: "auto",
  };

  useEffect(() => {
    if (proposalDetails?.payment_group) {
      setPaymentGroups(proposalDetails.payment_group);
    }
  }, [proposalDetails?.payment_group]);

  useEffect(() => {
    setMilestones(proposalDetails.milestones);
  }, [proposalDetails.milestones]);

  const endDate = useMemo(() => {
    const endDateArray = Object.keys(selectedMilestones).map((key) => {
      const milestone = selectedMilestones[key];
      return +new Date(milestone.end_date);
    });

    const maxEndDate = Math.max(...endDateArray);
    const endDate = transformDateFormat(new Date(maxEndDate));
    return endDate;
  }, [selectedMilestones]);

  const handleValidation = (mode) => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(groupName)) {
      valid = false;
      error.groupNameError = true;
      error.groupNameMsg = "Please enter the name";
    }

    setErrObj(error);

    if (Object.keys(selectedMilestones).length < 1) {
      return toast.error("Please select at least one milestone");
    }

    if (valid) {
      if (mode === "create") {
        handleCreate();
      }
      if (mode === "update") {
        handleUpdate();
      }
    }
  };

  const clearData = () => {
    setGroupName("");
    setErrObj({});
  };

  const handleClose = () => {
    // for (let key in milestones) {
    //   if (selectedMilestones.hasOwnProperty(key)) {
    //     setMilestones((prev) => ({
    //       ...prev,
    //       [key]: { ...prev[key], in_payment_group: false },
    //     }));
    //   }
    // }
    setSelectedMilestones({});
    setSelectedPaymentGroup({});
    // !isOpen && handleUpdate();
    setIsOpen(false);
    setIsEditModalOpen(false);
    clearData();
  };

  const handleMilestones = (event, milestone, milestoneId) => {
    const isSelected = selectedMilestones.hasOwnProperty(milestoneId);
    if (isSelected) {
      const newMilestones = {};
      for (let key in selectedMilestones) {
        if (key !== milestoneId) {
          newMilestones[key] = selectedMilestones[key];
        }
      }
      setSelectedMilestones((prev) => newMilestones);
      setMilestones((prev) => ({
        ...prev,
        [milestoneId]: {
          ...prev[milestoneId],
          in_payment_group: false,
          group_id: "",
        },
      }));
    } else {
      setSelectedMilestones((prev) => ({ ...prev, [milestoneId]: milestone }));
      setMilestones((prev) => ({
        ...prev,
        [milestoneId]: { ...prev[milestoneId], in_payment_group: true },
      }));
    }
  };

  async function createPaymentGroup(data) {
    try {
      const response = await getNewApiData(
        `${Setting.endpoints.newPaymentGroup}`,
        "POST",
        data,
        { "Content-Type": "application/json" },
        true
      );
      toast.success("Payment Group was added successfully");
      setSelectedMilestones({});
      setIsOpen(false);
      clearData();
      dispatch(setReload(!reload));
    } catch (error) {
      toast.error(error.toString());
    }
  }

  const handleCreate = () => {
    const groupId = uuidv4();
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

    let body = {
      paymentGroup: {
        groupId: groupId,
        groupName: groupName,
        refId: parseInt(villa?.proposal_id),
        refType: "proposal",
        type: "PAYMENT_GROUP",
        dueDate: formattedEndDate,
      },
      milestones: [],
    };
    // Extract and format milestones
    body.milestones = Object.keys(selectedMilestones).map((milestoneId) => ({
      milestoneId: parseInt(milestoneId),
    }));

    // Now `body` contains the paymentGroup with the milestones array
    createPaymentGroup(body);
    return;
    setPaymentGroups((prev) => ({
      ...prev,
      [groupId]: {
        groupName,
        group: selectedMilestones,
        dueDate: endDate,
      },
    }));

    const newMilestones = {};
    for (let key in milestones) {
      if (selectedMilestones.hasOwnProperty(key)) {
        const updatedMilestones = {
          ...milestones[key],
          group_id: groupId,
        };
        newMilestones[key] = updatedMilestones;
      } else {
        newMilestones[key] = milestones[key];
      }
    }
    setMilestones(newMilestones);
    setSelectedMilestones({});
    setIsOpen(false);
    clearData();
  };

  const handleUpdate = () => {
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    let body = {
      paymentGroup: {
        refId: parseInt(villa?.proposal_id),
        groupId: selectedPaymentGroup.id,
        groupName: groupName,
        dueDate: formattedEndDate,
      },
      milestones: [],
    };
    // Extract and format milestones
    body.milestones = Object.keys(selectedMilestones).map((milestoneId) => ({
      milestoneId: parseInt(milestoneId),
    }));
    updatePaymentGroupNew(body);
    return;
    setPaymentGroups((prev) => ({
      ...prev,
      [selectedPaymentGroup.id]: {
        groupName,
        group: selectedMilestones,
        dueDate: endDate,
      },
    }));

    const newMilestones = {};
    for (let key in milestones) {
      if (selectedMilestones.hasOwnProperty(key)) {
        const updatedMilestones = {
          ...milestones[key],
          group_id: selectedPaymentGroup.id,
        };
        newMilestones[key] = updatedMilestones;
      } else {
        newMilestones[key] = milestones[key];
      }
    }
    setMilestones(newMilestones);
    setSelectedMilestones({});
    setSelectedPaymentGroup({});
    setIsEditModalOpen(false);
    clearData();
  };
 
  const renderSelectedMilestones = () => {
    return Object.keys(selectedMilestones).map((key) => (
      <SingleMilestoneAccordion
        milestone={selectedMilestones[key]}
        id={key}
        key={key}
        checked
        handleCheckbox={handleMilestones}
        isEditScreen={true}
      />
    ));
  };

  const renderUnselectedMilestones = () => {
    return Object.keys(milestones).map((key) => {
      if (
        !selectedMilestones.hasOwnProperty(key) &&
        !milestones[key].in_payment_group
      ) {
        const milestone = milestones[key];
        return (
          <SingleMilestoneAccordion
            milestone={milestone}
            id={key}
            handleCheckbox={(event) => handleMilestones(event, milestone, key)}
            isEditScreen={true}
          />
        );
      }
    });
  };

  const convertBase64ToImageFile = (base64String, filename) => {
    const arr = base64String?.split(",");
    const mimeType = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);

    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    const file = new File([uint8Array], filename, { type: mimeType });

    return file;
  };

  const convertProjectToFiles = () => {
    const projectFiles = proposalDetails?.project?.map(
      (base64String, index) => {
        const filename = `project_image_${index + 1}.jpg`;
        return convertBase64ToImageFile(base64String, filename);
      }
    );

    return projectFiles;
  };

  const handleEditPaymentGroup = (e, paymentGroup, id) => {
    e.stopPropagation();
    setIsEditModalOpen(true);
    setSelectedPaymentGroup({ paymentGroup, id });
    setGroupName(paymentGroup.groupName);
    setSelectedMilestones(paymentGroup.group);
  };

  const handleDelete = (e, paymentGroup, id) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
    setSelectedPaymentGroup({ paymentGroup, id });
  };

  const handleDeletePaymentGroup = () => {
    deletePaymentGroup();
    return;
    const updatedMilestones = {};

    for (let key in milestones) {
      const milestone = milestones[key];

      if (milestone.group_id === selectedPaymentGroup.id) {
        updatedMilestones[key] = {
          ...milestone,
          in_payment_group: false,
          group_id: undefined,
        };
      } else {
        updatedMilestones[key] = milestone;
      }
    }

    const newPaymentGroups = {};
    for (let key in paymentGroups) {
      if (key !== selectedPaymentGroup.id) {
        newPaymentGroups[key] = paymentGroups[key];
      }
    }

    setMilestones(updatedMilestones);
    setPaymentGroups(newPaymentGroups);
    setIsDeleteModalOpen(false);
    setSelectedMilestones({});
    setSelectedPaymentGroup({});
  };
  async function updatePaymentGroupNew(data) {
    try {
      const response = await getNewApiData(
        `${Setting.endpoints.newPaymentGroup}`,
        "PUT",
        data,
        { "Content-Type": "application/json" },
        true
      );

      if (response) {
        setSelectedMilestones({});
        setSelectedPaymentGroup({});
        setIsEditModalOpen(false);
        clearData();
        dispatch(setReload(!reload));
        toast.success("Payment Group Updated Successfully");
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }

  async function deletePaymentGroup() {
    try {
      const response = await getNewApiData(
        `${Setting.endpoints.newPaymentGroup}/${selectedPaymentGroup.id}`,
        "DELETE",
        {},
        { "Content-Type": "application/json" },
        true
      );
      // if (response) {
      setIsDeleteModalOpen(false);
      setSelectedMilestones({});
      setSelectedPaymentGroup({});
      dispatch(setReload(!reload));
      toast.success("Payment Group Deleted Successfully");
      // }
    } catch (error) {
      toast.error(error.toString());
    }
  }

  const handleSave = () => {
    const valid = Object.values(milestones).every(
      (milestone) => milestone?.in_payment_group
    );
    if (!valid) {
      toast.error("All milestones should have a group");
    } else {
      setTabValue(4);
      // dispatch(
      //   setProposalDetails({
      //     ...proposalDetails,
      //     milestones,
      //     payment_group: paymentGroups,
      //   })
      // );
      // updatedPaymentGroups();
    }
  };

  async function updateproposalApicall(data) {
    setButtonLoader(true);
    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.updateProposal}/${villa?.proposal_id}`,
        "POST",
        data,
        true
      );
      if (response.success) {
        toast.success("Payment Groups Updated Successfully");
        setTabValue(4);
      } else {
        toast.error(response.message);
      }
      setButtonLoader("");
    } catch (error) {
      toast.error(error.toString());
      setButtonLoader("");
    }
  }

  const updatedPaymentGroups = () => {
    const projectFiles = convertProjectToFiles();
    const transformedData = {
      email: proposalDetails?.email,
      name: proposalDetails?.name,
      username: proposalDetails?.customer_name,
      project_type: proposalDetails?.project_type,
      exp_id: proposalDetails?.exp_id,
      description: proposalDetails?.description,
      start_date: proposalDetails?.start_date,
      end_date: proposalDetails?.end_date,
      project_image: projectFiles,
      scope_of_work: proposalDetails?.scope_of_work,
      proposal: JSON.stringify({
        milestone_details: Object.keys(milestones).map((milestoneKey) => {
          const milestone = milestones[milestoneKey];
          return {
            payment_group_id: milestone?.group_id,
            milestone_name: milestone?.milestone_name,
            description: milestone?.description,
            start_date: milestone?.start_date,
            end_date: milestone?.end_date,
            budget_item: Object.keys(proposalDetails.budgets)
              .map((key, i) => {
                const budget = proposalDetails?.budgets[key];
                if (budget.milestone_id == milestoneKey) {
                  return {
                    name: budget?.name,
                    budget_id: i + 1,
                    material_type: budget?.material_type,
                    material_unit: budget?.material_unit || "",
                    material_unit_price: budget?.material_unit_price || "0",
                    qty: budget?.qty || "0",
                    manpower_rate: budget?.manpower_rate || "0",
                    days: budget?.days || "0",
                    specification: budget?.specification,
                  };
                } else {
                  return null;
                }
              })
              .filter((budget) => budget !== null),
          };
        }),
        payment_group_details: Object.keys(paymentGroups)
          .map((key) => {
            const payment_group = paymentGroups[key];
            if (!isEmpty(payment_group?.group)) {
              return {
                group_id: key,
                group_name: payment_group?.groupName,
              };
            }
            return null;
          })
          .filter((payment_group) => payment_group !== null),
      }),
    };
    updateproposalApicall(transformedData);
  };

  const isAnyMilestonesAvailable = useMemo(() => {
    return Object.values(milestones).some(
      (milestone) => milestone?.in_payment_group === false
    );
  }, [milestones]);

  function renderUpsertPaymentGroupForm(mode) {
    return (
      <Modal
        open={isOpen || isEditModalOpen}
        closeAfterTransition
        disableAutoFocus
        slotProps={{ backdrop: Backdrop }}
      >
        <Fade in={isOpen || isEditModalOpen}>
          <Box sx={style}>
            <Stack gap="28px" height="100%">
              <Close
                sx={{
                  width: "35px",
                  height: "35px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
              <Stack direction="row" justifyContent="space-between">
                {mode === "create" ? (
                  <Typography className={classes.groupTitle}>
                    Create Payment Group
                  </Typography>
                ) : (
                  <Typography className={classes.groupTitle}>
                    Update Payment Group
                  </Typography>
                )}
              </Stack>
              <Divider />
              <Stack flex={1} gap="28px">
                <Stack direction="row" gap="28px">
                  <CInput
                    multiline={true}
                    rows={1}
                    label="Name:"
                    placeholder="Write here..."
                    value={groupName}
                    className={classes.groupTitle}
                    onChange={(e) => {
                      setGroupName(e.target.value);
                      setErrObj({
                        ...errObj,
                        groupNameError: false,
                        groupNameMsg: "",
                      });
                    }}
                    error={errObj.groupNameError}
                    helpertext={errObj.groupNameMsg}
                  />
                  <FormControl
                    variant="standard"
                    fullWidth
                    style={{ position: "relative" }}
                    error={errorObj.dueDateError}
                  >
                    <span className={classes.formLabel} htmlFor="end-date">
                      Due Date:
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={new Date(endDate)}
                        disabled
                        sx={{
                          width: "100%",
                          marginTop: "6px",
                        }}
                        components={{
                          OpenPickerIcon: () => (
                            <img
                              src={Images.calendarIcon}
                              alt="calender-icon"
                            ></img>
                          ),
                        }}
                        format="MMMM dd, yyyy"
                        slotProps={{
                          textField: {
                            helperText: errObj.dueDateMsg,
                            error: errObj.dueDateError,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Stack>
              </Stack>
              {renderSelectedMilestones()}
              {renderUnselectedMilestones()}
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {mode === "create" ? (
                  <Button
                    variant="contained"
                    onClick={() => handleValidation(mode)}
                  >
                    Create
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleValidation(mode)}
                  >
                    Update
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    );
  }

  useEffect(() => {
    console.log("paymentGroups", paymentGroups);
  }, [paymentGroups]);

  return (
    <Stack width="100%" gap="28px" flex={1} overflow="hidden">
      <Stack flex={1} gap="16px" overflow="auto">
        {Object.keys(paymentGroups).map((key) => {
          const pGroup = paymentGroups[key];
          if (Object.keys(pGroup.group).length > 0) {
            return (
              <MilestonePaymentGroup
                group={pGroup?.group}
                groupName={pGroup?.groupName}
                dueDate={pGroup?.dueDate}
                amount={pGroup?.amount}
                groupId={key}
                handleEditPaymentGroup={(e) =>
                  handleEditPaymentGroup(e, pGroup, key)
                }
                handleDelete={(e) => handleDelete(e, pGroup, key)}
                isExpandable
              />
            );
          }
        })}
        <Tooltip title={!isAnyMilestonesAvailable ? "No more Milestones" : ""}>
          <span style={{ width: "fit-content" }}>
            <Button
              variant="text"
              onClick={() => setIsOpen(true)}
              disabled={!isAnyMilestonesAvailable}
              color="primary"
              style={{
                alignSelf: "start",
                gap: "8px",
                padding: "8px 12px",
                boxShadow: "none",
              }}
            >
              <AddCircleOutline />
              Create payment group
            </Button>
          </span>
        </Tooltip>
      </Stack>
      <Stack direction="row" justifyContent="end" alignItems="center">
        <Button
          variant="contained"
          sx={{ padding: "12px 24px" }}
          onClick={handleSave}
        >
          {buttonLoader ? (
            <CircularProgress size={26} style={{ color: "#fff" }} />
          ) : (
            "Next Step"
          )}
        </Button>
      </Stack>
      {isOpen && renderUpsertPaymentGroupForm("create")}
      {isEditModalOpen && renderUpsertPaymentGroupForm("update")}
      <ConfirmModel
        visible={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        confirmation={handleDeletePaymentGroup}
        message={`Are you sure to delete the Payment Group ${selectedPaymentGroup?.paymentGroup?.groupName}`}
      />
    </Stack>
  );
};
export default EditPaymentGroup;
