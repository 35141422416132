import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, styled } from "@mui/material";
import { getApiData } from "../../utils/APIHelper";
import ProposalDetails from "./components/ProposalDetails";

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

export default function NewDashboard() {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getApiData(
        `api/project/list-contractor-project?status=ongoing`,
        "GET",
        {},
        {},
        false
      );
      const newDetails = response?.data || [];
      setDetails(newDetails);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          typography: "body1",
          backgroundColor: "#fff",
          height: "100%",
          padding: "4vh 2vw",
          borderRadius: "6px",
          fontFamily: "Inter Tight",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1vh",
          }}
        >
          <StyledHeading>Projects</StyledHeading>
        </div>
        <Box sx={{ paddingTop: 2 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : details?.length > 0 ? (
            <ProposalDetails details={details} />
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", marginTop: 4 }}
            >
              No data available
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
}
