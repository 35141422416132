import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { statusStyleArray } from "../config";
import { formattedAmountWithoutAed } from "../../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import authActions from "../../../redux/reducers/auth/actions";
import { useDispatch } from "react-redux";

export const getStatusDisplayText = (text) => {
  if (typeof text !== "string") return text;
  const displayText = text
    .replace(/-/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return displayText;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
}));

export default function ProposalDetails({ details, selectedTab }) {
  const { resetProposalDetails } = authActions;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (item) => {
    dispatch(resetProposalDetails()); // Common action for all cases
  
    if (selectedTab === 0) {
      navigate("/create-proposal", {
        state: {
          isUpdateDraftProposal: true,
          villa: item,
          isCreateProposal: true,
          isDraft: true,
        },
      });
    } else if (selectedTab === 1) {
      if (item?.form_json == null) {
        navigate("/create-proposal", {
          state: {
            isUpdateDraftProposal: true,
            villa: item,
            isCreateProposal: true,
            isRequested: true,
          },
        });
      } else {
        navigate("/request-proposal", {
          state: { isUpdateDraftProposal: true, villa: item },
        });
      }
    } else {
      navigate("/request-proposal", {
        state: { villa: item, status: "submitted" },
      });
    }
  };
  

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflow: "none !important",
          boxShadow: "none",
          maxHeight: "82vh",
        }}
      >
        <Table
          sx={{
            "& thead": {
              "& th": {
                color: "#949DAA",
                fontWeight: "500",
              },
            },
            "& tbody": {
              "& tr:hover": {
                backgroundColor: "#f7f7f7",
              },
              "& tr": {
                borderBottom: "none",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>S. No.</StyledTableCell>
              <StyledTableCell>Proposal Name</StyledTableCell>
              <StyledTableCell>Project Type</StyledTableCell>
              <StyledTableCell>Proposal Date</StyledTableCell>
              {selectedTab !== 0 && (
                <>
                  <StyledTableCell>Customer</StyledTableCell>
                  <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                    <strong>Cost (AED)</strong>
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((item, idx) => (
              <TableRow key={item.id}>
                <StyledTableCell>{idx + 1}.</StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    {selectedTab === 0 ? (
                      <>
                        {item?.project?.project_image &&
                          item?.project?.project_image[0]?.image && (
                            <Box
                              component="img"
                              src={item?.project?.project_image[0]?.image}
                              alt="Project Icon"
                              sx={{
                                width: 45,
                                height: 45,
                                borderRadius: "8px",
                                border: "2px solid white",
                                boxShadow: "0px 4px 2.67px 0px #00000026",
                                marginRight: 1,
                              }}
                            />
                          )}
                        {item?.project?.name}
                      </>
                    ) : (
                      <>
                        {item.project_image && item.project_image[0].image && (
                          <Box
                            component="img"
                            src={item.project_image[0].image}
                            alt="Project Icon"
                            sx={{
                              width: 45,
                              height: 45,
                              borderRadius: "8px",
                              border: "2px solid white",
                              boxShadow: "0px 4px 2.67px 0px #00000026",
                              marginRight: 1,
                            }}
                          />
                        )}
                        {item?.name}
                      </>
                    )}
                  </Box>
                </StyledTableCell>

                <StyledTableCell>
                  {selectedTab === 0
                    ? item?.project?.project_type
                    : item?.project_type}
                </StyledTableCell>
                <StyledTableCell>
                  {selectedTab === 0
                    ? moment(item?.project?.updatedAt).format("MMM DD, YYYY")
                    : moment(item?.updatedAt).format("MMM DD, YYYY")}
                </StyledTableCell>
                {selectedTab !== 0 && (
                  <>
                    <StyledTableCell>
                      <Box display="flex" alignItems="center">
                        {item?.user_data && item?.user_data?.profile_url && (
                          <Box
                            component="img"
                            src={item?.user_data?.profile_url}
                            alt="Project Icon"
                            sx={{
                              width: 35,
                              height: 35,
                              borderRadius: "50%",
                              border: "2px solid white",
                              boxShadow: "0px 4px 2.67px 0px #00000026",
                              marginRight: 1,
                            }}
                          />
                        )}
                        {item?.user_data?.username}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                      <strong>{`${formattedAmountWithoutAed(
                        item?.cost
                      )}`}</strong>
                    </StyledTableCell>
                  </>
                )}

                <StyledTableCell>
                  <Typography
                    style={{
                      borderRadius: "34px",
                      padding: "4px 8px",
                      fontSize: "14px",
                      minWidth: "7ch",
                      width: "fit-content",
                      backgroundColor:
                        statusStyleArray.find(
                          (style) =>
                            style.status ===
                            (selectedTab === 0
                              ? item?.project?.status
                              : item?.proposal_status)
                        )?.backgroundColor || "transparent",
                      color:
                        statusStyleArray.find(
                          (style) =>
                            style.status ===
                            (selectedTab === 0
                              ? item?.project?.status
                              : item?.proposal_status)
                        )?.color || "black",
                      border: "none",
                      textAlign: "center",
                    }}
                  >
                    {getStatusDisplayText(
                      selectedTab === 0
                        ? item?.project?.status
                        : item?.proposal_status
                    )}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  {item?.status === "proposal" ? (
                    <IconButton
                      size="small"
                      title="View"
                      onClick={() => handleChange(item)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton
                      size="small"
                      title="Edit"
                      onClick={() => handleChange(item)}
                    >
                      <EditIcon fontSize="20" />
                    </IconButton>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
