import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useState, createContext, useContext } from "react";
import Details from "./Details";
import Inclusion from "./Inclusion";
import Summary from "./Summary";
import PackageImages from "./PackageImages";

const PackageContext = createContext();

const CreatePackage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [packageDetails, setPackageDetails] = useState({});

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    bgcolor: "background.paper",
    padding: "20px 32px !important",
    overflow: "hidden",
    margin: "20px 0",
    borderRadius: "10px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04)",
    border: "1px solid #F2F4F7",
    height: "100%",
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      console.log("Package Details on Inclusion tab:", packageDetails);
    }
    setTabValue(newValue);
  };

  const renderSwitch = () => {
    switch (tabValue) {
      case 0:
        return <Details setTabValue={setTabValue} />;
      case 1:
        return <Inclusion setTabValue={setTabValue} tabValue={tabValue} />;
      case 2:
        return <PackageImages setTabValue={setTabValue} tabValue={tabValue} />;
      case 3:
        return <Summary />;
      default:
        return null;
    }
  };

  return (
    <PackageContext.Provider value={{ packageDetails, setPackageDetails }}>
      <div
        style={{
          padding: "2vh 3vw",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          backgroundColor: "#F9F9FA",
        }}
      >
        <h2 style={{ fontFamily: "Inter Tight" }}>Create Package</h2>
        <Box sx={modalStyle}>
          <Stack direction="row" justifyContent="space-between">
            <Tabs
              value={tabValue}
              variant="scrollable"
              onChange={handleTabChange}
            >
              <Tab label="Details" />
              <Tab
                label="Inclusion"
                disabled={
                  !(packageDetails && Object.keys(packageDetails).length > 0)
                }
              />
              <Tab label="Images" disabled={!packageDetails?.inclusions} />
              <Tab
                label="Summary"
                disabled={
                  !packageDetails?.inclusions || !packageDetails?.images
                }
              />
            </Tabs>
          </Stack>
          {renderSwitch()}
        </Box>
      </div>
    </PackageContext.Provider>
  );
};

export default CreatePackage;

export const usePackageContext = () => useContext(PackageContext);
