import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  TableSortLabel,
} from "@mui/material";
import { statusStyleArray } from "../config";
import { formattedAmountWithoutAed } from "../../../utils/CommonFunction";
import moment from "moment";
import { ReactComponent as ProjectActiveIcon } from "../../../assets/svg/ProjectActiveIcon.svg";

export const getStatusDisplayText = (text) => {
  if (typeof text !== "string") return text;
  const displayText = text
    .replace(/-/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return displayText;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
}));

export default function PaymentDetails({
  details,
  handleSort,
  sortBy,
  sortOrder,
}) {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflow: "none !important",
          boxShadow: "none",
          maxHeight: "77vh",
        }}
      >
        <Table
          sx={{
            "& thead": {
              "& th": {
                color: "#949DAA",
                fontWeight: "500",
              },
            },
            "& tbody": {
              "& tr:hover": {
                backgroundColor: "#f7f7f7",
              },
              "& tr": {
                borderBottom: "none",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>Project Name</StyledTableCell>
              <StyledTableCell>Payment Group</StyledTableCell>
              <StyledTableCell>Payment Group Status</StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={sortBy === "dueDate"}
                  direction={sortOrder.toLowerCase()}
                  onClick={() => handleSort("dueDate")}
                >
                  Due Date
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                <strong>Amount (AED)</strong>
              </StyledTableCell>
              <StyledTableCell>Release Date</StyledTableCell>
              <StyledTableCell>Payout Status</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((item, idx) => (
              <TableRow key={item.id}>
                <StyledTableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "7px",
                    }}
                  >
                    <ProjectActiveIcon />
                    {item?.projectName}
                  </Box>
                </StyledTableCell>

                <StyledTableCell>{item?.groupName}</StyledTableCell>

                <StyledTableCell>
                  {item?.paymentGroupStatus !== "Created" && (
                    <Typography
                      style={{
                        borderRadius: "34px",
                        padding: "4px 8px",
                        fontSize: "14px",
                        minWidth: "7ch",
                        width: "fit-content",
                        backgroundColor:
                          statusStyleArray.find(
                            (style) => style.status === item?.paymentGroupStatus
                          )?.backgroundColor || "transparent",
                        color:
                          statusStyleArray.find(
                            (style) => style.status === item?.paymentGroupStatus
                          )?.color || "black",
                        border: "none",
                        textAlign: "center",
                      }}
                    >
                      {getStatusDisplayText(item?.paymentGroupStatus)}
                    </Typography>
                  )}
                </StyledTableCell>

                <StyledTableCell>
                  {moment(item?.dueDate).format("MMM DD, YYYY")}
                </StyledTableCell>

                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>{`${formattedAmountWithoutAed(
                    item?.amount
                  )}`}</strong>
                </StyledTableCell>

                <StyledTableCell>
                  {item?.releaseDate &&
                  moment(item?.releaseDate).format("MMM DD, YYYY")
}
                </StyledTableCell>

                <StyledTableCell>
                  {item?.status !== "CREATED" && (
                    <Typography
                      style={{
                        borderRadius: "34px",
                        padding: "4px 8px",
                        fontSize: "14px",
                        minWidth: "7ch",
                        width: "fit-content",
                        backgroundColor:
                          statusStyleArray.find(
                            (style) => style.status === item?.status
                          )?.backgroundColor || "transparent",
                        color:
                          statusStyleArray.find(
                            (style) => style.status === item?.status
                          )?.color || "black",
                        border: "none",
                        textAlign: "center",
                      }}
                    >
                      {getStatusDisplayText(item?.status)}
                    </Typography>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
