import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import _, { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { HighlightOffOutlined } from "@mui/icons-material";
import { Setting } from "../../../utils/Setting";
import Images from "../../../config/images";
import { getApiData, postFormData } from "../../../utils/APIHelper";
import theme from "../../../config/theme";
import CAutocomplete from "../../../components/CAutocomplete";
import CInput from "../../../components/CInput";
import { useSelector } from "react-redux";
import { usePackageContext } from "..";
import styled from "@emotion/styled";

const errorObj = {
  typeErr: false,
  typeMsg: "",
  nameErr: false,
  nameMsg: "",
  descriptionErr: false,
  descriptionMsg: "",
  timelineErr: false,
  timelineMsg: "",
  costErr: false,
  costMsg: "",
  budgetTypeErr: false,
  budgetTypeMsg: "",
  documentErr: false,
  documentMsg: "",
  sizeMetadataErr: false,
  sizeMetadataMsg: "",
};

const MultiCustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: 2,
  "& .MuiOutlinedInput-root": {
    padding: "14px 12px",
    backgroundColor: "#FFF",
    "&.Mui-focused": {
      boxShadow: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E8E8E8 !important",
      },
    },
    "&:hover": {
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E8E8E8 !important",
      },
    },
    "& .MuiAutocomplete-input": {
      position: "relative",
      backgroundColor: "#FFF",
      fontSize: 14,
      fontFamily: "Inter Tight",
      padding: 0,
    },
    "& MuiAutocomplete-tag": {
      padding: 0,
      margin: 0,
    },
  },
  "& .Mui-focused": {
    outline: "unset",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E8E8E8 !important",
  },
}));

const Details = ({ setTabValue }) => {
  const navigate = useNavigate();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const { userData } = useSelector((state) => state.auth);
  const { packageDetails, setPackageDetails } = usePackageContext();

  const [errObj, setErrObj] = useState(errorObj);
  const [options, setOptions] = useState([]);
  const [details, setDetails] = useState({
    projectType: "",
    name: "",
    description: "",
    tags: [],
    timelineNumber: "",
    timelineOption: "",
    cost: "",
    budgetType: "",
    primaryImage: "",
    sizeMetadata: "",
  });
  const expertiseList = [
    { label: "Interior Design", value: "Interior Design" },
    { label: "Home Renovation", value: "Home Renovation" },
    { label: "Rooms", value: "Rooms" },
  ];
  const budgetOptions = [
    { label: "Economic", value: "Economic" },
    { label: "Standard", value: "Standard" },
    { label: "High End", value: "High End" },
    { label: "Super High End", value: "Super High End" },
  ];
  const timelineOptions = [
    { label: "Days", value: "Days" },
    { label: "Weeks", value: "Weeks" },
    { label: "Months", value: "Months" },
  ];
  const [uploadLoader, setUploadLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (packageDetails && packageDetails?.id) {
      getDetailsApiCall(packageDetails?.id);
    }
  }, [packageDetails?.id]);

  const handleClose = () => {
    navigate("/packages");
  };

  async function saveDetailsApiCall(data) {
    setButtonLoader(true);
    try {
      const response = await getApiData(
        `api/v2/package/`,
        "POST",
        data,
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        toast.success("Details Added Successfully");
        setPackageDetails(response);
        setTabValue(1);
      } else {
        toast.error(response.message);
      }
      setButtonLoader("");
    } catch (error) {
      toast.error(error.toString());
      setButtonLoader("");
    }
  }

  async function editDetailsApiCall(data) {
    setButtonLoader(true);
    try {
      const response = await getApiData(
        `api/v2/package/${packageDetails?.id}`,
        "PUT",
        data,
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        toast.success("Details Updated Successfully");
        setPackageDetails(response);
        setTabValue(1);
      } else {
        toast.error(response.message);
      }
      setButtonLoader("");
    } catch (error) {
      toast.error(error.toString());
      setButtonLoader("");
    }
  }

  async function getDetailsApiCall(id) {
    setLoader(true);
    try {
      const response = await getApiData(
        `api/v2/package/${id}`,
        "GET",
        {},
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        const [number, option] = response?.timeline?.split(" ");
        const formattedOption = option
          ? option.charAt(0).toUpperCase() + option.slice(1).toLowerCase()
          : "";
        setDetails({
          projectType: response?.projectType,
          name: response?.name,
          description: response?.description,
          tags: response?.tags || [],
          cost: response?.cost,
          budgetType: response?.budgetType,
          sizeMetadata: response?.sizeMetadata,
          timelineNumber: number || "",
          timelineOption: formattedOption || "",
          primaryImage:
            response?.primaryImage &&
            response?.primaryImage !== undefined &&
            response?.primaryImage.trim() !== ""
              ? JSON.parse(response.primaryImage)
              : "",
        });

        setPackageDetails(response);
        setLoader(false);
      } else {
        toast.error("Oops! Something went wrong");
      }
    } catch (error) {
      toast.error(error.toString());
    }
  }

  function handleSave() {
    const newErrObj = { ...errObj };
    let valid = true;

    const validations = [
      {
        field: details.projectType,
        errorKey: "typeErr",
        msgKey: "typeMsg",
        message: "Please select project type",
      },
      {
        field: details.name?.trim(),
        errorKey: "nameErr",
        msgKey: "nameMsg",
        message: "Please enter project name",
      },
      {
        field: details.description?.trim(),
        errorKey: "descriptionErr",
        msgKey: "descriptionMsg",
        message: "Please enter project description",
      },
      {
        field: details.timelineNumber?.trim() && details.timelineOption,
        errorKey: "timelineErr",
        msgKey: "timelineMsg",
        message: "Please enter a valid timeline",
      },
      {
        field: details.cost,
        errorKey: "costErr",
        msgKey: "costMsg",
        message: "Please enter cost for the project",
      },
      {
        field: details.budgetType,
        errorKey: "budgetTypeErr",
        msgKey: "budgetTypeMsg",
        message: "Please select budget type",
      },
      {
        field: details.primaryImage,
        errorKey: "documentErr",
        msgKey: "documentMsg",
        message: "Please upload image to proceed",
      },
      {
        field: details.sizeMetadata?.trim(),
        errorKey: "sizeMetadataErr",
        msgKey: "sizeMetadataMsg",
        message: "Please enter no. of roooms required",
      },
    ];

    validations.forEach(({ field, errorKey, msgKey, message }) => {
      if (!field) {
        valid = false;
        newErrObj[errorKey] = true;
        newErrObj[msgKey] = message;
      } else {
        newErrObj[errorKey] = false;
        newErrObj[msgKey] = "";
      }
    });
    setErrObj(newErrObj);
    if (!valid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const {
        timelineNumber,
        timelineOption,
        primaryImage,
        cost,
        ...restDetails
      } = details;
      const body = {
        ...restDetails,
        owner: { id: userData?.contractor_data?.id },
        timeline: `${timelineNumber} ${timelineOption}`,
        cost: parseInt(cost),
        primaryImage: JSON.stringify(primaryImage),
      };
      console.log("bd", body);
      saveDetailsApiCall(body);
    }
  }

  function handleEdit() {
    const newErrObj = { ...errObj };
    let valid = true;

    const validations = [
      {
        field: details.projectType,
        errorKey: "typeErr",
        msgKey: "typeMsg",
        message: "Please select project type",
      },
      {
        field: details.name?.trim(),
        errorKey: "nameErr",
        msgKey: "nameMsg",
        message: "Please enter project name",
      },
      {
        field: details.description?.trim(),
        errorKey: "descriptionErr",
        msgKey: "descriptionMsg",
        message: "Please enter project description",
      },
      {
        field: details.timelineNumber?.trim() && details.timelineOption,
        errorKey: "timelineErr",
        msgKey: "timelineMsg",
        message: "Please enter a valid timeline",
      },
      {
        field: details.cost,
        errorKey: "costErr",
        msgKey: "costMsg",
        message: "Please enter cost for the project",
      },
      {
        field: details.budgetType,
        errorKey: "budgetTypeErr",
        msgKey: "budgetTypeMsg",
        message: "Please select budget type",
      },
      {
        field: details.primaryImage,
        errorKey: "documentErr",
        msgKey: "documentMsg",
        message: "Please upload image to proceed",
      },
      {
        field: details.sizeMetadata?.trim(),
        errorKey: "sizeMetadataErr",
        msgKey: "sizeMetadataMsg",
        message: "Please enter no. of roooms required",
      },
    ];

    validations.forEach(({ field, errorKey, msgKey, message }) => {
      if (!field) {
        valid = false;
        newErrObj[errorKey] = true;
        newErrObj[msgKey] = message;
      } else {
        newErrObj[errorKey] = false;
        newErrObj[msgKey] = "";
      }
    });

    setErrObj(newErrObj);

    if (!valid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      const {
        timelineNumber,
        timelineOption,
        primaryImage,
        cost,
        ...restDetails
      } = details;
      const body = {
        ...restDetails,
        ownerId: userData?.contractor_data?.id,
        id: packageDetails?.id,
        cost: parseInt(cost),
        timeline: `${timelineNumber} ${timelineOption}`,
        primaryImage: JSON.stringify(primaryImage),
      };
      editDetailsApiCall(body);
    }
  }

  const handleAddTag = (newTag) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      tags: [...prevDetails.tags, newTag],
    }));
  };

  const MultiSelectTags = ({ details, onAddTag }) => {
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAddButton, setShowAddButton] = useState(false);
    const [selectedTags, setSelectedTags] = useState(details?.tags || []);

    // Sync `selectedTags` with `details.tags` when `details` updates
    useEffect(() => {
      setSelectedTags(details.tags);
    }, [details.tags]);

    // Fetch options from API based on input
    useEffect(() => {
      if (inputValue.length >= 3) {
        const fetchTags = async () => {
          setLoading(true);
          try {
            const response = await getApiData(
              `/api/v2/tags/design/search/${inputValue}`,
              "GET",
              {},
              {},
              true
            );
            const tags = response;
            setOptions(tags);
            setShowAddButton(tags?.length === 0 ? true : false);
          } catch (error) {
            console.error("Error fetching tags:", error);
          } finally {
            setLoading(false);
          }
        };
        fetchTags();
      } else {
        setOptions([]);
        setShowAddButton(false);
      }
    }, [inputValue]);

    // Handle tag selection
    const handleTagSelect = (event, newSelectedTags) => {
      console.log("ene", newSelectedTags);
      setSelectedTags(newSelectedTags); // Update local selected tags
      newSelectedTags.forEach((tag) => {
        if (!details.tags.some((existingTag) => existingTag.id === tag.id)) {
          onAddTag(tag); // Update `details.tags` if the tag is new
        }
      });

      const removedTags = selectedTags.filter(
        (tag) => !newSelectedTags.some((newTag) => newTag.id === tag.id)
      );

      console.log("r", removedTags);
      removedTags.forEach((removedTag) => {
        details.tags = details.tags.filter((tag) => tag.id !== removedTag.id);
      });
    };

    const handleAddNewTag = async () => {
      try {
        const response = await getApiData(
          "api/v2/tags/design",
          "POST",
          { name: inputValue },
          { "Content-Type": "application/json" },
          true
        );
        const newTag = response;
        console.log("newTag", newTag);
        onAddTag(newTag);
        setOptions((prevOptions) => [...prevOptions, newTag]);
        setSelectedTags((prevTags) => [...prevTags, newTag]);
        setInputValue("");
      } catch (error) {
        console.error("Error adding tag:", error);
      }
    };

    return (
      <div>
        <InputLabel
          shrink
          htmlFor="input"
          style={{ fontSize: "18px !important" }}
        >
          Package Tags
        </InputLabel>
        <MultiCustomAutocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          value={selectedTags}
          onChange={handleTagSelect}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          placeholder="Search Tags"
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={!selectedTags?.length > 0 && "Search Tags"}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {showAddButton && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddNewTag}
                      >
                        Add Tag
                      </Button>
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
    );
  };

  async function handleFileUpload(e) {
    const file = e.target.files[0];
    if (!file) {
      toast.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file_1", file);
    formData.append("type", "reno_package");
    formData.append("tags", "primaryImage");
    setUploadLoader(true);

    try {
      const response = await postFormData(
        Setting.endpoints.packageFiles,
        formData
      );
      if (response.success) {
        toast.success("File uploaded successfully");
        setDetails({ ...details, primaryImage: response?.images[0] });
        setUploadLoader(false);
        setErrObj({
          ...errObj,
          documentErr: false,
          documentMsg: "",
        });
      } else {
        toast.error("File upload failed");
        setUploadLoader(false);
      }
    } catch (error) {
      toast.error("An error occurred during file upload");
      setUploadLoader(false);
    }
  }

  const handleDeleteImage = async () => {
    setUploadLoader(true);
    try {
      const response = await getApiData(
        `api/project/delete-files/${details?.primaryImage?.id}`,
        "POST",
        {},
        { "Content-Type": "application/json" },
        false
      );
      if (response.success) {
        setDetails({ ...details, primaryImage: "" });
        toast.success(response?.message);
        setUploadLoader("");
      }
    } catch (error) {
      toast.error(error.toString());
      setUploadLoader("");
    }
  };

  function displayImagesView() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          borderRadius: 6,
          marginBottom: 10,
          overflow: "hidden",
        }}
      >
        <img
          style={{
            width: "100%",
            height: 250,
            borderRadius: 6,
            objectFit: "cover",
          }}
          src={details?.primaryImage?.image}
          alt="Primary Image"
        />
        <HighlightOffOutlined
          style={{
            position: "absolute",
            top: 5,
            right: 5,
            cursor: "pointer",
            fontSize: 28,
            color: "red",
            zIndex: 10,
          }}
          onClick={(e) => {
            handleDeleteImage();
          }}
        />
      </div>
    );
  }

  useEffect(() => {
    console.log("deta", details);
  }, [details]);

  return (
    <>
      {loader ? (
        <center>
          <CircularProgress style={{ color: "#274BF1" }} size={26} />
        </center>
      ) : (
        <Stack
          overflow="auto"
          gap="8px"
          sx={{ overflowX: "hidden !important" }}
        >
          <Grid
            item
            xs={12}
            style={{
              paddingTop: 10,
            }}
          >
            {uploadLoader ? (
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems={"center"}
                sx={12}
                minHeight={220}
              >
                <CircularProgress style={{ color: "#274BF1" }} size={26} />
              </Grid>
            ) : (
              <>
                <InputLabel
                  shrink
                  error={errObj.documentErr}
                  style={{ fontSize: "1rem", color: "#202939" }}
                >
                  Primary Image
                </InputLabel>
                {details?.primaryImage !== "" ? (
                  <Grid
                    item
                    style={{
                      width: "100%",
                    }}
                  >
                    {displayImagesView()}
                  </Grid>
                ) : (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      position: "relative",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 170,
                        border: "1px dashed #9CA3AF",
                        borderRadius: 4,
                        gap: 1,
                      }}
                    >
                      <div style={{ width: "24px", height: "24px" }}>
                        <img src={Images.upload_icon} alt="upload-icon"></img>
                      </div>
                      <InputLabel>
                        <b>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#2563EB",
                            }}
                          >
                            Click to upload Images
                          </span>{" "}
                          or drag and drop{" "}
                        </b>
                      </InputLabel>
                      <InputLabel style={{ fontSize: 12, color: "#6B7280" }}>
                        {"PNG, JPG, (max size 1200*800)"}
                      </InputLabel>
                    </div>
                    <input
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        opacity: 0,
                        cursor: "pointer",
                        width: "100%",
                        cursor:
                          details?.primaryImage !== ""
                            ? "not-allowed"
                            : "pointer",
                      }}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e)}
                      disabled={details?.primaryImage !== ""}
                    />
                    <FormHelperText
                      error={errObj.documentErr}
                      style={{ fontFamily: "Inter Tight" }}
                    >
                      {errObj.documentMsg}
                    </FormHelperText>
                  </Grid>
                )}
              </>
            )}
          </Grid>

          <Stack gap="8px">
            <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
              <Grid item xs={6}>
                <CAutocomplete
                  label="Project Type"
                  placeholder="Select project type"
                  value={expertiseList.find(
                    (option) => option?.value === details.projectType
                  )}
                  onChange={(e, newValue) => {
                    setDetails((prev) => ({
                      ...prev,
                      projectType: newValue?.value,
                    }));
                    setErrObj({
                      ...errObj,
                      typeErr: false,
                      typeMsg: "",
                    });
                  }}
                  options={expertiseList}
                  getOptionLabel={(option) => option?.label}
                  error={errObj.typeErr}
                  helpertext={errObj.typeMsg}
                />
              </Grid>
              <Grid item xs={6}>
                <CInput
                  label="Name"
                  placeholder="Enter package name"
                  value={details.name}
                  onChange={(e) => {
                    setDetails((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                    setErrObj({
                      ...errObj,
                      nameErr: false,
                      nameMsg: "",
                    });
                  }}
                  inputProps={{ maxLength: 50 }}
                  error={errObj.nameErr}
                  helpertext={errObj.nameMsg}
                />
              </Grid>
            </Grid>
          </Stack>

          <Stack gap="8px" style={{ paddingTop: 10 }}>
            <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
              <Grid item xs={6}>
                <CAutocomplete
                  label="Budget Type"
                  placeholder="Select budget type"
                  value={budgetOptions.find(
                    (option) =>
                      option?.value.toUpperCase() === details.budgetType
                  )}
                  onChange={(e, newValue) => {
                    setDetails((prev) => ({
                      ...prev,
                      budgetType: newValue?.value.toUpperCase(),
                    }));
                    setErrObj({
                      ...errObj,
                      budgetTypeErr: false,
                      budgetTypeMsg: "",
                    });
                  }}
                  options={budgetOptions}
                  getOptionLabel={(option) =>
                    option?.value
                      ? option.value
                          .toLowerCase()
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : ""
                  }
                  error={errObj.budgetTypeErr}
                  helpertext={errObj.budgetTypeMsg}
                />
              </Grid>
              <Grid item xs={6}>
                <CInput
                  label="Cost"
                  placeholder="Enter package cost"
                  value={details.cost}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^[1-9]\d*$/.test(inputValue) || inputValue === "") {
                      // Allow only positive integers greater than 0 or an empty string
                      setDetails((prev) => ({
                        ...prev,
                        cost: inputValue,
                      }));
                      setErrObj({
                        ...errObj,
                        costErr: false,
                        costMsg: "",
                      });
                    }
                  }}
                  inputProps={{
                    maxLength: 50,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    style: { appearance: "textfield" },
                  }}
                  error={errObj.costErr}
                  helpertext={errObj.costMsg}
                />
              </Grid>
            </Grid>
          </Stack>

          <Stack gap="8px" style={{ paddingTop: 10 }}>
            <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: "10px",
                  flex: "1",
                }}
              >
                <div style={{ width: "100%" }}>
                  <CInput
                    label="Timeline"
                    placeholder="Enter timeline"
                    value={details.timelineNumber}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[1-9]\d*$/.test(value) || value === "") {
                        // Allow only positive integers and empty values
                        setDetails((prev) => ({
                          ...prev,
                          timelineNumber: value,
                        }));
                        setErrObj({
                          ...errObj,
                          timelineErr: false,
                          timelineMsg: "",
                        });
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    error={errObj.timelineErr}
                    helpertext={errObj.timelineMsg}
                    style={{ appearance: "textfield" }}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <CAutocomplete
                    label="Timeline Span"
                    placeholder="Select timeline span"
                    value={timelineOptions.find(
                      (option) => option?.value === details.timelineOption
                    )}
                    onChange={(e, newValue) => {
                      setDetails((prev) => ({
                        ...prev,
                        timelineOption: newValue?.value,
                      }));
                      setErrObj({
                        ...errObj,
                        timelineErr: false,
                        timelineMsg: "",
                      });
                    }}
                    options={timelineOptions}
                    getOptionLabel={(option) => option?.value}
                    error={errObj.timelineErr}
                    helpertext={errObj.timelineMsg}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <CInput
                  label="Bedrooms"
                  placeholder="No. of bedrooms"
                  value={details.sizeMetadata}
                  onChange={(e) => {
                    setDetails((prev) => ({
                      ...prev,
                      sizeMetadata: e.target.value,
                    }));
                    setErrObj({
                      ...errObj,
                      sizeMetadataErr: false,
                      sizeMetadataMsg: "",
                    });
                  }}
                  inputProps={{ maxLength: 50 }}
                  error={errObj.sizeMetadataErr}
                  helpertext={errObj.sizeMetadataMsg}
                />
              </Grid>
            </Grid>
          </Stack>

          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <MultiSelectTags details={details} onAddTag={handleAddTag} />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <CInput
              multiline={true}
              rows={4}
              label="Description"
              placeholder="Provide package description"
              value={details.description}
              onChange={(e) => {
                setDetails((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
                setErrObj({
                  ...errObj,
                  descriptionErr: false,
                  descriptionMsg: "",
                });
              }}
              error={errObj.descriptionErr}
              helpertext={errObj.descriptionMsg}
            />
          </Grid>

          <Stack
            padding="10px"
            alignItems="end"
            flexDirection="row"
            justifyContent="flex-end"
            gap="10px"
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            {packageDetails?.id ? (
              <Button variant="contained" onClick={handleEdit}>
                {buttonLoader ? (
                  <CircularProgress size={26} style={{ color: "#fff" }} />
                ) : (
                  "Edit & Continue"
                )}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSave}>
                {buttonLoader ? (
                  <CircularProgress size={26} style={{ color: "#fff" }} />
                ) : (
                  "Save & Continue"
                )}
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Details;
