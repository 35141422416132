import { useSelector } from "react-redux";
import {
  Stack,
  Divider,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

import useStyles from "./styles";

import SingleMilestoneAccordion from "../../../../components/SingleMilestoneAccordian";
import SingleBudgetAccordion from "../../../../components/SingleBudgetAccordian";
import MilestonePaymentGroup from "../EditPaymentGroups/MilestonePaymentGroup";
import { transformDateFormat } from "../../../../utils/CommonFunction";
import { isEmpty } from "lodash";
import HomeOwnerSummary from "../../HomeOwnerSummary";
import { useState } from "react";
import { toast } from "react-toastify";
import { getApiData } from "../../../../utils/APIHelper";
import { useLocation } from "react-router-dom";

const UpdatedDetails = ({ handleTabValue }) => {
  const classes = useStyles();
  const { proposalDetails } = useSelector((state) => state.auth);
  const [buttonLoader, setButtonLoader] = useState(false);
  const location = useLocation();
  const [villa, setVilla] = useState(location?.state?.villa);

  const {
    budgets,
    start_date,
    end_date,
    description,
    milestones,
    name,
    project_type,
    scope_of_work,
    email,
  } = proposalDetails;

  async function handleSubmit() {
    try {
      setButtonLoader(true);
      const data = {
        status: "awaiting-approval",
      };
      const response = await getApiData(
        `api/project/update-status/${villa?.project_id}`,
        "PUT",
        data
      );

      setButtonLoader(false);
      if (response.success) {
        toast.success(response.message);
        window.location.reload();
      } else {
        toast.error(response.message || "Something went wrong. Try later");
      }
    } catch (error) {
      setButtonLoader(false);
      console.log("🚀 ~ file: index.js:63 ~ connect api call ~ error:", error);
      toast.error(error.toString());
    }
  }

  return (
    <Stack
      width="100%"
      gap="16px"
      overflow="auto"
      sx={{ overflowX: "hidden !important" }}
    >
      <Stack width="100%" gap="16px">
        <Typography
          className={classes.projectInformation}
          sx={{ backgroundColor: "#F3F4F9" }}
        >
          Project Details
        </Typography>
        <Stack gap="8px" width="100%">
          <Stack
            direction="row"
            gap="8px"
            padding="0 12px"
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Stack>
              <Typography className={classes.informationCard}>
                Project Name
              </Typography>
              <Typography className={classes.value}>{name}</Typography>
            </Stack>
            <Stack>
              <Typography className={classes.informationCard}>
                Project Type
              </Typography>
              <Typography className={classes.value}>{project_type}</Typography>
            </Stack>

            <Stack>
              <Typography className={classes.informationCard}>Email</Typography>
              <Typography className={classes.value}>{email || "NA"}</Typography>
            </Stack>
            <Stack>
              <Typography className={classes.informationCard}>
                Project Dates
              </Typography>
              <Typography className={classes.value}>
                {transformDateFormat(start_date)}-
                {transformDateFormat(end_date)}
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack padding="0 12px">
            <Stack>
              <Typography className={classes.informationCard}>
                Scope of work
              </Typography>
              <Typography className={classes.value}>{scope_of_work}</Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack padding="0 12px">
            <Stack>
              <Typography className={classes.informationCard}>
                Project Description
              </Typography>
              <Typography className={classes.value}>{description}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
      <Stack>
        <HomeOwnerSummary proposalDetails={proposalDetails} classes={classes} />
      </Stack>
      <Stack gap="16px" width="100%">
        <Stack direction="row" gap="4px" width="100%">
          <Typography className={classes.projectInformation} padding="4px 16px">
            Milestones
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "24px",
                height: "24px",
                backgroundColor: "#E9B55C",
                fontWeight: "bold",
                borderRadius: 22,
                fontSize: 12,
              }}
            >
              {Object.keys(proposalDetails.milestones).length}
            </span>
          </Typography>
        </Stack>
        <Divider />
        <Stack width="100%" gap="8px">
          {Object.keys(milestones).map((key) => {
            const milestone = milestones[key];
            return (
              <SingleMilestoneAccordion milestone={milestone} id={key} isEditScreen={true}>
                <Stack width="100%">
                  {Object.keys(budgets).map((budgetKey) => {
                    const budget = budgets[budgetKey];

                    if (key == budget.milestone_id) {
                      return (
                        <Stack padding="16px">
                          <SingleBudgetAccordion
                            budget={budget}
                            index={budgetKey}
                          />
                        </Stack>
                      );
                    }
                    return null;
                  })}
                </Stack>
              </SingleMilestoneAccordion>
            );
          })}
        </Stack>
      </Stack>
      <Divider />
      <Stack gap="16px" width="100%">
        <Typography className={classes.projectInformation}>
          Payment Groups
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "24px",
              height: "24px",
              backgroundColor: "#E9B55C",
              fontWeight: "bold",
              borderRadius: 22,
              fontSize: 12,
            }}
          >
            {proposalDetails?.payment_group &&
              Object.keys(proposalDetails.payment_group).length}
          </span>
        </Typography>
        {proposalDetails?.payment_group &&
          Object.keys(proposalDetails.payment_group).map((key) => {
            const pGroup = proposalDetails.payment_group[key];
            if (!isEmpty(pGroup.group)) {
              return (
                <MilestonePaymentGroup
                  groupId={key}
                  group={pGroup.group}
                  groupName={pGroup.groupName}
                  dueDate={pGroup.dueDate}
                  amount={pGroup.amount}
                  isExpandable
                  isRequestedProposal
                />
              );
            } else {
              return (
                <MilestonePaymentGroup
                  groupId={key}
                  group={pGroup.group}
                  groupName={pGroup.groupName}
                  dueDate={pGroup.dueDate}
                  amount={pGroup.amount}
                  isRequestedProposal
                />
              );
            }
          })}
        {console.log("v", villa, "p", proposalDetails)}
        {(villa?.status === "awaiting-contractor-confirmation" ||
          villa?.status === "proposal" ||
          villa?.status === "awaiting-project-pricing" ||
          villa?.status === "awaiting-review") && (
          <Stack padding="10px" alignItems="end">
            <Button variant="contained" onClick={handleSubmit}>
              {buttonLoader ? (
                <CircularProgress size={26} style={{ color: "#fff" }} />
              ) : (
                "Submit"
              )}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
export default UpdatedDetails;
