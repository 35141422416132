import { makeStyles } from "@mui/styles";
import { isMobile, isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
  },
  header: {
    fontFamily: "Inter Tight",
    padding: "2vh 1vw",
    letterSpacing: "0 !important",
  },
  subHeader: {
    letterSpacing: "0 !important",
    padding: ".5vh 1vw !important",
    background: "#f7f7f7",
    margin: "1vh 1vw 1vh 0 !important",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  documentRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: "Inter Tight !important",
    marginBottom: "1vh",
    justifyContent: "space-between",
    padding: "0 1vw !important",
    color: "rgb(13,13,14)",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    gap: "0px !important",
  },
  row: {
    flex: 1,
  },
  btn: {
    fontSize: "14px !important",
  },
  status: { display: "flex", alignItems: "center", gap: "10px" },
  verified_btn: {
    backgroundColor: "#4bb34b",
    color: "#fff",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "23px",
  },
  not_verified_btn: {
    backgroundColor: "#e54646",
    color: "#fff",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "23px",
  },
  pending_btn: {
    backgroundColor: "#ffa000",
    color: "#fff",
    borderRadius: "8px",
    padding: "3px",
    fontSize: "23px",
  },
  submitted_btn: {
    color: "#fff",
    backgroundColor: "rgb(9, 109, 217)",
    borderRadius: "4px",
    padding: "3px",
    fontSize: "23px",
  },
  file_url: {
    display: "flex",
    alignItems: "center",
    color: "#4e6bf6",
    textTransform: "capitalize",
    gap: "5px",
  },
  mandatory: {
    color: "red",
    fontSize: "18px",
  },
  status_wrapper: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    fontSize: "16px",
  },
  verified_text: {
    color: "#4bb34b",
    fontWeight: "800 !important",
    fontSize: "17px !important",
  },
  not_verified_text: {
    color: "#e54646",
    fontWeight: "800 !important",
    fontSize: "17px !important",
  },
  pending_text: {
    color: "#ffa000",
    fontWeight: "800 !important",
    fontSize: "17px !important",
  },
  submitted_text: {
    color: "rgb(9, 109, 217)",
    fontWeight: "800 !important",
    fontSize: "17px !important",
  },
  text: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    fontWeight: "400 !important",
  },
}));
export default useStyles;
