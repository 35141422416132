import {
  Stack,
  Divider,
  Typography,
  Button,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import useStyles from "./styles";
import { usePackageContext } from "..";
import SingleBoqAccordion from "../../../components/SingleBoqAccordian";
import { formattedAmount } from "../../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import ImageGallery from "../../../components/ImageGallery";
import { useState } from "react";
import { getApiData } from "../../../utils/APIHelper";
import { toast } from "react-toastify";

const Summary = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { packageDetails } = usePackageContext();
  const [buttonLoader, setButtonLoader] = useState(false);
  const {
    inclusions,
    description,
    name,
    projectType,
    budgetType,
    cost,
    tags,
    timeline,
  } = packageDetails;

  const handleSubmit = async () => {
    setButtonLoader(true);
    const body = {
      status: "SUBMITTED",
    };
    try {
      const response = await getApiData(
        `api/v2/package/${packageDetails?.id}`,
        "PATCH",
        body,
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        toast.success("Package Submitted successfully");
        navigate("/packages");
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setButtonLoader(false);
    }
  };

  const handleSaveDraft = async () => {
    setButtonLoader(true);
    const body = {
      status: "CREATED",
    };
    try {
      const response = await getApiData(
        `api/v2/package/${packageDetails?.id}`,
        "PATCH",
        body,
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        toast.success("Package successfully saved as Draft ");
        navigate("/packages");
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setButtonLoader(false);
    }
  };

  return (
    <>
      {buttonLoader ? (
        <center>
          <CircularProgress />
        </center>
      ) : (
        <Stack
          width="100%"
          gap="16px"
          overflow="auto"
          sx={{
            overflowX: "hidden !important",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none", // IE and Edge
            scrollbarWidth: "none", // Firefox
          }}
        >
          <Stack width="100%" gap="16px">
            <Typography
              className={classes.projectInformation}
              sx={{ backgroundColor: "#F3F4F9" }}
            >
              Package Details
            </Typography>
            <Stack gap="8px" width="100%">
              <Stack
                direction="row"
                gap="8px"
                padding="0 12px"
                width="100%"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Stack>
                  <Typography className={classes.informationCard}>
                    Package Name
                  </Typography>
                  <Typography className={classes.value}>{name}</Typography>
                </Stack>
                <Stack>
                  <Typography className={classes.informationCard}>
                    Package Type
                  </Typography>
                  <Typography className={classes.value}>
                    {projectType}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography className={classes.informationCard}>
                    Budget Type
                  </Typography>
                  <Typography className={classes.value}>
                    {budgetType
                      ?.toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography className={classes.informationCard}>
                    Cost
                  </Typography>
                  <Typography className={classes.value}>
                    {formattedAmount(cost)}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack padding="0 12px">
                <Stack>
                  <Typography className={classes.informationCard}>
                    Package Description
                  </Typography>
                  <Typography className={classes.value}>
                    {description}
                  </Typography>
                </Stack>
              </Stack>

              <Divider />
              <Stack padding="0 12px">
                <Stack>
                  <Typography className={classes.informationCard}>
                    Package Timeline
                  </Typography>
                  <Typography className={classes.value}>{timeline}</Typography>
                </Stack>
              </Stack>
              {tags?.length > 0 && (
                <>
                  <Divider />
                  <Stack padding="0 12px">
                    <Stack>
                      <Typography className={classes.informationCard}>
                        Package Tags
                      </Typography>
                      <Grid container spacing={1}>
                        {tags?.map((tag, index) => (
                          <Grid item key={index}>
                            <Typography
                              className={classes.value}
                              style={{
                                backgroundColor: "#f7f7f7",
                                padding: ".7vh 1vw",
                                margin: ".3vh .1vw",
                                width: "fit-content",
                                borderRadius: "4px",
                              }}
                            >
                              {tag?.name}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
            <Divider />
          </Stack>
          <Stack gap="16px" width="100%">
            <Stack direction="row" gap="4px" width="100%">
              <Typography
                className={classes.projectInformation}
                padding="4px 16px"
              >
                Inclusions
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#E9B55C",
                    fontWeight: "bold",
                    borderRadius: 22,
                    fontSize: 12,
                  }}
                >
                  {inclusions.length}
                </span>
              </Typography>
            </Stack>
            <Stack width="100%" gap="8px">
              {inclusions?.map((item, idx) => (
                <SingleBoqAccordion
                  budget={item}
                  index={idx}
                  key={idx}
                  isSummary={true}
                />
              ))}
            </Stack>
            <Divider />
          </Stack>
          <ImageGallery details={packageDetails} />
          <Stack
            padding="10px"
            alignItems="end"
            flexDirection="row"
            justifyContent="flex-end"
            gap="10px"
          >
            <Button variant="outlined" onClick={handleSaveDraft}>
              Save Draft
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};
export default Summary;
