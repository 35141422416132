import React, { useState } from "react";
import {
  Modal,
  Fade,
  Box,
  Grid,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import theme from "../../../../config/theme";
import { Close } from "@mui/icons-material";
import CInput from "../../../../components/CInput";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { getApiData, postFormData } from "../../../../utils/APIHelper";
import { Setting } from "../../../../utils/Setting";
import { toast } from "react-toastify";
import { usePackageContext } from "../..";
import CloseIcon from "@mui/icons-material/Close";

function Form({
  visible,
  isCreationOpen,
  mode,
  handleCloseCreation,
  visibleEditModal,
  budgetDetails,
  setBudgetDetails,
  setErrObj,
  errObj,
  clearErr,
  handleUpdate,
  handleSave,
}) {
  const { packageDetails } = usePackageContext();

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonLoader, setButtonLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 700,
    height: sm ? 300 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    padding: "24px",
    overflow: "auto",
  };

  if (typeof budgetDetails?.imageMetadata === "string") {
    try {
      setBudgetDetails({
        ...budgetDetails,
        imageMetadata: JSON.parse(budgetDetails?.imageMetadata),
      });
    } catch (e) {
      console.error("Error parsing image metadata:", e);
    }
  }

  const handleBoqImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file_1", file);
      formData.append("type", "package_boq");
      formData.append("tags", "");

      try {
        const response = await postFormData(
          Setting.endpoints.packageFiles,
          formData
        );
        if (response.success) {
          setBudgetDetails({
            ...budgetDetails,
            imageMetadata: response.images[0],
          });
          toast.success("BOQ Image uploaded successfully");
        } else {
          toast.error("File upload failed");
        }
      } catch (error) {
        toast.error("An error occurred during file upload");
      }
    }
  };

  const handleDeleteImage = async () => {
    setButtonLoader(true);
    try {
      const response = await getApiData(
        `api/project/delete-files/${budgetDetails?.imageMetadata?.id}`,
        "POST",
        {},
        { "Content-Type": "application/json" },
        false
      );
      if (response.success) {
        setBudgetDetails({ ...budgetDetails, imageMetadata: "" });
        toast.success(response?.message);
        setButtonLoader("");
      }
    } catch (error) {
      toast.error(error.toString());
      setButtonLoader("");
    }
  };

  return (
    <Modal open={visible || isCreationOpen} onClose={handleCloseCreation}>
      <Fade in={visible || isCreationOpen} style={{ height: " auto" }}>
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {mode === "modal" ? (
              <div className="addMilestoneHeader">Update Budget Item</div>
            ) : (
              <div className="addMilestoneHeader">Add Budget Item</div>
            )}
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => handleCloseCreation()}
            />
          </div>

          <div
            style={{
              marginTop: 12,
              height: 1,
              width: "100%",
              background: "#EEF0F3",
            }}
          />

          <Grid item xs={12} id="bName" mt={2} marginBottom={1}>
            <CInput
              label={<span className="fieldTitle">Budget Item Name</span>}
              placeholder="Enter Budget Name..."
              value={
                mode === "modal" && visibleEditModal
                  ? budgetDetails.name
                  : mode === "form" && visibleEditModal
                  ? ""
                  : budgetDetails.name
              }
              onChange={(e) => {
                setBudgetDetails({ ...budgetDetails, name: e.target.value });
                setErrObj({
                  ...errObj,
                  bNameErr: false,
                  bNameMsg: "",
                });
              }}
              inputProps={{ maxLength: 50 }}
              error={
                mode === "modal" && visibleEditModal
                  ? errObj.bNameErr
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.bNameErr
              }
              helpertext={
                mode === "modal" && visibleEditModal
                  ? errObj.bNameMsg
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.bNameMsg
              }
            />
          </Grid>

          <Grid item xs={12} id="description" mt={2} marginBottom={1}>
            <CInput
              label={
                <span className="fieldTitle">Budget Item Description</span>
              }
              placeholder="Enter Budget Description..."
              value={
                mode === "modal" && visibleEditModal
                  ? budgetDetails.description
                  : mode === "form" && visibleEditModal
                  ? ""
                  : budgetDetails.description
              }
              onChange={(e) => {
                setBudgetDetails({
                  ...budgetDetails,
                  description: e.target.value,
                });
                setErrObj({
                  ...errObj,
                  descriptionErr: false,
                  descriptionMsg: "",
                });
              }}
              error={
                mode === "modal" && visibleEditModal
                  ? errObj.description
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.descriptionErr
              }
              helpertext={
                mode === "modal" && visibleEditModal
                  ? errObj.descriptionMsg
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.descriptionMsg
              }
            />
          </Grid>

          <Grid item xs={12} id="materialType" marginBottom={1}>
            <CInput
              label={<span className="fieldTitle">Material type:</span>}
              placeholder="marble, wood, etc..."
              value={
                mode === "modal" && visibleEditModal
                  ? budgetDetails.materialType
                  : mode === "form" && visibleEditModal
                  ? ""
                  : budgetDetails.materialType
              }
              onChange={(e) => {
                setBudgetDetails({
                  ...budgetDetails,
                  materialType: e.target.value,
                });
                clearErr();
              }}
              inputProps={{ maxLength: 50 }}
              error={
                mode === "modal" && visibleEditModal
                  ? errObj.materialTypeErr
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.materialTypeErr
              }
              helpertext={
                mode === "modal" && visibleEditModal
                  ? errObj.materialTypeMsg
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.materialTypeMsg
              }
            />
          </Grid>

          <Grid item xs={12} id="materialUnit" marginBottom={1}>
            <CInput
              label={<span className="fieldTitle">Material Unit</span>}
              placeholder="Enter material quantity"
              value={
                mode === "modal" && visibleEditModal
                  ? budgetDetails.materialUnit
                  : mode === "form" && visibleEditModal
                  ? ""
                  : budgetDetails.materialUnit
              }
              type="tel"
              onChange={(e) => {
                const regex = /^\d*\.?\d{0,2}$/;
                if (regex.test(e.target.value)) {
                  setBudgetDetails({
                    ...budgetDetails,
                    materialUnit: e.target.value,
                  });
                }
                clearErr();
              }}
              inputProps={{
                pattern: "[0-9]*",
                inputMode: "decimal",
              }}
              error={
                mode === "modal" && visibleEditModal
                  ? errObj.materialUnitErr
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.materialUnitErr
              }
              helpertext={
                mode === "modal" && visibleEditModal
                  ? errObj.materialUnitMsg
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.materialUnitMsg
              }
            />
          </Grid>

          <Grid item xs={12} id="description" marginBottom={1}>
            <CInput
              multiline={true}
              rows={2}
              label={<span className="fieldTitle">Specifications:</span>}
              placeholder="Write here..."
              value={
                mode === "modal" && visibleEditModal
                  ? budgetDetails.specifications
                  : mode === "form" && visibleEditModal
                  ? ""
                  : budgetDetails.specifications
              }
              onChange={(e) => {
                setBudgetDetails({
                  ...budgetDetails,
                  specifications: e.target.value,
                });
                setErrObj({
                  ...errObj,
                  specificationsErr: false,
                  specificationsMsg: "",
                });
              }}
              error={
                mode === "modal" && visibleEditModal
                  ? errObj.specificationsErr
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.specificationsErr
              }
              helpertext={
                mode === "modal" && visibleEditModal
                  ? errObj.specificationsMsg
                  : mode === "form" && visibleEditModal
                  ? ""
                  : errObj.specificationsMsg
              }
            />
          </Grid>

          <Grid item xs={12} id="package_boq" marginBottom={1}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              component="label"
              disabled={!!budgetDetails.imageMetadata}
              size="small"
            >
              Upload Image
              <input
                type="file"
                hidden
                onChange={(e) => handleBoqImage(e)}
                accept="image/*"
              />
            </Button>
          </Grid>

          {budgetDetails?.imageMetadata && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "110px",
                position: "relative",
              }}
            >
              <img
                src={budgetDetails?.imageMetadata?.image}
                alt="Uploaded BOQ"
                style={{
                  width: "100px",
                  height: "100px",
                  border: "2px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  margin: "8px 0",
                }}
              />
              <IconButton
                onClick={handleDeleteImage}
                style={{
                  position: "absolute",
                  top: "-2px",
                  right: "-4px",
                  padding: "4px",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  borderRadius: "50%",
                  border: "2px solid red",
                }}
              >
                <CloseIcon style={{ fontSize: "12px", color: "red" }} />{" "}
              </IconButton>
            </div>
          )}

          <div
            style={{
              marginTop: 12,
              marginBottom: 12,
              height: 1,
              width: "100%",
              background: "#EEF0F3",
            }}
          />

          <Grid
            item
            container
            justifyContent={"center"}
            gap={sm ? 1 : 2}
            wrap="nowrap"
          >
            <Grid item xs={6}>
              <div className="cancel" onClick={handleCloseCreation}>
                Cancel
              </div>
            </Grid>
            <Grid item xs={6}>
              {mode === "modal" ? (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleUpdate()}
                >
                  Update
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleSave()}
                >
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default Form;
