import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Drawer,
  Box,
  IconButton,
  Card,
  Link,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import { color } from "../../../../config/theme";
import { Setting } from "../../../../utils/Setting";
import { getApiData } from "../../../../utils/APIHelper";
import ImageGallery from "./ImageGallery";
import InspectionDetailsCard from "./InspectionDetailsCard";
import moment from "moment";
import { MdOpenInNew } from "react-icons/md";
import { FcFile } from "react-icons/fc";

const InspectionDetails = ({ milestone, classes }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [details, setDetails] = useState({});
  const [projectFiles, setProjectFiles] = useState({
    inspectionReport: null,
    supportingImages: [],
    supportingFiles: [],
    loading: true,
  });
  const [loading, setLoading] = useState(false);

  const handleViewMoreClick = async (item) => {
    setSelectedItem(item);
    setDrawerOpen(true);
    await getInspectionDetails(item?.inspection_id);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedItem(null);
  };

  async function getInspectionDetails(inspectionId) {
    setLoading(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.inspectionDetails(inspectionId)}`,
        "GET",
        {},
        {},
        true
      );

      if (response) {
        setDetails(response);
        const documents = response?.documents || [];
        setProjectFiles({
          inspectionReport: documents.filter(
            (item) => item.tags === "INSPECTION-REPORT"
          )[0],
          supportingImages: documents.filter(
            (item) =>
              item.tags == "SUPPORTING-DOCS" && item.extention.includes("image")
          ),
          supportingFiles: documents.filter(
            (item) =>
              item.tags == "SUPPORTING-DOCS" &&
              !item.extention.includes("image")
          ),
          loading: false,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  function getResult(result) {
    if (result === "PASS") {
      return "Passed";
    } else if (result === "FAIL") {
      return "Failed";
    } else {
      return "Not Started";
    }
  }

  return (
    <>
      {Array.isArray(milestone?.inspection) &&
        milestone?.inspection?.map((item, index) => (
          <Grid
            container
            className={classes.card}
            rowGap={1}
            key={index}
            style={{ padding: "1vh 1vw"}}
          >
            <Grid
              item
              container
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
                paddingBottom: "1vh",
              }}
            >
              <Typography
                fontFamily={"Inter Tight"}
                fontWeight="bold"
                fontSize="18px"
              >
                Inspection Details
              </Typography>
              <Typography
                fontFamily={"Inter Tight"}
                fontWeight="bolder"
                fontSize="14px"
                sx={{
                  border:
                    item?.result === "PASS"
                      ? "none"
                      : item?.result === "FAIL"
                      ? "none"
                      : "1px solid gray",
                  color:
                    item?.result === "PASS"
                      ? "rgb(3, 77, 3)"
                      : item?.result === "FAIL"
                      ? "#ff4d4f"
                      : "#000",
                  backgroundColor:
                    item?.result === "PASS"
                      ? "rgb(204, 238, 233)"
                      : item?.result === "FAIL"
                      ? "rgb(255 188 182 / 63%)"
                      : "#fff",
                  padding: ".3vh .7vw",
                  borderRadius: "4px",
                  fontWeight: "900",
                }}
              >
                {getResult(item?.result)}
              </Typography>
            </Grid>

            <Grid item container xs={12} justifyContent="space-between">
              <Typography fontFamily={"Inter Tight"}>
                <span style={{ color: "#8C92A4" }}>Inspected At:</span>{" "}
                {item?.inspection_date
                  ? moment(item?.inspection_date).format("MMM DD, YYYY")
                  : "---"}
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => handleViewMoreClick(item)}
            >
              <Typography
                fontFamily={"Inter Tight"}
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                View inspection details
              </Typography>
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            width: "70%",
            height: "100%",
            maxHeight: "100%",
            backgroundColor: "rgb(241, 241, 241)",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${color.captionText}`}
          padding="8px 23px"
        >
          <Typography
            fontFamily={"Inter Tight"}
            fontWeight="bold"
            fontSize="16px"
          >
            Inspection Details
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={100}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <InspectionDetailsCard details={details} />
              <Card sx={{ margin: 3, padding: 4 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    fontFamily={"Inter Tight"}
                    fontWeight="800"
                    fontSize="18px"
                    paddingBottom="1vh"
                  >
                    Inspection Report
                  </Typography>
                  {projectFiles?.inspectionReport ? (
                    <Link
                      href={projectFiles.inspectionReport.path}
                      target="_blank"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        color: "text.primary",
                        padding: 1,
                        border: "1px solid",
                        borderRadius: 1,
                        "&:hover": {
                          backgroundColor: "action.hover",
                        },
                      }}
                    >
                      <FcFile size={20} />
                      <Typography sx={{ flexGrow: 1, ml: 1 }}>
                        Submitted_Report.pdf
                      </Typography>
                      <IconButton>
                        <MdOpenInNew />
                      </IconButton>
                    </Link>
                  ) : (
                    <Typography
                      fontFamily={"Inter Tight"}
                      fontWeight="800"
                      fontSize="16px"
                    >
                      No inspection report
                    </Typography>
                  )}
                </Box>
                <ImageGallery projectFiles={projectFiles} />
                <Box>
                  <Typography
                    fontFamily={"Inter Tight"}
                    fontWeight="bold"
                    fontSize="18px"
                    paddingBottom="1vh"
                  >
                    {projectFiles.supportingFiles.length
                      ? "Supporting Files"
                      : "No Supporting Files"}
                  </Typography>
                  {projectFiles.loading ? (
                    <Skeleton variant="rectangular" width="100%" height={50} />
                  ) : (
                    projectFiles.supportingFiles.map((file, index) => (
                      <Link
                        key={file.path}
                        href={file.path}
                        target="_blank"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          textDecoration: "none",
                          color: "text.primary",
                          padding: 1,
                          border: "1px solid",
                          borderRadius: 1,
                          mb: 1,
                          "&:hover": {
                            backgroundColor: "action.hover",
                          },
                        }}
                      >
                        <FcFile size={20} />
                        <Typography sx={{ flexGrow: 1, ml: 1 }}>
                          Supporting_File_{index + 1}
                        </Typography>
                        <IconButton>
                          <MdOpenInNew />
                        </IconButton>
                      </Link>
                    ))
                  )}
                </Box>
              </Card>
            </>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default InspectionDetails;
