import { styled } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const Status = styled("span")(({ theme, status }) => {
  let backgroundColor;
  let color;

  switch (status) {
    case "awaiting-approval":
      backgroundColor = "#e6f7ff";
      color = "#096dd9";
      break;
    case "awaiting-contractor-confirmation":
      backgroundColor = "#fff7e6";
      color = "#d46b08";
      break;
    case "rejected-by-contractor":
      backgroundColor = "#ecc2c1";
      color = "#500404";
      break;
    case "proposed-to-client":
      backgroundColor = "#CCEEE9";
      color = "#034d03";
      break;
    case "approved":
      backgroundColor = "#CCEEE9";
      color = "#034d03";
      break;
    case "proposal":
      backgroundColor = "#CCEEE9";
      color = "#034d03";
      break;
    case "SUBMITTED":
      backgroundColor = "#e6f7ff";
      color = "#096dd9";
      break;
    case "awaiting-project-pricing":
      backgroundColor = "#f6ffed";
      color = "#389e0d";
      break;
    case "CREATED":
      backgroundColor = "#feffe6";
      color = "#d4b106";
      break;
    default:
      backgroundColor = "#FFF3DF";
      color = "#272709";
  }

  return {
    display: "flex",
    justifyContent: "center",
    color,
    backgroundColor,
    padding: "4px 10px",
    fontSize: "10px",
    lineHeight: "16px",
    borderRadius: 4,
    fontFamily: "Inter Tight",
    whiteSpace: "nowrap",
  };
});
const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    minWidth: 380,
    maxWidth: 380,
    margin: "0px 8px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.04)",
    cursor: "pointer",
    transition: "transform 0.3s ease-out !important",
    "@media (min-width: 960px)": {
      "&:hover": {
        transform: "translate3d(0, -10px, 0)",
        filter:
          "drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))",
      },
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 280,
      flexDirection: "column",
      alignItems: "flex-start",
      "& > *": {
        padding: "4px 0px",
      },
      width: 200,
    },
  },

  name: {
    color: "#202939",
    fontFamily: "Inter Tight !important",
    fontSize: "20px !important",
    lineHeight: "28px !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  rowJustified: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  code1: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    color: "#202939",
    fontFamily: "Inter Tight !important",
    fontSize: "14px !important",
    lineHeight: "16px !important",
  },

  row: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    color: "#202939",
    fontFamily: "Inter Tight !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
  },

  company: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    color: "#202939",
    fontFamily: "Inter Tight !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
  },

  budget: {
    color: "#202939",
    fontFamily: "Inter Tight !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
  },
}));
export default useStyles;
