import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Divider,
  CircularProgress,
  Modal,
  Box,
  Stack,
  Backdrop,
  Tabs,
  Tab,
  Avatar,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useStyles, { Status } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import BlueAbout from "../../components/BlueAbout";
import { isArray, isEmpty } from "lodash";
import { useTheme } from "@mui/styles";
import ImageViewer from "../../components/ImageViewer";
import { color } from "../../config/theme";
import { getApiData } from "../../utils/APIHelper";
import { Setting } from "../../utils/Setting";
import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import authActions from "../../redux/reducers/auth/actions";
import ConversationsWithMessagesWrapper from "../../components/Chat/ConversationsWithMessagesWrapper";
import SingleMilestoneAccordion from "../../components/SingleMilestoneAccordian";
import SingleBudgetAccordion from "../../components/SingleBudgetAccordian";
import SubmittedDetails from "./SubmittedDetails";
import SubmittedSummary from "./SubmittedSummary";
import Images from "../../config/images";
import SubmittedBudget from "./SubmittedBudget";
import SubmittedMilestone from "./SubmittedMilestone";
import "./index.css";
import { toast } from "react-toastify";
import EditProposal from "./EditProposal";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModel from "../../components/ConfirmModel";
import CInput from "../../components/CInput";
import {
  formattedAmount,
  transformDateFormat,
} from "../../utils/CommonFunction";
import MilestonePaymentGroup from "./EditProposal/EditPaymentGroups/MilestonePaymentGroup";
import UploadPdf from "./UploadPdf";
import ProposalList from "./ProposalList";
import HomeOwnerSummary from "./HomeOwnerSummary";

export default function RequestedProposal() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "90%",
    bgcolor: "background.paper",
    padding: 4,
    overflow: "hidden",
  };

  const rejectModal = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
    bgcolor: "background.paper",
    px: 2,
    py: 2,
    overflow: "hidden",
  };

  const location = useLocation();
  const dispatch = useDispatch();
  const { setProposalDetails } = authActions;
  const { proposalDetails } = useSelector((state) => state.auth);
  const reload = useSelector((state) => state.auth.reload);
  const [villa, setVilla] = useState(location?.state?.villa);
  const nData = villa?.submitted_by_reno
    ? villa?.reno_data || {}
    : villa?.user_data || {};
  const isSubmitted = location?.state?.status === "submitted";
  const [isPressed, setIsPressed] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [url, setUrl] = useState("");
  const [imgurl, setImgUrl] = useState("");
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(3);
  const [isRejected, setIsRejected] = useState(false);
  const [notes, setNotes] = useState([{ note: "" }]);
  const [contractorFiles, setContractorFiles] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const isUpdateDraftProposal = Boolean(location?.state?.isUpdateDraftProposal);
  const [isCalled, setIsCalled] = useState(false);

  const renoNotes = villa?.notes?.filter((item) => item.createdBy === "reno");
  const contractorNotes = villa?.notes?.filter(
    (item) => item.createdBy !== "reno"
  );

  const [projectFiles, setProjectFiles] = useState([]);
  const [budget, setBudget] = useState([]);
  const fromManageProject = location?.state?.fromManageProject || false;
  const navigate = useNavigate();

  const isDisabled =
    villa?.status === "proposal" &&
    villa.proposal_status !== "open-for-modification";

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  async function imageUrlToBase64(url) {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  }

  useEffect(() => {
    getProjectImages();
    getContractFiles();
    getbudgetItems();
  }, []);

  const getProjectImages = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.projectFiles}/${villa?.project_id}?type=contractor`,
        "GET"
      );
      if (response.success) {
        setProjectFiles(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  async function transformImages(data) {
    const images = await Promise.all(
      data.map(async (item) => {
        const response = await imageUrlToBase64(item.image);
        return { id: item.id, image: response };
      }) || []
    );
    return images;
  }

  const [isBudgetFetched, setIsBudgetFetched] = useState(false);
  const [isVillaFetched, setIsVillaFetched] = useState(false);

  async function getbudgetItems() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.getBudgetItem}/${villa?.proposal_id}`,
        "GET"
      );
      if (response?.success) {
        const fetchedBudget = response?.data?.budget || [];
        setBudget(fetchedBudget);
        setIsBudgetFetched(true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const getProjectDetails = useCallback(
    async function () {
      try {
        await getbudgetItems();
        const response = await getApiData(
          `${Setting.endpoints.getProject}?proposal_id=${villa?.proposal_id}`,
          "GET"
        );
        if (
          response.success &&
          isArray(response?.data) &&
          !isEmpty(response?.data)
        ) {
          setVilla(response?.data[0]);
          setIsVillaFetched(true);
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [villa?.proposal_id]
  );

  // Initialize Proposal Details with both villa and budget
  const initializeProposalDetails = async (fetchedBudget, fetchedVilla) => {
    if (!fetchedVilla || !fetchedBudget) return; // Ensure both villa and budget are available
    // Fetch project images
    const fetchImages = async () => {
      const projectImageArr = [];
      const images = await Promise.all(
        projectFiles.map(async (item) => {
          const response = await imageUrlToBase64(item.path || item.image);
          return { id: item.id, image: response };
        }) || []
      );
      projectImageArr.push(...images);
      return projectImageArr;
    };

    const projectImages = await fetchImages();

    const newMilestones = {};
    const budgets = {};

    // Populate milestones and budgets
    fetchedVilla?.milestone?.forEach((milestone) => {
      newMilestones[milestone.id] = {
        milestone_name: milestone.milestone_name ?? "",
        description: milestone.description ?? "",
        start_date: milestone.start_date ?? "",
        end_date: milestone.end_date ?? "",
        amount: milestone.amount ?? 0,
        group_id: milestone.group_id ?? "",
        in_payment_group: milestone.group_id ? true : false,
        tags: milestone.tags ?? "",
      };
    });

    await Promise.all(
      fetchedBudget.map(async (budgetItem) => {
        const budgetImage = await transformImages(budgetItem.images);
        budgets[budgetItem.id] = {
          name: budgetItem.name ?? "",
          material_type: budgetItem.material_type ?? "",
          material_unit: budgetItem.material_unit ?? "",
          material_unit_price: budgetItem.material_unit_price ?? "",
          milestone_id: budgetItem.milestone_id ?? "",
          qty: budgetItem.qty ?? "",
          manpower_rate: budgetItem.manpower_rate ?? "",
          days: budgetItem.days ?? "",
          specification: budgetItem.specification ?? "",
          updatedAt: transformDateFormat(budgetItem.updatedAt),
          amount: budgetItem.total_item ?? 0,
          photo_origin: budgetImage ?? [],
        };
      })
    );

    // Ensure milestones are associated with their payment groups
    fetchedVilla?.payment_groups?.forEach((group) => {
      const { milestones, group_id } = group;
      milestones.forEach((milestone) => {
        if (newMilestones[milestone.id]) {
          newMilestones[milestone.id] = {
            ...newMilestones[milestone.id],
            group_id,
            in_payment_group: true,
          };
        }
      });
    });

    // Process payment groups
    const payment_groups = {};
    let sortedPaymentGroups = {};

    fetchedVilla?.payment_groups?.forEach((group) => {
      const transformedPaymentGroup = {};
      Object.keys(newMilestones).forEach((key) => {
        const milestone = newMilestones[key];
        if (milestone.group_id === group.group_id) {
          transformedPaymentGroup[key] = milestone;
        }
      });

      payment_groups[group.group_id] = {
        groupName: group.group_name,
        dueDate: group.due_date,
        group: transformedPaymentGroup,
        amount: group.amount,
        hasMilestones: !!group.milestones.length,
      };

      const entries = Object.entries(payment_groups);
      entries.sort((a, b) => new Date(a[1].dueDate) - new Date(b[1].dueDate));
      sortedPaymentGroups = Object.fromEntries(entries);
    });

    // Dispatch the updated proposal details
    dispatch(
      setProposalDetails({
        email: fetchedVilla?.customer_email || "",
        name: fetchedVilla?.name || "",
        customer_name: fetchedVilla?.username || "",
        project_type: fetchedVilla?.project_type || "",
        exp_id: fetchedVilla?.exp_id || "",
        description: fetchedVilla?.description || "",
        scope_of_work: fetchedVilla?.scope_of_work || "",
        project_image: projectImages || [],
        start_date: fetchedVilla?.start_date || "",
        end_date: fetchedVilla?.end_date || "",
        proposal_id: fetchedVilla?.proposal_id || "",
        milestones: newMilestones ?? {},
        budgets: budgets ?? {},
        payment_group: sortedPaymentGroups ?? {},
        savDraftProposalId: "",
        dimensions: JSON.parse(fetchedVilla?.dimensions) || "",
        form_json: fetchedVilla?.form_json || {},
      })
    );
  };

  useEffect(() => {
    console.log("rek", reload);
  }, [reload]);

  useEffect(() => {
    console.log("entered via reload", reload);
    if (isVillaFetched && isBudgetFetched) {
      setPageLoad(true);
      console.log("entered via reload 2e", reload);
      initializeProposalDetails(budget, villa);
      setPageLoad(false);
    }
  }, [isVillaFetched, isBudgetFetched, budget, villa, tabValue, reload]);

  const getContractFiles = useCallback(
    async function () {
      try {
        const response = await getApiData(
          `${Setting.endpoints.projectFiles}/${villa?.project_id}?type=admin`,
          "GET"
        );
        if (response.success) {
          setContractorFiles(response?.data);
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [villa?.id]
  );

  const getData = useCallback(async () => {
    setPageLoad(true);
    await getProjectDetails();
    await getContractFiles();
    setPageLoad(false);
  }, [getProjectDetails, getContractFiles]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getData();
  }, [getData, reload]);

  const handleSetTabValue = () => {
    setTabValue(3);
  };

  const handleOpen = () => {
    setIsOpen(true);
    setTabValue(0);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  async function approveProject() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.approveRejectProject}`,
        "PUT",
        { project_id: villa?.id, status: "approve" }
      );
      if (response.success) {
        toast.success("Proposal Approved");
        setIsApproved(false);
        villa.status = "awaiting-approval";
        window.location.reload();
      } else {
        toast.error(response.message);
        setIsApproved(false);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleApproveProject = () => {
    approveProject();
    // villa.status = "awaiting-approval";
    // setIsApproved(false);
  };

  async function handleRejectProject() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.approveRejectProject}`,
        "PUT",
        { project_id: villa?.id, status: "reject" }
      );
      if (response.success) {
        toast.success("Proposal Rejected");
        villa.status = "rejected-by-contractor";
        villa.notes = [...villa?.notes, ...notes.filter((item) => item.note)];
        setSubmitLoader(false);
        setIsRejected(false);
        window.location.reload();
      }
    } catch (error) {
      toast.error(error);
    }
  }

  async function handleSubmitNotes() {
    try {
      const response = await getApiData(`${Setting.endpoints.notes}`, "POST", {
        project_id: villa?.id,
        notes: notes.filter((note) => note.note).map((item) => item.note),
      });
      if (response.success) {
        toast.success("Notes added successfully");
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleSubmit = async () => {
    setSubmitLoader(true);
    if (notes[0].note === "") {
      toast.error("Notes should not be empty");
      setSubmitLoader(false);
    } else {
      await handleSubmitNotes();
      await handleRejectProject();
      // villa.status = "rejected-by-contractor";
      // villa.notes = [...villa?.notes, ...notes.filter((item) => item.note)];
      // setSubmitLoader(false);
      // setIsRejected(false);
    }
  };

  const handleAddNote = () => {
    setNotes([...notes, ""]);
  };

  const handleDeleteNotes = (index) => {
    setNotes(notes.filter((value, newIndex) => newIndex !== index));
  };

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = { note: value };
    setNotes(updatedNotes);
  };

  const transformStatus = (status, proposalStatus) => {
    if (proposalStatus === "open-for-modification") {
      return "Open For Modification";
    }

    if (status === "proposal") {
      return "proposed-to-client"
        ?.split("-")
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(" ");
    }
    return status
      ?.split("-")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");
  };

  const transformedStatus = transformStatus(
    villa?.status,
    villa?.proposal_status
  );

  const totalBudgetAmount = useMemo(() => {
    if (Object?.keys(proposalDetails).length > 0) {
      const amount = Object?.keys(proposalDetails?.milestones)?.reduce(
        (acc, key) => {
          let milestone = proposalDetails?.milestones[key];
          return acc + milestone?.amount;
        },
        0
      );
      return amount;
    }
  }, [proposalDetails]);

  return (
    <div>
      <Grid
        // item
        // container
        xs={12}
        sm={9}
        md={9}
        lg={12}
        alignItems="center"
        justifyContent="center"
        style={{ height: "97vh", overflowY: "scroll" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={9}
          className={classes.MainContainer}
          padding={"30px"}
          sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          {pageLoad ? (
            <div className={classes.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : (
            <>
              <Grid
                item
                container
                wrap={sm ? "wrap" : "nowrap"}
                alignItems={"center"}
                columnGap={2}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={nData?.profile_url}
                    alt="chat"
                    className={classes.imageStyle}
                  />
                  <div className={classes.activeContainer}>
                    <div className={classes.activeStatus}></div>
                  </div>
                </div>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Stack>
                    <Typography className={classes.titleText}>
                      {nData?.username}
                    </Typography>
                    <Status
                      status={
                        villa?.proposal_status === "open-for-modification"
                          ? villa?.proposal_status
                          : villa?.status
                      }
                    >
                      {transformedStatus}
                    </Status>
                  </Stack>

                  <Stack direction="row" gap="16px">
                    {villa?.status !== "submitted" && (
                      <Stack>
                        <Button
                          variant="contained"
                          onClick={handleOpen}
                          disabled={isDisabled}
                        >
                          Edit
                        </Button>
                      </Stack>
                    )}

                    <Stack item lg={3} md={3} sm={3} xs={3} textAlign={"end"}>
                      <Typography className={classes.requestDate}>
                        {isSubmitted ? "Submitted Date" : "Request Date"}
                      </Typography>
                      <Typography className={classes.dateStyle}>
                        {isSubmitted
                          ? transformDateFormat(villa?.updatedAt)
                          : transformDateFormat(villa?.createdAt)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              <>
                <Stack width="100%" gap="16px">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    className={classes.projectInformation}
                    sx={{
                      backgroundColor: "#F3F4F9",
                    }}
                  >
                    Project Details
                    {villa?.budget && (
                      <Stack>
                        <Typography className={classes.informationCard}>
                          Total Amount
                        </Typography>
                        <Typography className={classes.value}>
                          {formattedAmount(totalBudgetAmount)}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack gap="8px" width="100%">
                    <Stack
                      direction="row"
                      gap="8px"
                      padding="0 12px"
                      width="100%"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Stack>
                        <Typography className={classes.informationCard}>
                          Project Name
                        </Typography>
                        <Typography className={classes.value}>
                          {proposalDetails?.name}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography className={classes.informationCard}>
                          Project Type
                        </Typography>
                        <Typography className={classes.value}>
                          {proposalDetails?.project_type}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography className={classes.informationCard}>
                          Email
                        </Typography>
                        <Typography className={classes.value}>
                          {proposalDetails?.email || "NA"}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography className={classes.informationCard}>
                          Project Dates
                        </Typography>
                        <Typography className={classes.value}>
                          {transformDateFormat(proposalDetails?.start_date)}-
                          {transformDateFormat(proposalDetails?.end_date)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />
                      <Stack padding="0 12px">
                        <Stack>
                          <Typography className={classes.informationCard}>
                            Scope of work
                          </Typography>
                          <Typography className={classes.value}>
                            {proposalDetails?.scope_of_work || "---"}
                          </Typography>
                        </Stack>
                      </Stack>

                    <Divider />
                      <Stack padding="0 12px">
                        <Stack>
                          <Typography className={classes.informationCard}>
                            Project Description
                          </Typography>
                          <Typography className={classes.value}>
                            {proposalDetails?.description || "---"}
                          </Typography>
                        </Stack>
                      </Stack>
                    <Divider />
                  </Stack>
                </Stack>
{console.log("proposalDetails",proposalDetails)}
                <Stack>
                  <HomeOwnerSummary
                    proposalDetails={proposalDetails}
                    classes={classes}
                  />
                </Stack>

                {projectFiles?.length > 0 && (
                  <Stack padding="0px 12px" gap="8px">
                    <Typography fontFamily="Inter Tight" padding="0px 4px">
                      Project Files
                    </Typography>
                    <Stack direction="row" gap="16px" flexWrap="wrap">
                      {projectFiles?.map((item) => {
                        return (
                          <a
                            href={
                              item?.file_extention?.includes("pdf")
                                ? `${item.image}`
                                : null
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              onClick={() => {
                                if (item?.file_extention?.includes("image")) {
                                  setIsPressed(true);
                                  setImgUrl(item.path);
                                } else {
                                  setImgUrl("");
                                }
                              }}
                              alt="logo"
                              src={item.path}
                              style={{
                                cursor: "pointer",
                                width: "140px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        );
                      })}
                    </Stack>
                  </Stack>
                )}

                <Stack gap="12px" width="100%">
                  {proposalDetails?.milestones && (
                    <>
                      <Stack direction="row" gap="4px" width="100%">
                        {Object.keys(proposalDetails?.milestones).length >
                          0 && (
                          <Typography
                            className={classes.projectInformation}
                            padding="8px 16px"
                          >
                            Milestones
                            <span
                              style={{
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "4px 10px",
                                margin: "0px 8px",
                                backgroundColor: "#E9B55C",
                                borderRadius: 22,
                              }}
                            >
                              {Object.keys(proposalDetails?.milestones).length}
                            </span>
                          </Typography>
                        )}
                      </Stack>
                      {Object.keys(proposalDetails?.milestones).length > 1 && (
                        <Divider />
                      )}
                      <Stack width="100%" gap="8px">
                        {Object.keys(proposalDetails?.milestones).map((key) => {
                          const milestone = proposalDetails?.milestones[key];
                          return (
                            <SingleMilestoneAccordion
                              milestone={milestone}
                              key={key}
                              isEditScreen={true}
                              id={key}
                            >
                              <Stack width="100%">
                                {Object.keys(proposalDetails?.budgets).map(
                                  (budgetKey) => {
                                    const budget =
                                      proposalDetails?.budgets[budgetKey];

                                    if (key == budget.milestone_id) {
                                      return (
                                        <Stack padding="16px">
                                          <SingleBudgetAccordion
                                            budget={budget}
                                            index={budgetKey}
                                          />
                                        </Stack>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </Stack>
                            </SingleMilestoneAccordion>
                          );
                        })}
                      </Stack>
                    </>
                  )}
                  {proposalDetails?.payment_group && (
                    <Stack width="100%" gap="12px">
                      {Object.keys(proposalDetails?.payment_group).length >
                        0 && (
                        <Typography
                          className={classes.projectInformation}
                          padding="8px 16px"
                        >
                          Payment Groups
                          <span
                            style={{
                              fontSize: "12px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "4px 10px",
                              margin: "0px 8px",
                              backgroundColor: "#E9B55C",
                              borderRadius: 22,
                            }}
                          >
                            {Object.keys(proposalDetails?.payment_group).length}
                          </span>
                        </Typography>
                      )}

                      {villa?.proposal_status === "awaiting-approval" &&
                        Object.keys(proposalDetails.payment_group).map(
                          (key) => {
                            const pGroup = proposalDetails.payment_group[key];
                            if (!isEmpty(pGroup.group)) {
                              return (
                                <MilestonePaymentGroup
                                  group={pGroup.group}
                                  groupName={pGroup.groupName}
                                  dueDate={pGroup.dueDate}
                                  amount={pGroup.amount}
                                  groupId={key}
                                  isExpandable
                                />
                              );
                            } else {
                              return (
                                <MilestonePaymentGroup
                                  group={pGroup.group}
                                  groupName={pGroup.groupName}
                                  dueDate={pGroup.dueDate}
                                  amount={pGroup.amount}
                                  groupId={key}
                                  status
                                />
                              );
                            }
                          }
                        )}

                      {villa?.proposal_status !== "awaiting-approval" &&
                        Object.keys(proposalDetails.payment_group).map(
                          (key) => {
                            const pGroup = proposalDetails.payment_group[key];
                            if (!isEmpty(pGroup.group)) {
                              return (
                                <MilestonePaymentGroup
                                  groupId={key}
                                  group={pGroup.group}
                                  groupName={pGroup.groupName}
                                  dueDate={pGroup.dueDate}
                                  amount={pGroup.amount}
                                  isExpandable
                                  isRequestedProposal
                                />
                              );
                            } else {
                              return (
                                <MilestonePaymentGroup
                                  groupId={key}
                                  group={pGroup.group}
                                  groupName={pGroup.groupName}
                                  dueDate={pGroup.dueDate}
                                  amount={pGroup.amount}
                                  isRequestedProposal
                                />
                              );
                            }
                          }
                        )}
                    </Stack>
                  )}
                </Stack>

                <div className="MainContainer" style={{ marginBottom: "30px" }}>
                  <Typography
                    className={classes.projectInformation}
                    padding="8px 16px"
                  >
                    Look and Feel
                  </Typography>
                  <div>
                    <UploadPdf
                      projectId={villa?.id}
                      setIsCalled={setIsCalled}
                    />
                    <ProposalList
                      arr={villa?.proposal_files}
                      setIsCalled={setIsCalled}
                    />
                  </div>
                </div>

                {villa?.status !== "submitted" && (
                  <Stack gap="16px">
                    <Typography
                      className={classes.projectInformation}
                      sx={{ backgroundColor: "#F3F4F9" }}
                    >
                      Contract Details
                    </Typography>
                    <Stack gap="8px" width="100%">
                      <Grid container gridTemplateColumns="3">
                        <Grid item lg={4} md={3}>
                          <Typography className={classes.informationCard}>
                            Initial Deposit Need By contractor(%)
                          </Typography>
                          <Typography className={classes.value}>
                            {
                              villa?.project_fees
                                ?.Initial_deposit_needed_by_contractor
                            }
                          </Typography>
                        </Grid>
                        <Grid item lg={4} md={3}>
                          <Typography className={classes.informationCard}>
                            Warranty withheld amount(%)
                          </Typography>
                          <Typography className={classes.value}>
                            {
                              villa?.project_fees
                                ?.completion_of_the_warranty_period
                            }
                          </Typography>
                        </Grid>
                        <Grid item lg={4} md={3}>
                          <Typography className={classes.informationCard}>
                            Delay Guarantee(%)
                          </Typography>
                          <Typography className={classes.value}>
                            {villa?.project_fees?.project_completion}
                          </Typography>
                        </Grid>

                        <Grid item lg={4} md={3}>
                          <Typography className={classes.informationCard}>
                            Delay withheld amount(%)
                          </Typography>
                          <Typography className={classes.value}>
                            {villa?.project_fees?.deposit_for_delay_guarantees}
                          </Typography>
                        </Grid>

                        <Grid item lg={4} md={3}>
                          <Typography className={classes.informationCard}>
                            Warranty Period
                          </Typography>
                          <Typography className={classes.value}>
                            {villa?.project_fees?.warranty_period}{" "}
                            {villa?.project_fees?.warranty_period_unit
                              ?.toLowerCase()
                              ?.replace(/^\w/, (c) => c.toUpperCase())}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      {contractorFiles.length > 0 && (
                        <Stack gap="8px">
                          <Typography className={classes.value}>
                            Contract Files
                          </Typography>
                          <Stack
                            width="100%"
                            direction="row"
                            gap="16px"
                            padding="0px 12px"
                          >
                            {contractorFiles.map((item) => {
                              return (
                                <img
                                  src={item.path}
                                  alt="contractor file"
                                  style={{
                                    width: "140px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              );
                            })}
                          </Stack>
                        </Stack>
                      )}
                      {contractorFiles.length > 1 && <Divider />}
                      {villa?.notes?.length > 0 && (
                        <Stack gap="8px">
                          <Typography className={classes.value}>
                            Additional Info
                          </Typography>
                          {renoNotes.length > 0 && (
                            <Stack
                              padding="0px 12px"
                              alignItems="start"
                              flexDirection="row"
                              gap="8px"
                            >
                              <Avatar
                                alt="profile pic"
                                sx={{ border: "1px solid #cacfdb" }}
                              >
                                A
                              </Avatar>
                              <Stack flex={1} gap="8px">
                                {renoNotes?.map((note) => {
                                  return (
                                    <div
                                      style={{
                                        width: "fit-content",
                                        maxWidth: "75%",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Stack
                                        justifyContent="center"
                                        className={classes.notesContainer}
                                      >
                                        <Typography>{note.note}</Typography>
                                      </Stack>
                                    </div>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          )}
                          {contractorNotes.length > 0 && (
                            <Stack
                              padding="0px 12px"
                              alignItems="start"
                              flexDirection="row-reverse"
                              gap="8px"
                            >
                              <Avatar
                                src={villa?.Contractor?.profile_url}
                                sx={{ border: "1px solid #cacfdb" }}
                              />
                              <Stack flex={1} gap="8px" alignItems="end">
                                {contractorNotes?.map((note) => {
                                  return (
                                    <div
                                      style={{
                                        width: "fit-content",
                                        maxWidth: "75%",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Stack
                                        justifyContent="center"
                                        className={classes.notesContainer}
                                      >
                                        <Typography>{note.note}</Typography>
                                      </Stack>
                                    </div>
                                  );
                                })}
                              </Stack>
                            </Stack>
                          )}
                        </Stack>
                      )}

                      {villa?.notes?.length > 0 && <Divider />}
                    </Stack>
                  </Stack>
                )}
                {villa?.proposal_status ===
                  "awaiting-contractor-confirmation" && (
                  <Stack direction="row" justifyContent="end" gap="16px">
                    <Button
                      variant="outlined"
                      onClick={() => setIsRejected(true)}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setIsApproved(true)}
                    >
                      Approve
                    </Button>
                  </Stack>
                )}
              </>

              {!isSubmitted && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  gap="8px"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      navigate("/create-proposal", {
                        state: {
                          villa,
                          fromManageProject,
                          isUpdateDraftProposal: true,
                        },
                      });
                    }}
                  >
                    Submit Proposal
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        style={{
          position: "fixed",
          bottom: 200,
          left: "90%",
          display: "flex",
          justifyContent: "end",
          padding: 5,
        }}
      ></Grid>
      <Modal
        open={isChatStarted}
        onClose={() => setIsChatStarted(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <ConversationsWithMessagesWrapper isMobileView={true} />
        </Box>
      </Modal>
      {/* <BlueAbout /> */}
      <ImageViewer
        url={imgurl}
        visible={isPressed}
        isPdf={url}
        onClose={() => {
          setIsPressed(false);
        }}
      />

      {isOpen && (
        <Modal
          open={isOpen}
          onClose={handleClose}
          closeAfterTransition
          disableAutoFocus
          slotProps={{ backdrop: Backdrop }}
        >
          <Box sx={modalStyle}>
            <Stack direction="row" justifyContent="space-between">
              <Tabs
                value={tabValue}
                variant="scrollable"
                onChange={(v, b) => {
                  setTabValue(b);
                }}
              >
                <Tab label="Summary" />
                <Tab label="Milestones" />
                <Tab label="Budget" />
                <Tab label="Details" />
              </Tabs>
              <Stack width="32px" height="32px" sx={{ cursor: "pointer" }}>
                <img
                  src={Images.close}
                  onClick={handleClose}
                  alt="close"
                  style={{ width: "100%", height: "100%" }}
                ></img>
              </Stack>
            </Stack>
            {tabValue === 0 ? (
              <SubmittedSummary
                villa={villa}
                handleSetTabValue={handleSetTabValue}
              />
            ) : null}
            {tabValue === 1 ? (
              <SubmittedMilestone
                villa={villa}
                handleSetTabValue={handleSetTabValue}
              />
            ) : null}

            {tabValue === 2 ? (
              <SubmittedBudget
                villa={villa}
                handleSetTabValue={handleSetTabValue}
              />
            ) : null}
            {tabValue === 3 ? (
              <SubmittedDetails
                villa={villa}
                handleSetTabValue={handleSetTabValue}
                handleClose={handleClose}
              />
            ) : null}
          </Box>
        </Modal>
      )}
      {isRejected && (
        <Modal
          open={isRejected}
          onClose={() => setIsRejected(false)}
          closeAfterTransition
          disableAutoFocus
          slotProps={{ backdrop: Backdrop }}
        >
          <Box sx={rejectModal}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className={classes.heading}>
                Additional Info
              </Typography>
              <Stack width="32px" height="32px" sx={{ cursor: "pointer" }}>
                <img
                  src={Images.close}
                  onClick={() => setIsRejected(false)}
                  alt="close"
                  style={{ width: "100%", height: "100%" }}
                ></img>
              </Stack>
            </Stack>
            <Divider />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography className={classes.label}>Notes</Typography>
              <Button
                variant="contained"
                onClick={handleAddNote}
                style={{ alignSelf: "center", gap: "4px" }}
              >
                Add Note
                <AddCircleOutline />
              </Button>
            </Stack>
            <Stack alignItems={"center"} flex={1} overflow="auto" gap="16px">
              {notes.map((item, index) => {
                return (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <CInput
                      label={`Note ${index + 1}`}
                      value={item?.note}
                      multiline
                      onChange={(e) => handleNoteChange(index, e.target.value)}
                    />
                    {notes.length > 1 && (
                      <DeleteOutline
                        onClick={() => handleDeleteNotes(index)}
                        sx={{ cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              })}
            </Stack>
            <Stack direction="row" gap="16px" alignSelf="end">
              <Button variant="outlined" onClick={() => setIsRejected(false)}>
                Close
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                endIcon={
                  submitLoader && <CircularProgress color="white" size={18} />
                }
              >
                Submit
              </Button>
            </Stack>
          </Box>
        </Modal>
      )}

      <ConfirmModel
        visible={isApproved}
        handleClose={() => setIsApproved(false)}
        confirmation={handleApproveProject}
        message="Are you sure you want to approve the project"
      />
      <EditProposal villa={villa} isOpen={isOpen} handleClose={handleClose} />
    </div>
  );
}
