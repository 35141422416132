import React from "react";
import { Stack, Box, Typography, Grid } from "@mui/material";
import { isEmpty } from "lodash";

const HomeOwnerSummary = ({ proposalDetails, classes }) => {
  const isRoomsAndHasData =
    proposalDetails?.project_type === "Rooms" &&
    proposalDetails?.form_json &&
    Object.keys(proposalDetails.form_json).length > 0;
  return (
    <>
    {console.log("proposalDetails ----",proposalDetails?.form_json)}
      {(proposalDetails?.dimensions || isRoomsAndHasData) && (
        <Stack
          sx={{
            marginBottom: "2vh",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: ".5vh",
          }}
        >
          <Typography
            className={classes.projectInformation}
            sx={{
              backgroundColor: "#F3F4F9",
              width: "fit-content",
              padding: ".5vh 1vw !important",
            }}
          >
            Project Size
          </Typography>
          <Grid container spacing={2} >
            {proposalDetails?.project_type === "Rooms" && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography className={classes.informationCard}>
                  Size
                </Typography>
                <Typography className={classes.value}>
                  {proposalDetails?.form_json?.appliances?.no_of_rooms},{" "}
                  {proposalDetails?.form_json?.appliances?.size}
                </Typography>
              </Grid>
            )}
            {proposalDetails?.project_type === "Bathroom" && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography className={classes.informationCard}>
                  New Layout
                </Typography>
                <Typography className={classes.value}>
                  {proposalDetails?.form_json?.layout &&
                    proposalDetails?.form_json?.layout}
                </Typography>
              </Grid>
            )}
            {proposalDetails?.dimensions &&
              proposalDetails?.dimensions !== null &&
              Object?.entries(proposalDetails?.dimensions)?.map(
                ([key, value]) => (
                  <Grid item xs={12} sm={6} md={5} lg={3} key={key}>
                    <Typography className={classes.informationCard}>
                      {`${key
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}: `}
                    </Typography>
                    <Typography className={classes.value}>{value}</Typography>
                  </Grid>
                )
              )}
          </Grid>
        </Stack>
      )}
      {proposalDetails?.form_json && (
        <>
          {Object.entries(proposalDetails?.form_json).map(([key, value]) => {
            if (
              key.endsWith("Summary") &&
              value.data?.appliances &&
              !isEmpty(value?.data?.appliances)
            ) {
              return (
                <Box
                  key={key}
                  sx={{
                    marginBottom: 3,
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    paddingBottom: ".5vh",
                  }}
                >
                  <Typography
                    className={classes.projectInformation}
                    sx={{
                      backgroundColor: "#F3F4F9",
                      width: "fit-content !important",
                      padding: ".5vh 1vw !important",
                    }}
                  >
                    Inclusion
                  </Typography>
                  <Typography
                    className={classes.informationCard}
                    style={{ padding: "1.5vh 1vw" }}
                  >
                    {!isEmpty(value?.data?.appliances) ? value?.data?.appliances.join(", ") : "---"}
                  </Typography>
                </Box>
              );
            }
            return null;
          })}

          {Object.entries(proposalDetails?.form_json).map(([key, value]) => {
            if (key.endsWith("Summary") && value.data?.includedRenovations) {
              return (
                <>
                  {value?.data?.includedRenovations && (
                    <Box key={key} sx={{ marginBottom: 1 }}>
                      <Typography
                        className={classes.projectInformation}
                        sx={{
                          backgroundColor: "#F3F4F9",
                          width: "fit-content",
                          padding: ".5vh 1vw !important",
                        }}
                      >
                        Things to be included in Renovation
                      </Typography>
                      <Typography
                        className={classes.informationCard}
                        style={{ padding: "1.5vh 1vw" }}
                      >
                        {value?.data?.includedRenovations.join(", ")}
                      </Typography>
                    </Box>
                  )}
                </>
              );
            }
            return null;
          })}
        </>
      )}
    </>
  );
};

export default HomeOwnerSummary;
