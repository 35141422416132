import { makeStyles } from "@mui/styles";
import { color } from "../../../config/theme";

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    width: "100%",
    gap: "20px",
    marginTop: "20px",
    overflowY: "auto",
  },
  changelogItem: {
    width: "100%",
    paddingTop: "12px",
    paddingBottom: "12px",
    border: "1px solid #CBD1DB",
    borderRadius: "14px",
  },
  titleContainer: {
    flexGrow: 1,
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    color: "#222427",
    display: "flex",
    justifyContent: "space-between",
  },
  titleItem: {
    marginRight: 0,
    minWidth: "14ch",
  },
  noValueWidth: {
    minWidth: "6ch",
  },
  valueWidth: {
    minWidth: "14ch",
  },
  summaryContainer: {
    alignItems: "center",
    width: "100%",
    padding: "12px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
    width: "100%",
  },
  actionContainer: {
    display: "flex",
    gap: "15px",
  },
  approveContainer: {
    width: "10ch",
    marginLeft: 16,
  },
  contentContainer: {
    display: "flex",
    gap: "15px",
    padding: "12px",
    minHeight: 150,
  },
  footerContainer: {
    display: "flex",
    gap: "15px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "12px",
  },
  statusContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  statusStart: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statusEnd: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statusKey: {
    fontFamily: "Inter Tight !important",
    color: "#222427",
  },
  status: {
    fontFamily: "Inter Tight !important",
    color: "#222427",
  },
  budgetItem: {
    width: "100%",
    border: "1px solid #CBD1DB",
    borderRadius: "14px",
  },
  budgetContainer: {
    display: "flex",
    gap: "15px",
    background: color.LightSurface,
    borderRadius: "14px",
    padding: "12px",
  },
  budgetSummaryContainerNew: {
    alignItems: "center",
    width: "100%",
    padding: "12px",
    background: color.bg.lightGreen,
    borderRadius: "14px",
  },
  budgetSummaryContainerOld: {
    alignItems: "center",
    width: "100%",
    padding: "12px",
    background: color.bg.lightRed,
    borderRadius: "14px",
  },
  budgetSummaryContainer: {
    alignItems: "center",
    width: "100%",
    padding: "12px",
    borderRadius: "14px",
  },
  budgetContentContainer: {
    padding: "12px",
  },
  budgetTitleContainerNew: {
    flexGrow: 1,
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    color: color.green,
  },
  budgetTitleContainer: {
    flexGrow: 1,
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    display: "flex",
    justifyContent: "space-between",
  },
  budgetTitleContainerOld: {
    flexGrow: 1,
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    color: color.red,
  },
  sectionTitle: {
    fontSize: "15px !important",
    fontFamily: "Inter Tight !important",
  },
  key: {
    fontFamily: "Inter Tight !important",
    color: "#222427",
  },
  value: {
    fontFamily: "Inter Tight !important",
  },
  customtable: {
    borderCollapse: "collapse",
    "& td": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      textAlign: "center",
      border: "none !important",
      background: "transparent !important",
      width: 120,
      padding: "4px 0px",
    },
    "& th": {
      color: color.LightSurface,
    },
  },
  leftAlign: {
    borderCollapse: "collapse",
    "& td": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      textAlign: "left",
      border: "none !important",
      background: "transparent !important",
      padding: "4px 0px",
      paddingLeft:".7vw !important"
    },
    "& th": {
      color: color.LightSurface,
    },
  },
  description: {
    fontFamily: "Inter Tight !important",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    wordBreak: "break-all",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  }
}));

export default useStyles;
