import { Box, Stack, Tab, Tabs, Modal, Backdrop } from "@mui/material";
import { useState, createContext, useContext, useEffect } from "react";
import Details from "./Details";
import Inclusion from "./Inclusion";
import Summary from "./Summary";
import PackageImages from "./PackageImages";

const PackageContext = createContext();

const EditPopup = ({ isOpen, handleClose, details , tabValue, setTabValue}) => {
  const [packageDetails, setPackageDetails] = useState(details);
  console.log("dea", details);

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    bgcolor: "background.paper",
    padding: "10px 32px 10px 32px !important",
    overflow: "hidden",
    borderRadius: "4px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "90%",
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) {
      console.log("Package Details on Inclusion tab:", packageDetails);
    }
    setTabValue(newValue);
  };

  useEffect(()=>{
    setTabValue(0);
  },[])

  const renderSwitch = () => {
    switch (tabValue) {
      case 0:
        return <Details setTabValue={setTabValue} handleClose={handleClose} />;
      case 1:
        return (
          <Inclusion
            setTabValue={setTabValue}
            tabValue={tabValue}
            handleModalClose={handleClose}
          />
        );
      case 2:
        return (
          <PackageImages
            setTabValue={setTabValue}
            tabValue={tabValue}
            handleClose={handleClose}
          />
        );
      case 3:
        return <Summary handleClose={handleClose} />;
      default:
        return null;
    }
  };

  return (
    <PackageContext.Provider value={{ packageDetails, setPackageDetails }}>
      <Modal
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        disableAutoFocus
        slotProps={{ backdrop: Backdrop }}
      >
        <Box sx={modalStyle}>
          <Stack direction="row" justifyContent="space-between">
            <Tabs
              value={tabValue}
              variant="scrollable"
              onChange={handleTabChange}
            >
              <Tab label="Details" />
              <Tab
                label="Inclusion"
                disabled={
                  !(packageDetails && Object.keys(packageDetails).length > 0)
                }
              />
              <Tab label="Images" disabled={!packageDetails?.inclusions} />
              <Tab
                label="Summary"
                disabled={
                  !packageDetails?.inclusions || !packageDetails?.images
                }
              />
            </Tabs>
          </Stack>
          {renderSwitch()}
        </Box>
      </Modal>
    </PackageContext.Provider>
  );
};

export default EditPopup;

export const usePackageContext = () => useContext(PackageContext);
