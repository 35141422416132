import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.css";
import { CircularProgress, Divider, Stack } from "@mui/material";
import { getApiData } from "../../utils/APIHelper";
import { ChevronRight } from "@mui/icons-material";
import Images from "../../config/images";
import BlueAbout from "../../components/BlueAbout/index";
import {
  formattedAmount,
  formattedAmountWithoutAed,
} from "../../utils/CommonFunction";
import { useSelector } from "react-redux";
import moment from "moment";

const Status = ({ status }) => {
  const statusStyles = {
    delivered: {
      backgroundColor: "rgb(204, 238, 233)",
      color: "rgb(3, 77, 3)",
    },
    PAID: {
      backgroundColor: "rgb(235, 250, 221)",
      color: "#389e0d",
    },
    INITIATED: {
      backgroundColor: "#e6f7ff",
      color: "#096dd9",
    },
    "Not Started": {
      backgroundColor: "rgba(234, 229, 229, 0.82)",
      color: "#444",
    },
    default: {
      backgroundColor: "#FFF3DF",
      color: "#000",
    },
  };

  const { backgroundColor, color } =
    statusStyles[status] || statusStyles.default;

  return (
    <p
      style={{
        display: "inline-block",
        padding: "5px 10px",
        margin: "5px",
        borderRadius: "4px",
        fontFamily: "Inter Tight",
        textTransform: "capitalize",
        backgroundColor,
        color,
      }}
    >
      {status?.toLowerCase()?.replace(/^\w/, (c) => c.toUpperCase())}
    </p>
  );
};

function Details(props) {
  const { project } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Stack>
      <Stack
        padding="16px 0"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setOpen(!open)}
        style={{ cursor: "pointer" }}
      >
        <Stack flex={1} direction="row" alignItems="center">
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowDownIcon /> : <ChevronRight />}
          </IconButton>
          <div style={{ width: "24px", height: "24px", marginRight: "8px" }}>
            <img src={Images.file} alt="file"></img>
          </div>
          <Typography
            fontSize="15px"
            fontFamily="Inter Tight"
            textTransform="capitalize"
          >
            {project?.name}
          </Typography>
        </Stack>

        <Stack flex={1.5} direction="row" gap="30px" alignItems="center">
          <div style={{ flex: ".5" }}>
            <Typography
              fontSize="13px"
              fontFamily="Inter Tight"
              color="#878F9C"
            >
              Project ID
            </Typography>
            <Typography
              fontSize="15px"
              fontFamily="Inter Tight"
              color="#202929"
            >
              {project?.id}
            </Typography>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography
              fontSize="13px"
              fontFamily="Inter Tight"
              color="#878F9C"
            >
              Total Paid
            </Typography>
            <Typography
              fontSize="15px"
              fontFamily="Inter Tight"
              color="#202929"
            >
              {formattedAmount(project?.totalPaidAmount)}
            </Typography>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography
              fontSize="13px"
              fontFamily="Inter Tight"
              color="#878F9C"
            >
              Total Project Value
            </Typography>
            <Typography
              fontSize="15px"
              fontFamily="Inter Tight"
              color="#202929"
            >
              {formattedAmount(project?.totalCost)}
            </Typography>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Typography
              fontSize="13px"
              fontFamily="Inter Tight"
              color="#878F9C"
            >
              Current Balance
            </Typography>
            <Typography
              fontSize="15px"
              fontFamily="Inter Tight"
              color="#202929"
              justifyContent="flex-end"
            >
              {formattedAmount(project?.balance)}
            </Typography>
          </div>
        </Stack>
      </Stack>
      <Divider />

      <Stack>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: "24px" }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow
                  style={{
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    backgroundColor: "#F9FBFF",
                    height: "56px",
                  }}
                >
                  <TableCell className="balancedetailsValue">
                    Payment Group Name
                  </TableCell>
                  <TableCell className="balancedetailsValue">
                    Payout Status
                  </TableCell>

                  <TableCell className="balancedetailsValue">
                    Settled On
                  </TableCell>
                  <TableCell className="balancedetailsValue">Mode</TableCell>
                  <TableCell className="balancedetailsValue">
                    Payment Group Amount (AED)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {project?.payouts?.map((item, idx) => (
                  <TableRow
                    key={idx}
                    style={{
                      borderBottom: "unset !important",
                    }}
                  >
                    <TableCell
                      className="milestone-value"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item?.groupName}
                    </TableCell>
                    <TableCell className="milestone-value">
                      {item?.status ? <Status status={item?.status} /> : "NA"}
                    </TableCell>
                    <TableCell className="milestone-value">
                      {item?.releaseDate &&
                        moment(item?.releaseDate).format("MMM DD, YYYY")}
                    </TableCell>
                    <TableCell className="milestone-value">
                      {item?.settlementType
                        ?.toLowerCase()
                        ?.replace(/^\w/, (c) => c.toUpperCase())}
                    </TableCell>
                    <TableCell className="milestone-amount">
                      {formattedAmountWithoutAed(item?.amount)}
                    </TableCell>{" "}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </Stack>
    </Stack>
  );
}

export default function BalanceDetails() {
  const { userData } = useSelector((state) => state.auth);

  const [totalBalance, setTotalBalance] = useState(0);
  const [projectDetails, setProjectDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      userData?.contractor_data?.id &&
      userData?.contractor_data?.id !== undefined
    ) {
      setLoading(true);
      getBalanceSheetDetails(userData?.contractor_data?.id);
    }
  }, [userData?.contractor_data?.id]);

  async function getBalanceSheetDetails(contractorId) {
    console.log("Contractor ID:", contractorId);
    try {
      const response = await getApiData(
        `/api/v2/contractors/${contractorId}/balanceSheet`,
        "GET",
        {},
        {},
        true
      );

      setProjectDetails(response?.projectBalanceSheet);
      setTotalBalance(response?.totalBalance);
    } catch (error) {
      console.log("Error fetching balance sheet details:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Stack flex={1}>
      <div>
        <Stack className="tableContainer">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="total-balance"
            bgcolor="#F3F4F9"
            padding="16px"
          >
            <h3
              style={{
                fontWeight: "500",
              }}
            >
              Total Balance
            </h3>
            <h2>{formattedAmount(totalBalance)}</h2>
          </Stack>
          <Stack flex={1} position="relative">
            {loading ? (
              <CircularProgress
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              />
            ) : (
              <Box
                sx={{
                  height: "85vh",
                  overflowY: "scroll",
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Edge
                  },
                }}
              >
                {projectDetails?.length > 0 ? (
                  projectDetails?.map((project) => (
                    <Details key={project.id} project={project} />
                  ))
                ) : (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    flex={1}
                    border="1px dashed #B1B1B1"
                  >
                    <Typography fontFamily="Inter Tight" fontSize="16px">
                      No records to display
                    </Typography>
                  </Stack>
                )}
              </Box>
            )}
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
}
