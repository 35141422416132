import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Button,
  styled,
} from "@mui/material";
import tabs from "./config";
import { getApiData } from "../../utils/APIHelper";
import { useNavigate } from "react-router-dom";
import ProposalDetails from "./components/ProposalDetails";
import authActions from "../../redux/reducers/auth/actions";
import { useDispatch } from "react-redux";

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

const StyledButton = styled(Button)(({ theme }) => ({
  background: "#4D6BF6",
  color: "#ffffff",
  boxShadow: "none",
  border: "none",
  whiteSpace: "nowrap",
  fontSize: "16px",
  fontWeight: "800",
  borderRadius: "10px",
  padding: ".5vh .7vw",
  letterSpacing: "0",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  marginTop: "2vh",
}));

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  color: selected ? "#fff !important" : "#949DAA",
  backgroundColor: selected ? "#0d0d0d" : "#fff",
  border: "1px solid #d7d8d9",
  borderRadius: "10px",
  minHeight: "40px !important",
  fontWeight: 500,
  textTransform: "none",
  marginRight: "10px",
  padding: "4px 16px !important",
  fontFamily: "Inter Tight",
  "&:hover": {
    backgroundColor: selected ? "#0d0d0d" : "",
    color: !selected && "#000",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent !important",
  },
}));

export default function Proposals() {
  const { resetProposalDetails } = authActions;
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setDetails([]);
  };

  const fetchData = async () => {
    setLoading(true);
    const selectedType = tabs[selectedTab]?.type;
    let url = "";
    if (selectedTab === 0) {
      url = "api/project/proposal-drafts-list";
    } else {
      url = `api/project/list-contractor-project?status=${selectedType}`;
    }
    try {
      const response = await getApiData(`${url}`, "GET", {}, {}, false);
      const newDetails = response?.data || [];
      setDetails(newDetails);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedTab]);

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          typography: "body1",
          backgroundColor: "#fff",
          height: "100%",
          padding: "4vh 2vw",
          borderRadius: "6px",
          fontFamily: "Inter Tight",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1vh",
          }}
        >
          <StyledHeading>Proposals</StyledHeading>
          <StyledButton
            variant="contained"
            onClick={() => {
              dispatch(resetProposalDetails());
              navigate("/create-proposal", {
                state: {
                  isCreateProposal: true,
                  isUpdateDraftProposal: false,
                  isDraft: true,
                },
              });
            }}
          >
            <strong>Create Proposal</strong>
          </StyledButton>
        </div>
        <StyledTabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Styled Tabs"
          sx={{
            "& .MuiTabs-indicator": {
              background: "none",
            },
          }}
        >
          {tabs?.map((tab, index) => (
            <StyledTab
              key={tab.id}
              label={tab.title}
              selected={selectedTab === index}
            />
          ))}
        </StyledTabs>
        <Box sx={{ paddingTop: 2 }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : details?.length > 0 ? (
            <ProposalDetails details={details} selectedTab={selectedTab} />
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", marginTop: 4 }}
            >
              No data available
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
}
