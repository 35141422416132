import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useStyles from "../styles";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTheme } from "@emotion/react";
import {
  formattedAmount,
  transformDateFormat,
} from "../../../../../utils/CommonFunction";
import Images from "../../../../../config/images";
import SingleMilestoneAccordion from "../../../../../components/SingleMilestoneAccordian";

const MilestonePaymentGroup = ({
  groupName,
  dueDate,
  group,
  amount,
  handleEditPaymentGroup,
  handleDelete,
  isRequestedProposal,
  isExpandable,
  status,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const handleChangeExpanded = () => () => {
    setExpanded((prev) => !prev);
  };
  // Function to determine the source of milestones
  const getMilestones = () => group?.milestones || group;
  const milestones = getMilestones();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack className={classes.groupContainer}>
      <Accordion
        style={{
          boxShadow: "none",
          borderRadius: "none",
        }}
        disableGutters
        onChange={handleChangeExpanded()}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            ".MuiAccordionSummary-content": {
              margin: 0,
            },
            "Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap="16px">
              <Typography className={classes.value}>{groupName}</Typography>
              {isExpandable && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#E9B55C",
                    fontWeight: "bold",
                    borderRadius: 22,
                    fontSize: 12,
                  }}
                >
                  {Object.keys(milestones).length}
                </span>
              )}
            </Stack>
            <Stack
              direction="row"
              gap={2}
              alignItems="center"
              justifyContent="flex-end"
              // width={amount > 0 ? (md ? "60%" : lg ? "40%" : "40%") : "25%"}
              width={md ? "60%" : lg ? "40%" : "40%"}

            >
              {dueDate ? (
                <Stack marginRight={status ? "34px" : "0px"}>
                  <Typography className={classes.label}>Due Date</Typography>
                  <Typography className={classes.value}>
                    {transformDateFormat(dueDate)}
                  </Typography>
                </Stack>
              ) : (
                <div></div>
              )}
                <Stack
                  alignItems="center"
                  marginRight={!isExpandable ? "34px" : "0px"}
                  flex={1}
                >
                  <Typography className={classes.label} textAlign="end">
                    Total Amount
                  </Typography>
                  <Typography className={classes.value} textAlign="end">
                    {amount > 0 ? formattedAmount(amount) : "-"}
                  </Typography>
                </Stack>
            
              {isExpandable && (
                <Stack>
                  {expanded ? (
                    <ExpandMoreIcon
                      style={{
                        display: !isExpandable ? "none" : "block",
                      }}
                    />
                  ) : (
                    <ExpandMoreIcon
                      sx={{
                        transform: "rotate(180deg)",
                        display: !isExpandable ? "none" : "block",
                      }}
                    />
                  )}
                </Stack>
              )}

              {handleEditPaymentGroup && (
                <img
                  src={Images.edit}
                  alt="edit-button"
                  onClick={handleEditPaymentGroup}
                />
              )}
              {handleDelete && (
                <DeleteOutlinedIcon
                  onClick={handleDelete}
                  sx={{ ":hover": { color: "red" } }}
                />
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        {isExpandable && (
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              gap: "8px",
            }}
          >
            <Divider sx={{ margin: "8px 0px" }} />
            {Object.keys(milestones).map((milestoneKey) => {
              return (
                <SingleMilestoneAccordion
                  milestone={milestones[milestoneKey]}
                  key={milestoneKey}
                  id={milestoneKey}
                  isEditScreen={true}
                />
              );
            })}
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};
export default MilestonePaymentGroup;
