export const tabs = [
  { id: "1", title: "Draft", type: "CREATED" },
  { id: "2", title: "Submitted", type: "SUBMITTED" },
  {
    id: "3",
    title: "Awaiting Approval",
    type: "awaiting-contractor-confirmation",
  },
  { id: "4", title: "Approved", type: "awaiting-project-pricing,approved" },
];

export default tabs;

export const statusMapPresetColor = {
  //budget type
  STANDARD: "orange",
  ECONOMIC: "blue",
  "HIGH END": "pink",
  "SUPER HIGH END": "green",

  //package status
  CREATED: "orange",
  SUBMITTED: "blue",
  "awaiting-contractor-confirmation": "yellow",
  "awaiting-project-pricing": "orange",
  approved: "green",
};

export const statusStyleArray = [
  {
    status: "STANDARD",
    color: "#d46b08",
    backgroundColor: "#fff7e6",
  },
  {
    status: "ECONOMIC",
    color: "#096dd9",
    backgroundColor: "#e6f7ff",
  },
  {
    status: "HIGH END",
    color: "#c41d7f",
    backgroundColor: "#fff0f6",
  },
  {
    status: "SUPER HIGH END",
    color: "#389e0d",
    backgroundColor: "#f6ffed",
  },
  {
    status: "SUBMITTED",
    backgroundColor: "#096dd9",
    color: "#e6f7ff",
  },
  {
    status: "awaiting-contractor-confirmation",
    color: "#d4b106",
    backgroundColor: "#feffe6",
  },
  {
    status: "awaiting-project-pricing",
    backgroundColor: "#d46b08",
    color: "#fff7e6",
  },
  {
    status: "approved",
    color: "#fff",
    backgroundColor: "rgb(56, 158, 13)",
  },
  {
    status: "CREATED",
    backgroundColor: "#c41d7f",
    color: "#fff0f6",
  },
];
