import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getApiData, postFormData } from "../../../../utils/APIHelper";
import { toast } from "react-toastify";
import EditVariant from "./EditVariant";
import { Setting } from "../../../../utils/Setting";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CInput from "../../../../components/CInput";
import { styled } from "@mui/system";

const AddVariantButton = styled(Button)({
  display: "flex",
  alignItems: "center",
  gap: "2px",
  marginBottom: "12px",
  fontSize: "14px",
});

const VariantScroll = ({ budget, getDetailsApiCall, isSummary }) => {
  // const handleDeleteVariant = async (variant) => {
  //   try {
  //     const response = await getApiData(
  //       `api/v2/boq/${variant?.parentId}/variant/${variant?.id}`,
  //       "DELETE",
  //       {},
  //       { "Content-Type": "application/json" },
  //       true
  //     );

  //     toast.success(response?.message || "Varaint Deleted Succesfully");
  //     getDetailsApiCall();
  //   } catch (error) {
  //     toast.error(error.toString());
  //   }
  // };

  const [variants, setVariants] = useState([
    {
      name: "",
      description: "",
      included: true,
      image: null,
      isSaved: false,
      id: "",
    },
  ]);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleAddVariant = () => {
    if (
      variants[variants.length - 1].name &&
      variants[variants.length - 1].image
    ) {
      setVariants([
        ...variants,
        {
          name: "",
          description: "",
          included: true,
          image: null,
          isSaved: false,
          id: "",
        },
      ]);
    } else {
      toast.error("Complete the existing variant first");
    }
  };

  const handleNameChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].name = value;
    setVariants(updatedVariants);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index].description = value;
    setVariants(updatedVariants);
  };

  const handleImageChange = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file_1", file);
      formData.append("type", "boq_variant");
      formData.append("tags", "");

      try {
        const response = await postFormData(
          Setting.endpoints.packageFiles,
          formData
        );
        if (response.success) {
          toast.success("File uploaded successfully");
          const updatedVariants = [...variants];
          updatedVariants[index].image = response.images[0];
          setVariants(updatedVariants);
        } else {
          toast.error("File upload failed");
        }
      } catch (error) {
        toast.error("An error occurred during file upload");
      }
    }
  };

  const handleSaveVariant = async (index) => {
    const variant = variants[index];
    if (!variant.name || !variant.image) {
      toast.error("Please complete the variant before saving.");
      return;
    }

    setButtonLoader(true);
    const body = {
      name: variant.name,
      description: variant.description,
      included: true,
      imageMetadata: variant.image ? JSON.stringify(variant.image) : "",
    };

    try {
      const response = await getApiData(
        `api/v2/boq/${budget?.id}/variant`,
        "POST",
        body,
        { "Content-Type": "application/json" },
        true
      );
      if (response) {
        toast.success("Variant Added Successfully");
        getDetailsApiCall();
        const updatedVariants = [...variants];
        updatedVariants[index].isSaved = true;
        updatedVariants[index].id = response?.id;
        setVariants(updatedVariants);
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setButtonLoader(false);
    }
  };

  const handleDeleteImage = async (index) => {
    const variant = variants[index];
    setButtonLoader(true);
    try {
      const response = await getApiData(
        `api/project/delete-files/${variant.image.id}`,
        "POST",
        {},
        { "Content-Type": "application/json" },
        false
      );
      if (response.success) {
        toast.success(response.message);
        const updatedVariants = [...variants];
        updatedVariants[index].image = null;
        setVariants(updatedVariants);
      }
    } catch (error) {
      toast.error(error.toString());
    } finally {
      setButtonLoader(false);
    }
  };

  const handleDeleteVariant = async (variant, index) => {
    try {
      const response = await getApiData(
        `api/v2/boq/${budget?.id}/variant/${variant?.id}`,
        "DELETE",
        {},
        { "Content-Type": "application/json" },
        true
      );

      toast.success("Variant Deleted successfully");
      setVariants(variants.filter((_, i) => i !== index));
      getDetailsApiCall();
    } catch (error) {
      toast.error(error.toString());
    }
  };

  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexWrap: "wrap",
    //     rowGap: 1,
    //     columnGap: 1,
    //     padding: "1vh 0",
    //     justifyContent: "flex-start",
    //     fontFamily: "Inter Tight !important",
    //   }}
    // >
    //   {/* {budget?.variants?.map((variant) => (
    //     <Box
    //       key={variant.id}
    //       sx={{
    //         padding: "1vh 1vw",
    //         borderRadius: 2,
    //         boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    //         marginBottom: 1,
    //         display: "flex",
    //         gap: "20px",
    //         width: "48%",
    //       }}
    //     >
    //       <Box
    //         component="img"
    //         src={JSON.parse(variant.imageMetadata).image}
    //         alt={variant.name}
    //         sx={{
    //           width: "130px",
    //           height: "130px",
    //           borderRadius: 1,
    //           marginBottom: 1,
    //         }}
    //       />
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           gap: "5px",
    //           justifyContent: "flex-start",
    //           width: "100%",
    //         }}
    //       >
    //         <Box
    //           sx={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <Typography
    //             variant="subtitle1"
    //             noWrap
    //             sx={{
    //               fontWeight: "bold",
    //               color: "#000",
    //               letterSpacing: "0",
    //               fontSize: "18px",
    //               fontFamily: "Inter Tight !important",
    //               overflow: "hidden",
    //               textOverflow: "ellipsis",
    //               whiteSpace: "nowrap",
    //               maxWidth: "150px",
    //             }}
    //           >
    //             {variant.name}
    //           </Typography>
    //           {!isSummary && (
    //             <Box>
    //               <EditVariant
    //                 variant={variant}
    //                 getDetailsApiCall={getDetailsApiCall}
    //               />
    //               <IconButton
    //                 size="small"
    //                 onClick={() => handleDeleteVariant(variant)}
    //               >
    //                 <DeleteIcon fontSize="small" />
    //               </IconButton>
    //             </Box>
    //           )}
    //         </Box>

    //         <Typography
    //           variant="body2"
    //           color="text.secondary"
    //           sx={{
    //             display: "-webkit-box",
    //             WebkitLineClamp: 4,
    //             WebkitBoxOrient: "vertical",
    //             overflow: "hidden",
    //             textOverflow: "ellipsis",
    //             fontFamily: "Inter Tight !important",
    //             wordBreak: "break-all",
    //           }}
    //         >
    //           Description:&nbsp;{variant.description || "---"}
    //         </Typography>
    //       </div>
    //     </Box>
    //   ))} */}

    // </Box>
    <Grid container spacing={2} id="variants">
      {budget?.variants?.map((variant, index) => (
        <Grid
          key={index}
          container
          spacing={1}
          style={{
            backgroundColor: "#f7f7f7",
            padding: 8,
            borderRadius: 4,
            marginRight: "10px",
          }}
        >
          {console.log("variant", variant, budget?.variants)}
          <div style={{ marginRight: "1vw" }}>
            {variant.imageMetadata !== "" ? (
              <div style={{ position: "relative" }}>
                <img
                  src={JSON.parse(variant.imageMetadata).image}
                  alt="Uploaded BOQ"
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "2px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
                {!variant.isSaved && (
                  <IconButton
                    onClick={() => handleDeleteImage(index)}
                    style={{
                      position: "absolute",
                      top: "-6px",
                      right: "-6px",
                      padding: "4px",
                      backgroundColor: "white",
                      borderRadius: "50%",
                      border: "1px solid red",
                    }}
                  >
                    <CloseIcon style={{ fontSize: "12px", color: "red" }} />{" "}
                  </IconButton>
                )}
              </div>
            ) : (
              <IconButton
                component="label"
                style={{
                  width: "100px",
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  border: "1px dashed #ccc",
                  margin: "0",
                  cursor: "pointer",
                  background: "#fff",
                }}
                disabled={!!variant.image}
              >
                <AddIcon fontSize="small" />
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleImageChange(index, e)}
                  accept="image/*"
                />
              </IconButton>
            )}
          </div>
          <Grid item xs={12} md={4}>
            <CInput
              label="Name"
              placeholder="Enter Name"
              value={variant.name}
              onChange={(e) => handleNameChange(index, e.target.value)}
              isWhiteBackground
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CInput
              label="Description"
              placeholder="Enter description"
              value={variant.description}
              onChange={(e) => handleDescriptionChange(index, e.target.value)}
              isWhiteBackground
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              marginLeft: "20px",
            }}
          >
            <IconButton onClick={() => handleDeleteVariant(variant, index)}>
              <DeleteIcon style={{ color: "red" }} />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default VariantScroll;
