import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import authActions from "../../../../redux/reducers/auth/actions";

import _, { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { HighlightOffOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Setting } from "../../../../utils/Setting";
import Images from "../../../../config/images";
import { getAPIProgressData, getApiData } from "../../../../utils/APIHelper";
import theme from "../../../../config/theme";
import CAutocomplete from "../../../../components/CAutocomplete";
import CInput from "../../../../components/CInput";
import { useLocation } from "react-router-dom";
import HomeOwnerSummary from "../../HomeOwnerSummary";
import useStyles from "./styles";
const errorObj = {
  scpErr: false,
  scpMsg: "",
  typeErr: false,
  typeMsg: "",
  nameErr: false,
  nameMsg: "",
  cNameErr: false,
  cNameMsg: "",
  descriptionErr: false,
  descriptionMsg: "",
  emailErr: false,
  emailMsg: "",
  documentErr: false,
  documentMsg: "",
  startErr: false,
  startMsg: "",
  endErr: false,
  endMsg: "",
};

const EditSummary = ({ setTabValue }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setProposalDetails } = authActions;
  const { proposalDetails } = useSelector((state) => state.auth);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [villa, setVilla] = useState(location?.state?.villa);
  const [errObj, setErrObj] = useState(errorObj);
  const [expertiseList, setExpertiesList] = useState([]);
  const [projectType, setProjectType] = useState(null);
  const [name, setName] = useState("");
  const [scope, setScope] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [originalDoc, setOriginalDoc] = useState([]);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [updatedFiles, setUpdatedFiles] = useState([]);

  useEffect(() => {
    setScope(proposalDetails?.scope_of_work);
    setProjectType(proposalDetails?.project_type || "");
    setName(proposalDetails?.name || "");
    setDescription(proposalDetails?.description || "");
    setCustomerName(proposalDetails?.customer_name || "");
    setEmail(proposalDetails?.email || "");
    setOriginalDoc(proposalDetails?.project_image || []);
    getprojectList();
  }, [
    proposalDetails?.customer_name,
    proposalDetails?.description,
    proposalDetails?.email,
    proposalDetails?.name,
    proposalDetails.project,
    proposalDetails?.project_image,
    proposalDetails?.project_type,
    proposalDetails?.scope_of_work,
  ]);

  async function getprojectList() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.projectlist}`,
        "GET",
        {}
      );
      if (response.success) {
        if (isArray(response?.data) && !isEmpty(response?.data)) {
          setExpertiesList(response?.data);
        } else {
          setExpertiesList([]);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    // dimensions: JSON?.parse(dimensions) ?? "",
    //   form_json: JSON?.parse(form_json) ?? {},
    console.log("no", proposalDetails);
  }, [proposalDetails]);

  function checkImgSize(img) {
    let valid = true;
    if (img.size > 3145728) {
      valid = false;
    } else {
      valid = true;
    }
    return valid;
  }

  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  }

  async function UploadFile(img) {
    const nArr1 = originalDoc ? [...originalDoc] : [];
    const updatedImages = updatedFiles ? [...updatedFiles] : [];
    for (let i = 0; i < img.length; i++) {
      const base64Data = await convertToBase64(img[i]);
      nArr1.push(base64Data);
      updatedImages.push(base64Data);
    }
    setOriginalDoc(nArr1);
    setUpdatedFiles(updatedImages);

    setErrObj({
      ...errObj,
      photoErr: false,
      photoMsg: "",
    });
  }

  const convertBase64ToImageFile = (base64String, filename) => {
    const arr = base64String?.split(",");
    const mimeType = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const uint8Array = new Uint8Array(n);

    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    const file = new File([uint8Array], filename, { type: mimeType });
    return file;
  };

  const convertProjectToFiles = (data) => {
    const projectFiles = data?.map((base64String, index) => {
      const filename = `file_${index + 1}`;
      return convertBase64ToImageFile(base64String, filename);
    });
    return projectFiles;
  };

  async function updateproposalApicall(data) {
    setButtonLoader(true);
    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.updateProposal}/${villa?.proposal_id}`,
        "POST",
        data,
        true
      );
      if (response.success) {
        toast.success("Details Updated Successfully");
        setTabValue(1);
      } else {
        toast.error(response.message);
      }
      setButtonLoader("");
    } catch (error) {
      toast.error(error.toString());
      setButtonLoader("");
    }
  }

  const updateProjectFiles = async () => {
    const projectFiles = convertProjectToFiles(updatedFiles);
    if (projectFiles.length < 1) return;
    const data = projectFiles.reduce((acc, val, index) => {
      acc[`file_${index + 1}`] = val;

      return acc;
    }, {});
    data.project_id = villa.project_id;
    data.type = "contractor";

    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.projectFiles}`,
        "POST",
        data,
        true
      );
      if (response.success) {
        toast.success("Project images updated");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deletProjectImage = async (id) => {
    if (!id) return;
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteProjectImage}/${id}`,
        "GET"
      );
      if (response.success) {
        toast.success("Image deleted");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateSummary = () => {
    const transformedData = {
      customer_email: email,
      name: name,
      username: customerName,
      project_type: projectType,
      exp_id: proposalDetails?.exp_id,
      description: description,
      start_date: proposalDetails?.start_date,
      end_date: proposalDetails?.end_date,
      scope_of_work: scope,
      proposal: JSON.stringify({
        milestone_details: Object.keys(proposalDetails.milestones).map(
          (milestoneKey) => {
            const milestone = proposalDetails?.milestones[milestoneKey];
            return {
              payment_group_id: milestone?.group_id,
              milestone_name: milestone?.milestone_name,
              description: milestone?.description,
              start_date: milestone?.start_date,
              end_date: milestone?.end_date,
              budget_item: Object.keys(proposalDetails.budgets)
                .map((key, i) => {
                  const budget = proposalDetails?.budgets[key];
                  if (budget.milestone_id == milestoneKey) {
                    return {
                      name: budget?.name,
                      budget_id: i + 1,
                      material_type: budget?.material_type,
                      material_unit: budget?.material_unit || "",
                      material_unit_price: budget?.material_unit_price || "0",
                      qty: budget?.qty || "0",
                      manpower_rate: budget?.manpower_rate || "0",
                      days: budget?.days || "0",
                      specification: budget?.specification,
                    };
                  } else {
                    return null;
                  }
                })
                .filter((budget) => budget !== null),
            };
          }
        ),
        payment_group_details: Object.keys(proposalDetails?.payment_group)
          .filter((key) => {
            const payment_group = proposalDetails?.payment_group[key];
            return payment_group.hasMilestones;
          })
          .map((key) => {
            const payment_group = proposalDetails?.payment_group[key];
            return {
              group_id: key,
              group_name: payment_group?.groupName,
            };
          }),
      }),
    };
    updateProjectFiles();
    updateproposalApicall(transformedData);
  };

  const handleSave = () => {
    validation();
  };

  const deleteImage = (index) => {
    const newOriginalDocs = [...originalDoc];
    const imageToDelete = newOriginalDocs[index];

    newOriginalDocs.splice(index, 1);
    setOriginalDoc(newOriginalDocs);

    const newUpdatedFiles = updatedFiles.filter((file) => {
      if (typeof file === "string") {
        return file !== imageToDelete;
      } else {
        return file !== imageToDelete;
      }
    });

    setUpdatedFiles(newUpdatedFiles);
    deletProjectImage(imageToDelete.id);
  };

  function displayImagesView() {
    if (isArray(originalDoc) && originalDoc?.length > 0) {
      return originalDoc?.map((item, index) => {
        let imgUrl = "";
        if (item.image) {
          imgUrl = item.image;
        } else if (typeof item === "object" && item instanceof Blob) {
          imgUrl = URL.createObjectURL(item);
        } else {
          imgUrl = item;
        }
        return (
          <div
            style={{
              display: "flex",
              border: "1px solid #F2F3F4",
              borderRadius: 6,
              marginBottom: 10,
              padding: 3,
            }}
          >
            <img
              style={{
                width: 60,
                height: 70,
                borderRadius: 6,
                marginRight: 20,
                objectFit: "cover",
              }}
              src={imgUrl}
              alt="Budget Photos"
            />
            <div style={{ margin: "auto 0" }}>
              <Typography
                style={{
                  fontFamily: "Inter Tight",
                  fontWeight: "500",
                  color: "#202939",
                  fontSize: 18,
                }}
              >
                {item?.name || `Image ${index + 1}` || ""}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: 10,
              }}
            >
              <HighlightOffOutlined
                style={{
                  zIndex: 10,
                  cursor: "pointer",
                  fontSize: 28,
                  color: "#8C92A4",
                }}
                onClick={(e) => {
                  const nArr = [...originalDoc];

                  nArr.splice(index, 1);
                  setOriginalDoc(nArr);
                  const newFiles = updatedFiles.filter((item) =>
                    originalDoc.includes(item)
                  );
                  setUpdatedFiles(newFiles);
                  deleteImage(index);
                }}
              />
            </div>
          </div>
        );
      });
    }
  }

  function validation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(scope)) {
      valid = false;
      error.scpErr = true;
      error.scpMsg = "Please enter scope of project";
    }

    if (!projectType) {
      valid = false;
      error.typeErr = true;
      error.typeMsg = "Please select project type";
    }
    if (isEmpty(name?.trim())) {
      valid = false;
      error.nameErr = true;
      error.nameMsg = "Please enter project name";
    }

    if (isEmpty(description?.trim())) {
      valid = false;
      error.descriptionErr = true;
      error.descriptionMsg = "Please enter project description";
    }
    if (isEmpty(customerName?.trim())) {
      valid = false;
      error.cNameErr = true;
      error.cNameMsg = "Please enter customer name";
    }

    if (isEmpty(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter email";
    } else if (!emailRegex.test(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter valid email";
    }

    setErrObj(error);
    if (!valid) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    } else {
      dispatch(
        setProposalDetails({
          ...proposalDetails,
          scope_of_work: scope,
          project_type: projectType,
          name,
          description: description,
          email,
          customer_name: customerName,
          project: originalDoc || [],
        })
      );
      updateSummary();
    }
  }

  return (
    <Stack overflow="auto" gap="8px" sx={{ overflowX: "hidden !important" }}>
      <Stack gap="8px">
        <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
          <Grid item xs={6} style={{ paddingTop: 25 }}>
            <CAutocomplete
              label="Type"
              placeholder="Select project type"
              value={expertiseList.find(
                (option) => option?.project_name === projectType
              )}
              onChange={(e, newValue) => {
                setProjectType(newValue?.project_name);
                setErrObj({
                  ...errObj,
                  typeErr: false,
                  typeMsg: "",
                });
              }}
              options={expertiseList}
              getOptionLabel={(option) => option?.project_name}
              error={errObj.typeErr}
              helpertext={errObj.typeMsg}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 25 }}>
            <CInput
              label="Name"
              placeholder="Write here..."
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setErrObj({
                  ...errObj,
                  nameErr: false,
                  nameMsg: "",
                });
              }}
              inputProps={{ maxLength: 50 }}
              error={errObj.nameErr}
              helpertext={errObj.nameMsg}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CInput
            multiline={true}
            rows={3}
            label="Description"
            placeholder="Write here..."
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              setErrObj({
                ...errObj,
                descriptionErr: false,
                descriptionMsg: "",
              });
            }}
            error={errObj.descriptionErr}
            helpertext={errObj.descriptionMsg}
          />
        </Grid>
        <Grid container flex columnGap={1} wrap={md ? "wrap" : "nowrap"}>
          <Grid item xs={6}>
            <CInput
              label="Customer Name"
              placeholder="Write here..."
              value={customerName}
              onChange={(e) => {
                setCustomerName(e.target.value);
                setErrObj({
                  ...errObj,
                  cNameErr: false,
                  cNameMsg: "",
                });
              }}
              inputProps={{ maxLength: 50 }}
              error={errObj.cNameErr}
              helpertext={errObj.cNameMsg}
            />
          </Grid>
          <Grid item xs={6}>
            <CInput
              label="Customer Email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setErrObj({
                  ...errObj,
                  emailErr: false,
                  emailMsg: "",
                });
              }}
              error={errObj.emailErr}
              helpertext={errObj.emailMsg}
            />
          </Grid>
        </Grid>
      </Stack>
      <Grid item xs={12} style={{ paddingTop: 25 }}>
        <CInput
          multiline={true}
          rows={4}
          label="Scope of work"
          placeholder="Write here..."
          value={scope}
          onChange={(e) => {
            setScope(e.target.value);
            setErrObj({
              ...errObj,
              scpErr: false,
              scpMsg: "",
            });
          }}
          error={errObj.scpErr}
          helpertext={errObj.scpMsg}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: 20,
        }}
      >
        {uploadLoader ? (
          <Grid
            item
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={12}
            minHeight={220}
          >
            <CircularProgress style={{ color: "#274BF1" }} size={26} />
          </Grid>
        ) : (
          <>
            <InputLabel shrink error={errObj.documentErr}>
              Upload supporting pictures or documentation
            </InputLabel>
            <Grid
              item
              container
              xs={12}
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: 170,
                  border: "1px dashed #9CA3AF",
                  borderRadius: 4,
                  gap: 1,
                }}
              >
                <div style={{ width: "24px", height: "24px" }}>
                  <img src={Images.upload_icon} alt="upload-icon"></img>
                </div>
                <InputLabel>
                  <b>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#2563EB",
                      }}
                    >
                      Click to upload Images
                    </span>{" "}
                    or drag and drop{" "}
                  </b>
                </InputLabel>
                <InputLabel style={{ fontSize: 12, color: "#6B7280" }}>
                  {"PNG, JPG, (max size 1200*800)"}
                </InputLabel>
              </div>
              <input
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                multiple
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                  cursor: "pointer",
                  width: "100%",
                }}
                onChange={(e) => {
                  const chosenFiles = Array.prototype.slice.call(
                    e.target.files
                  );
                  const rejected = chosenFiles.every(
                    (item) =>
                      item.type === "image/png" ||
                      item.type === "image/jpg" ||
                      item.type === "image/jpeg"
                  );
                  if (!rejected) {
                    toast.error("You can only add jpeg,jpg or png");
                  }
                  const filteredFiles = chosenFiles.filter(
                    (item) =>
                      item.type === "image/png" ||
                      item.type === "image/jpg" ||
                      item.type === "image/jpeg"
                  );

                  let showMsg = false;
                  let limit = false;
                  const newArr = [...updatedFiles];
                  filteredFiles.map((item) => {
                    const bool = checkImgSize(item);
                    if (bool && newArr.length < 5) {
                      newArr.push(item);
                    } else if (newArr.length >= 4) {
                      limit = true;
                    } else {
                      showMsg = true;
                    }
                  });
                  if (limit) {
                    toast.error("You can upload maximum 5 files");
                  } else if (showMsg) {
                    toast.error(
                      "Some registraion you are attempting to upload exceeds the maximum file size limit of 3 MB. Please reduce the size of your image and try again."
                    );
                  }
                  let shouldUpload =
                    isArray(newArr) &&
                    !isEmpty(newArr) &&
                    newArr?.filter((elem) => typeof elem !== "string");
                  if (shouldUpload) {
                    UploadFile(shouldUpload);
                  }
                }}
              />
              <FormHelperText
                error={errObj.documentErr}
                style={{ fontFamily: "Inter Tight" }}
              >
                {errObj.documentMsg}
              </FormHelperText>
            </Grid>
            <Grid
              item
              style={{
                marginTop: "20px",
                overflowY: "auto",
                maxHeight: "170px",
                width: "100%",
              }}
            >
              {displayImagesView()}
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <HomeOwnerSummary proposalDetails={proposalDetails} classes={classes} />
      </Grid>
      <Stack padding="10px" alignItems="end">
        <Button variant="contained" onClick={handleSave}>
          {buttonLoader ? (
            <CircularProgress size={26} style={{ color: "#fff" }} />
          ) : (
            "Save"
          )}
        </Button>
      </Stack>
    </Stack>
  );
};
export default EditSummary;
