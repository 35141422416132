import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Divider,
  CircularProgress,
  Stack,
  Box,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useStyles, { Status } from "./styles";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { color } from "../../config/theme";
import { getApiData } from "../../utils/APIHelper";
import "./index.css";
import { toast } from "react-toastify";
import {
  formattedAmount,
  formattedAmountWithoutAed,
  transformDateFormat,
} from "../../utils/CommonFunction";
import SingleBoqAccordion from "../../components/SingleBoqAccordian";
import EditPopup from "./EditPopup";
import ConfirmModel from "../../components/ConfirmModel";
import ImageGallery from "../../components/ImageGallery";

export default function EditPackage() {
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();

  const [details, setDetails] = useState(location?.state?.item);
  const [pageLoad, setPageLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isRejected, setIsRejected] = useState(false);

  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const getProjectDetails = useCallback(
    async function () {
      try {
        const response = await getApiData(
          `api/v2/package/${details?.id}`,
          "GET",
          {},
          { "Content-Type": "application/json" },
          true
        );
        if (response) {
          setDetails(response);
        }
      } catch (error) {
        toast.error(error);
      }
    },
    [details?.id]
  );
  console.log("details", details);

  async function handleApprovePackage() {
    try {
      const response = await getApiData(
        `api/v2/package/${details?.id}`,
        "PATCH",
        { status: "awaiting-project-pricing", ownerId: details?.owner?.id },
        { "Content-Type": "application/json" },
        true
      );
      toast.success("Package Approved");
      setIsApproved(false);
      window.location.reload();
    } catch (error) {
      toast.error(error);
      setIsApproved(false);
    }
  }

  const getData = useCallback(async () => {
    setPageLoad(true);
    await getProjectDetails();
    setPageLoad(false);
  }, [getProjectDetails]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getData();
  }, [getData]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTabValue(0);
    getProjectDetails();
  };

  return (
    <div>
      <Grid
        // item
        // container
        xs={12}
        sm={9}
        md={9}
        lg={12}
        alignItems="center"
        justifyContent="center"
        style={{ height: "97vh", overflowY: "scroll" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={9}
          className={classes.MainContainer}
          padding={"30px"}
          sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
        >
          {pageLoad ? (
            <div className={classes.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : (
            <>
              <Grid
                item
                container
                wrap={sm ? "wrap" : "nowrap"}
                alignItems={"center"}
                columnGap={2}
              >
                <div style={{ position: "relative" }}>
                  <img
                    src={
                      "https://renohome.blob.core.windows.net/reno-cms/ea0b2d8e-352a-45ed-aee4-59bab25bb47f"
                    }
                    alt="profile"
                    className={classes.imageStyle}
                  />
                  <div className={classes.activeContainer}>
                    <div className={classes.activeStatus}></div>
                  </div>
                </div>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Stack>
                    <Typography className={classes.titleText}>User</Typography>
                    <Status status={details?.status}>
                      {details?.status || "Created"}
                    </Status>
                  </Stack>

                  <Stack direction="row" gap="16px">
                    <Stack>
                      <Button
                        variant="contained"
                        onClick={handleOpen}
                        disabled={details?.status === "approved"}
                      >
                        Edit
                      </Button>
                    </Stack>

                    <Stack item lg={3} md={3} sm={3} xs={3} textAlign={"end"}>
                      <Typography className={classes.requestDate}>
                        Created At
                      </Typography>
                      <Typography className={classes.dateStyle}>
                        {transformDateFormat(details?.createdAt)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>

              <>
                <Stack width="100%" gap="16px">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    className={classes.projectInformation}
                    sx={{
                      backgroundColor: "#F3F4F9",
                      fontFamily: "Inter Tight"
                    }}
                  >
                    Package Details
                    {details?.cost && (
                      <Stack
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          flexDirection: "row",
                          fontFamily: "Inter Tight"
                        }}
                      >
                        <Typography
                          className={classes.informationCard}
                          padding={"0px"}
                        >
                          Total Cost:
                        </Typography>
                        <Typography className={classes.value} padding="0">
                          {formattedAmount(details?.cost)}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <Stack gap="8px" width="100%">
                    <Stack
                      direction="row"
                      gap="8px"
                      padding="0 12px"
                      width="100%"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          Package Name
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.name}
                        </Typography>
                      </Stack>
                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          Package Type
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.projectType}
                        </Typography>
                      </Stack>
                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          Budget Type
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.budgetType
                            ?.toLowerCase()
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </Typography>
                      </Stack>
                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          Updated At
                        </Typography>
                        <Typography className={classes.value}>
                          {transformDateFormat(details?.updatedAt)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />

                    <Stack
                      direction="row"
                      gap="8px"
                      padding="0 12px"
                      width="100%"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          No. of Bedrooms
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.sizeMetadata || "---"}
                        </Typography>
                      </Stack>
                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          Package Timeline
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.timeline}
                        </Typography>
                      </Stack>

                      <Stack flex={1}>
                        <Typography className={classes.informationCard}>
                          Price
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.price
                            ? formattedAmount(details?.price)
                            : "---"}
                        </Typography>
                      </Stack>

                      <Stack
                        flex={1}
                        style={{ alignItems: "flex-end", visibility: "hidden" }}
                      >
                        <Typography className={classes.informationCard}>
                          Price
                        </Typography>
                        <Typography className={classes.value}>
                          {formattedAmount(details?.price)}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />

                    <Stack padding="0 12px">
                      <Stack>
                        <Typography className={classes.informationCard}>
                          Package Description
                        </Typography>
                        <Typography className={classes.value}>
                          {details?.description}
                        </Typography>
                      </Stack>
                    </Stack>

                    {details?.tags && (
                      <>
                        {" "}
                        <Divider />
                        <Stack padding="0 12px">
                          <Stack>
                            <Typography className={classes.informationCard}>
                              Package Tags
                            </Typography>
                            <Grid container spacing={1}>
                              {details?.tags?.map((tag, index) => (
                                <Grid item key={index}>
                                  <Typography
                                    className={classes.value}
                                    style={{
                                      backgroundColor: "#f7f7f7",
                                      padding: ".7vh 1vw",
                                      margin: ".3vh .1vw",
                                      width: "fit-content",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {tag.name}
                                  </Typography>
                                </Grid>
                              ))}
                            </Grid>
                          </Stack>
                        </Stack>
                      </>
                    )}
                    <Divider />
                  </Stack>
                </Stack>

                <Stack gap="12px" width="100%">
                  {details?.inclusions && (
                    <>
                      <Stack direction="row" gap="4px" width="100%">
                        {details?.inclusions?.length > 0 && (
                          <Typography
                            className={classes.projectInformation}
                            padding="8px 16px"
                          >
                            Inclusions
                            <span
                              style={{
                                fontSize: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "4px 10px",
                                margin: "0px 8px",
                                backgroundColor: "#E9B55C",
                                borderRadius: 22,
                              }}
                            >
                              {details?.inclusions?.length}
                            </span>
                          </Typography>
                        )}
                      </Stack>
                      <Stack width="100%" gap="8px">
                        {details?.inclusions?.map((item, idx) => (
                          <SingleBoqAccordion
                            budget={item}
                            index={idx}
                            key={idx}
                            isSummary={true}
                            getDetailsApiCall={getProjectDetails}
                          />
                        ))}
                      </Stack>
                      <Divider />
                    </>
                  )}
                </Stack>
                {details?.status !== "CREATED" &&
                  details?.status !== "SUBMITTED" && (
                    <Stack gap="12px" width="100%">
                      <Stack direction="row" gap="4px" width="100%">
                        <Typography
                          className={classes.projectInformation}
                          padding="8px 16px"
                        >
                          Commission Details
                        </Typography>
                      </Stack>
                      <Stack
                        direction="column"
                        gap="8px"
                        padding="0 12px"
                        width="100%"
                        justifyContent="space-between"
                        flexWrap="wrap"
                      >
                        <Stack
                          flex={1}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={classes.informationCard}>
                            Reno Commission ({details?.currency ?? "AED"}) :
                          </Typography>
                          <Typography className={classes.value}>
                            {formattedAmountWithoutAed(details?.commission)}
                          </Typography>
                        </Stack>
                        <Stack
                          flex={1}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={classes.informationCard}>
                            Package Cost ({details?.currency ?? "AED"}) :
                          </Typography>
                          <Typography className={classes.value}>
                            {formattedAmountWithoutAed(
                              details?.cost - details?.commission
                            )}
                          </Typography>
                        </Stack>
                        <Stack
                          flex={1}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={classes.informationCard}>
                            Package Price ({details?.currency ?? "AED"}) :
                          </Typography>
                          <Typography className={classes.value}>
                            {formattedAmountWithoutAed(details?.cost)}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Divider />
                    </Stack>
                  )}
                <ImageGallery details={details} />
                {details?.status === "awaiting-contractor-confirmation" && (
                  <Stack direction="row" justifyContent="end" gap="16px">
                    <Button
                      variant="contained"
                      onClick={() => setIsApproved(true)}
                    >
                      Approve Package
                    </Button>
                  </Stack>
                )}
              </>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        style={{
          position: "fixed",
          bottom: 200,
          left: "90%",
          display: "flex",
          justifyContent: "end",
          padding: 5,
        }}
      ></Grid>
      <EditPopup
        details={details}
        isOpen={isOpen}
        handleClose={handleClose}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      <ConfirmModel
        visible={isApproved}
        handleClose={() => setIsApproved(false)}
        confirmation={handleApprovePackage}
        message="Are you sure you want to approve the package"
      />
    </div>
  );
}
