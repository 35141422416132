import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    padding: "16px 10px !important",
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
    backgroundColor: "#F3F4F9",
  },

  amountCellHeading: {
    textAlign: "end !important",
    padding: "16px 40px 16px 10px !important",
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
    backgroundColor: "#F3F4F9",
  },

  cell: {
    padding: "14px 10px !important",
    fontSize: "14px !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
  },

  amountCell: {
    textAlign: "end !important",
    padding: "16px 40px 16px 10px !important",
    fontSize: "14px !important",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderCollapse: "collapse",
  },
}));

export default useStyles;
