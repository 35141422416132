import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, Collapse } from "@mui/material";
import { GoPackage } from "react-icons/go";
import { RiSecurePaymentLine } from "react-icons/ri";
import { BsCashStack } from "react-icons/bs";
import { ReactComponent as ProjectIcon } from "../../../assets/svg/ProjectIcon.svg";
import { ReactComponent as ProposalIcon } from "../../../assets/svg/ProposalIcon.svg";
import { ReactComponent as PaymentIcon } from "../../../assets/svg/PaymentIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/svg/ProfileIcon.svg";
import { ReactComponent as ProjectActiveIcon } from "../../../assets/svg/ProjectActiveIcon.svg";
import { ReactComponent as ProposalActiveIcon } from "../../../assets/svg/ProposalActiveIcon.svg";
import { ReactComponent as PaymentActiveIcon } from "../../../assets/svg/PaymentActiveIcon.svg";
import { ReactComponent as ProfileActiveIcon } from "../../../assets/svg/ProfileActiveIcon.svg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
export default function DrawerList() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const isActive = (path) => pathname === path;
  const isProfileActive = (path) =>
    pathname.startsWith("/payments") ||
    pathname.startsWith("/balance-breakdown");

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 0.2,
          backgroundColor: "#FFF",
          height: "100vh",
          paddingBottom: "20px",
          fontFamily: "Inter Tight !important",
        }}
      >
        <List style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <ListItem
            button
            onClick={() => navigate("/dashboard")}
            style={{
              color: isActive("/ongoing-project" && "/dashboard")
                ? "#4D6BF6"
                : "#949DAA",
              fontWeight: "600 !important",
              fontSize: "14px !important",
            }}
          >
            {isActive("/ongoing-project" && "/dashboard") ? (
              <ProjectActiveIcon
                style={{ marginRight: 15, fontSize: "18px" }}
              />
            ) : (
              <ProjectIcon style={{ marginRight: 15, fontSize: "18px" }} />
            )}
            <ListItemText primary="Projects" />
          </ListItem>

          <ListItem
            button
            onClick={() => navigate("/proposals")}
            style={{
              color: isActive("/proposals") ? "#4D6BF6" : "#949DAA",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {isActive("/proposals") ? (
              <ProposalActiveIcon
                style={{ marginRight: 15, fontSize: "18px" }}
              />
            ) : (
              <ProposalIcon style={{ marginRight: 15, fontSize: "18px" }} />
            )}
            <ListItemText primary="Proposals" />
          </ListItem>

          <ListItem
            button
            onClick={() => navigate("/packages")}
            style={{
              color: isActive("/packages") ? "#4D6BF6" : "#949DAA",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            <GoPackage style={{ marginRight: 15, fontSize: "20px" }} />
            <ListItemText primary="Packages" />
          </ListItem>

          <ListItem
            button
            onClick={() => setOpenProfileMenu(!openProfileMenu)}
            style={{
              color: isProfileActive() ? "#4D6BF6" : "#949DAA",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {isProfileActive() ? (
              <PaymentActiveIcon
                style={{ marginRight: 15, fontSize: "18px" }}
              />
            ) : (
              <PaymentIcon style={{ marginRight: 15, fontSize: "18px" }} />
            )}
            <ListItemText primary="Payments" />
            {openProfileMenu ? (
              <ExpandLess style={{ marginLeft: "auto", fontSize: "18px" }} />
            ) : (
              <ExpandMore style={{ marginLeft: "auto", fontSize: "18px" }} />
            )}
          </ListItem>
          <Collapse in={openProfileMenu}>
            <List sx={{ marginLeft: "20px" }}>
              <ListItem
                button
                onClick={() => navigate("/payments")}
                style={{
                  color: isActive("/payments") ? "#4D6BF6" : "#949DAA",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                <RiSecurePaymentLine
                  style={{ marginRight: 14, fontSize: "22px" }}
                />
                <ListItemText primary="All Payments" />
              </ListItem>

              <ListItem
                button
                onClick={() => navigate("/balance-breakdown")}
                style={{
                  color: isActive("/balance-breakdown") ? "#4D6BF6" : "#949DAA",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                <BsCashStack style={{ marginRight: 15, fontSize: "20px" }} />
                <ListItemText primary="Project Balance" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => navigate("/contractor-profile")}
            style={{
              color: isActive("/contractor-profile") ? "#4D6BF6" : "#949DAA",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {isActive("/contractor-profile") ? (
              <ProfileActiveIcon
                style={{ marginRight: 15, fontSize: "18px" }}
              />
            ) : (
              <ProfileIcon style={{ marginRight: 15, fontSize: "18px" }} />
            )}
            <ListItemText primary="Profile" />
          </ListItem>
        </List>
      </div>
    </>
  );
}
