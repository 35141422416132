import {
  Backdrop,
  Box,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useState } from "react";

import EditSummary from "./EditSummary";
import EditMilestones from "./EditMilestones";
import EditBudgets from "./EditBudgets";
import EditPaymentGroups from "./EditPaymentGroups";
import UpdatedDetails from "./UpdatedDetails";
import Images from "../../../config/images";

const EditProposal = ({ isOpen, handleClose }) => {
  const [tabValue, setTabValue] = useState(0);

  const modalStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "90%",
    bgcolor: "background.paper",
    padding: 4,
    overflow: "hidden",
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      disableAutoFocus
      slotProps={{ backdrop: Backdrop }}
    >
      <Box sx={modalStyle}>
        <Stack direction="row" justifyContent="space-between">
          <Tabs
            value={tabValue}
            variant="scrollable"
            onChange={(v, b) => {
              setTabValue(b);
            }}
          >
            <Tab label="Details" />
            <Tab label="Milestones" />
            <Tab label="Budget" />
            <Tab label="Payment Group" />
            <Tab label="Summary" />
          </Tabs>
          <IconButton onClick={handleClose}>
            <img
              src={Images.close}
              alt="close"
              style={{ width: "100%", height: "100%" }}
            />
          </IconButton>
        </Stack>
        {tabValue === 0 ? <EditSummary setTabValue={setTabValue} /> : null}
        {tabValue === 1 ? <EditMilestones setTabValue={setTabValue} /> : null}
        {tabValue === 2 ? <EditBudgets setTabValue={setTabValue} /> : null}
        {tabValue === 3 ? (
          <EditPaymentGroups setTabValue={setTabValue} />
        ) : null}
        {tabValue === 4 ? <UpdatedDetails /> : null}
      </Box>
    </Modal>
  );
};
export default EditProposal;
