import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Images from "../../config/images";
import useStyles, { Status } from "./styles";

import {
  formattedAmount,
  transformDateFormat,
} from "../../utils/CommonFunction";

const ProjectCard = (props) => {
  const { villa = {}, type = "", onClick = () => {} } = props;
  const manageProject = type === "manageProject";
  const submitted = type === "submitted";
  const requested = type === "requested";
  const ongoing = type === "ongoing";
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const status =
    villa.status === "awaiting-approval" ||
    villa.status === "awaiting-contractor-confirmation" ||
    villa.status === "rejected-by-contractor" ||
    villa.status === "proposal" ||
    villa.status === "draft-by-contractor";

  const startDate = villa?.start_date
    ? transformDateFormat(villa?.start_date)
    : undefined;
  const endDate = villa?.end_date
    ? transformDateFormat(villa?.end_date)
    : undefined;

  const transformedStatus =
    villa?.proposal_status === "open-for-modification"
      ? "Open For Modification"
      : villa?.status
          .split("-")
          .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
          .join(" ");
  const ispdf = villa?.project_image[0]?.type === "application/pdf";

  return (
    <Card key={villa?.id} className={classes.card} onClick={onClick}>
      <CardMedia
        component="img"
        height="120"
        image={
          !ispdf
            ? villa?.project_image[0]?.image
            : "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
        }
        style={{ objectFit: "cover" }}
        alt={"project_image"}
      />
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          gap="16px"
        >
          <Typography className={classes.name}>
            {villa?.name || "NA"}
          </Typography>
          {status && (
            <Status
              status={
                villa?.proposal_status === "open-for-modification"
                  ? villa?.proposal_status
                  : villa?.status
              }
            >
              {transformedStatus}
            </Status>
          )}
        </Stack>
        <div className={classes.rowJustified}>
          <Typography
            fontSize="12px"
            fontFamily="Inter Tight"
            color="#202939"
          >
            {villa?.project_slug}
          </Typography>

          {startDate && (
            <Typography className={classes.code1}>
              <Typography className={classes.code1} color="#8C92A4">
                Start-Date:
              </Typography>
              {startDate}
            </Typography>
          )}
        </div>
        {/* <Divider /> */}
        {/* <div className={classes.rowJustified}>
          <Typography className={classes.row}>
            <img src={Images.bedroom} alt="badroom" />
            {villa?.form_json?.bedrooms || 0}
          </Typography>
          <Typography className={classes.row}>
            <img src={Images.bathroom} alt="bathroom" />{" "}
            {villa?.form_json?.bathrooms || 0}
          </Typography>
          <Typography className={classes.row}>
            <img src={Images.size} alt="size" /> {villa?.form_json?.size || 0}{" "}
            sqm
          </Typography>
        </div> */}
        {!manageProject && (
          <>
            <Divider style={{ color: "#F2F3F4" }} />
            <Typography className={classes.company}>
              <img
                src={villa?.user_data?.profile_url}
                width="28px"
                height="28px"
                alt="profile_logo"
                style={{ borderRadius: "100%" }}
              />
              {villa?.user_data?.username}
              {villa?.user_data?.is_email_verified && (
                <img
                  src={Images.verified}
                  alt="verified"
                  style={{ marginLeft: 8 }}
                />
              )}
            </Typography>
          </>
        )}

        {!requested && !manageProject && villa?.budget != null && (
          <div className={classes.rowJustified}>
            <Typography className={classes.row}>
              {!requested && "Budget:"}
            </Typography>
            {!requested && (
              <Typography className={classes.budget}>
                {formattedAmount(villa?.budget)}
              </Typography>
            )}
          </div>
        )}

        {(submitted || ongoing) && (
          <>
            <div
              className={classes.rowJustified}
              style={{ visibility: endDate ? "visible" : "hidden" }}
            >
              <Typography className={classes.row}>End Date</Typography>
              {!md && <img src={Images.arrow} alt="arrow"></img>}
              <Typography className={classes.budget}>{endDate}</Typography>
            </div>
          </>
        )}
        {manageProject && (
          <>
            <div style={{ width: "100%" }}>
              <Divider style={{ color: "#F2F3F4" }} />
            </div>
            <div className={classes.rowJustified}>
              <Typography className={classes.row}>Customer name:</Typography>
              <Typography className={classes.budget}>
                {villa?.user_data?.username}
              </Typography>
            </div>
            <div className={classes.rowJustified}>
              <Typography className={classes.row}>Budget :</Typography>
              <Typography className={classes.budget}>
                AED {villa?.budget || 0}
              </Typography>
            </div>
            <div className={classes.rowJustified}>
              <Typography className={classes.row}>Start Date:</Typography>
              <Typography className={classes.budget}>{startDate}</Typography>
            </div>
            <div className={classes.rowJustified}>
              <Typography className={classes.row}>End Date:</Typography>
              <Typography className={classes.budget}>{endDate}</Typography>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProjectCard;
