import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import useStyles from "./styles";
import { color } from "../../../config/theme";
import ChangeRequestItem from "./components/ChangeRequestItem";
import { getApiData } from "../../../utils/APIHelper";
import { Setting } from "../../../utils/Setting";

const Changelog = ({ projectId }) => {
  const [changelogs, setChangelogs] = useState([]);
  const [loading, setLoading] = useState();
  const classes = useStyles();

  const getChangelogItems = async () => {
    try {
      setLoading(true);
      const data = await getApiData(
        Setting.endpoints.changeRequest.list(projectId),
        "GET",
        {},
        {},
        true
      );
      setChangelogs(data);
    } catch (error) {
      console.log("error in change log list", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (projectId) {
      getChangelogItems();
    }
  }, [projectId]);

  return (
    <Stack className={classes.groupContainer}>
      {loading !== false ? (
        <div className={classes.loadingContainer}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : changelogs && changelogs?.length === 0 ? (
        <Stack minHeight="200px" justifyContent="center" alignItems="center">
          <Typography variant="h5" fontFamily="Inter Tight">
            No Change Requests Found
          </Typography>
        </Stack>
      ) : (
        <>
        {changelogs?.map((changelog) => (
          <ChangeRequestItem
            key={changelog?.changeRequest?.changeRequestId}
            changelog={changelog}
          />
        ))}
        </>
      )}
    </Stack>
  );
};

export default Changelog;
