import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  groupTitle: {
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
  },
  groupContainer: {
    width: "100%",
    gap: "16px",
    padding: "12px",
    border: "1px solid #CBD1DB",
    borderRadius: "14px",
  },
  label: {
    fontSize: "14px !important",
    fontFamily: "Inter Tight !important",
    color: "#76767d",
  },

  formLabel: {
    fontSize: "14px !important",
    fontFamily: "Inter Tight !important",
  },
  value: {
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    color: "#222427",
  },

  totalAmount: {
    fontSize: "16px !important",
    fontFamily: "Inter Tight !important",
    color: "#8C92A4",
  },
}));

export default useStyles;
