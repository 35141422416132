import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { statusStyleArray } from "../config";
import { formattedAmountWithoutAed } from "../../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import { DeleteOutline } from "@mui/icons-material";

export const getStatusDisplayText = (text) => {
  if (typeof text !== "string") return text;
  const displayText = text
    .replace(/-/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return displayText;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
}));

const ActiveStyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  display: "flex",
  alignItems: "center",
  gap: "7px",
  marginTop: "13px",
}));

export default function PackageDetails({ details, handleDelete, selectedTab }) {
  const navigate = useNavigate();
  const handleChange = (item) => {
    navigate("/edit-package", {
      state: { item, status: "submitted" },
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflow: "none !important",
          boxShadow: "none",
          maxHeight: "80vh",
        }}
      >
        <Table
          sx={{
            "& thead": {
              "& th": {
                color: "#949DAA",
                fontWeight: "500",
              },
            },
            "& tbody": {
              "& tr:hover": {
                backgroundColor: "#f7f7f7",
              },
              "& tr": {
                borderBottom: "none",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell>Package Name</StyledTableCell>
              <StyledTableCell>Project Type</StyledTableCell>
              <StyledTableCell>Budget Type</StyledTableCell>
              <StyledTableCell
                sx={{
                  flex: 0.5,
                  textAlign: "right",
                  fontWeight: "900 !important",
                }}
              >
                <strong>Cost (AED)</strong>
              </StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              {selectedTab === 3 && <StyledTableCell>Active</StyledTableCell>}
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((item, idx) => (
              <TableRow key={item.id}>
                <StyledTableCell>{idx + 1}.</StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    {item.primaryImage && (
                      <Box
                        component="img"
                        src={JSON.parse(item.primaryImage)?.image}
                        alt="Package Icon"
                        sx={{
                          width: 45,
                          height: 45,
                          borderRadius: "8px",
                          border: "2px solid white",
                          boxShadow: "0px 4px 2.67px 0px #00000026",
                          marginRight: 1,
                        }}
                      />
                    )}
                    {item.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{item.projectType}</StyledTableCell>
                <StyledTableCell>
                  <Typography
                    style={{
                      borderRadius: "34px",
                      padding: "4px 8px",
                      fontSize: "14px",
                      minWidth: "7ch",
                      width: "fit-content",
                      backgroundColor:
                        statusStyleArray.find(
                          (style) => style.status === item?.budgetType
                        )?.backgroundColor || "transparent",
                      color:
                        statusStyleArray.find(
                          (style) => style.status === item?.budgetType
                        )?.color || "black",
                      border: "none",
                    }}
                  >
                    {getStatusDisplayText(item?.budgetType)}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>{`${formattedAmountWithoutAed(item?.cost)}`}</strong>
                </StyledTableCell>

                <StyledTableCell>
                  <Typography
                    style={{
                      borderRadius: "34px",
                      padding: "4px 8px",
                      fontSize: "14px",
                      minWidth: "7ch",
                      width: "fit-content",
                      backgroundColor:
                        statusStyleArray.find(
                          (style) => style.status === item?.status
                        )?.backgroundColor || "transparent",
                      color:
                        statusStyleArray.find(
                          (style) => style.status === item?.status
                        )?.color || "black",
                      border: "none",
                      textAlign: "center",
                    }}
                  >
                    {getStatusDisplayText(
                      item?.status === "CREATED" ? "DRAFT" : item?.status
                    )}
                  </Typography>
                </StyledTableCell>

                {selectedTab === 3 && (
                  <ActiveStyledTableCell>
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: item?.isActive ? "#4BB34B" : "#E54746",
                        border: "3px solid #e5e5e5",
                      }}
                    ></div>
                    <div>{item?.isActive ? "ON" : "OFF"}</div>
                  </ActiveStyledTableCell>
                )}
                <StyledTableCell>
                  {item?.status === "approved" ? (
                    <IconButton
                      size="small"
                      title="View"
                      onClick={() => handleChange(item)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        size="small"
                        title="Edit"
                        onClick={() => handleChange(item)}
                      >
                        <EditIcon fontSize="20" />
                      </IconButton>
                      <IconButton
                        size="small"
                        title="Delete Proposal"
                        onClick={() => handleDelete(item?.id)}
                      >
                        <DeleteOutline
                          fontSize="20"
                          style={{ color: "#E54746" }}
                        />
                      </IconButton>
                    </>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
